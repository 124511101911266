<template>
  <div class="nosotros">
    <NavBar @updateSection="updateSection"></NavBar>
    <Portada></Portada>
    <Cobertura id="section_0"></Cobertura>
    <Mision id="section_1"></Mision>
    <Evolucion id="section_2"></Evolucion>
    <!-- <Indicadores id="section_3"></Indicadores> -->
    <Footer></Footer>
  </div>
</template>

<script>
import NavBar from "../components/Navbar.vue";
import Portada from "../components/nosotros/Portada.vue";
import Cobertura from "../components/nosotros/Cobertura.vue";
import Mision from "../components/nosotros/Mision.vue";
import Evolucion from "../components/nosotros/Evolucion.vue";
import Indicadores from "../components/nosotros/Indicadores.vue";
import Footer from "../components/Footer.vue";
import { scroller } from "vue-scrollto/src/scrollTo";

  export default {
    name: "Nosotros",
    data: function() {
      return {
        section_active: 0
      };
    },
    components: {
      NavBar,
      Portada,
      Cobertura,
      Mision,
      Evolucion,
      Indicadores,
      Footer
    },
    props: {
      section: {
        type: Number,
        default: 0
      }
    },
    beforeMount() {
      this.section_active = this.section ? this.section : 0;
    },
    mounted() {
      if (this.section >= 0) {
        let scrool = scroller();
        scrool("#section_" + this.section);
      }
    },
    methods: {
      updateSection: function(index) {
        let scrool = scroller();
        scrool("#section_" + index);
        this.section_active = index;
      }
    }
  }
</script>