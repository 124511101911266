<template>
  <div class="intro-conectividad full-width row" v-scrollanimation>
    <div class="fondo-intro-conectividad" v-scrollanimation></div>
    
    <div class="cont-intro-conectividad flex-row-center align-row-center full-height">
      <div class="middle intro-conectividad-text " v-scrollanimation>
        <div class="content-intro-conectividad-text flex-column-center">
          <span class="titulo title_section" v-scrollanimation>
            Conectividad
          </span>
          <div class="barra" v-scrollanimation></div>
          <div class="contenido subtitle" v-scrollanimation>
            Con 15 años de experiencia en el mercado de las telecomunaciones, Nedetel ha logrado posicionarse como uno de los Proveedores de Servicios de Internet (ISP) y Portadores más grandes a nivel nacional.
          </div>
        </div>
      </div>
      <div class="middle intro-conectividad-img flex-row-center align-row-center" v-scrollanimation>
        <div class="cont-img" v-scrollanimation>
          <img src="../../../assets/img/soluciones/intro.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.intro-conectividad {
  height: 45vw;
  position: relative;
}

.intro-conectividad .fondo-intro-conectividad {
  position: absolute;
  top:-12vw;
  right: 0vw;
  width: 70%;
  height: 100%;
  z-index: -2;
  background-image: url("../../../assets/img/soluciones/fondo-intro.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.intro-conectividad .intro-conectividad-img {
  align-items: flex-end;
}

.intro-conectividad .intro-conectividad-text {
  padding-left: 8vw;
  width: calc(50% - 8vw);
  height: 100%;
  position: relative;
}

.intro-conectividad .intro-conectividad-text .content-intro-conectividad-text { 
  height: auto;
  position: absolute;
  bottom: 10vw;
}


.intro-conectividad  .intro-conectividad-text .titulo { 
  color: #1A4C94;
  font-size: 2.8vw;
  text-transform: uppercase;
}

.intro-conectividad .intro-conectividad-text .barra {
  height: 0.5vw;
  width: 40%;
  border-radius: 6px;
  background: #1A4C94;
  margin-bottom: 3vw;
}

.intro-conectividad  .intro-conectividad-text .contenido {
  font-size: 1.4vw;
  color: #606060;
  text-align: justify;
}

.intro-conectividad .intro-conectividad-img .cont-img {
  width: 30vw;
  height: 30vw;
  margin-bottom: 2vw;
}

.intro-conectividad .intro-conectividad-img .cont-img img{
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 320px) {
  .intro-conectividad {
    height: 55vw;
  }
  .intro-conectividad .fondo-intro-conectividad {
    top: 0;
    width: 100%;
    height: 100%;
  }
  .intro-conectividad .cont-intro-conectividad {
    flex-flow: column;
  }
  .intro-conectividad .intro-conectividad-text {
    height: 40%;
    width: 100%;
    padding: 0;
  }
  .intro-conectividad .intro-conectividad-text .content-intro-conectividad-text {
    position: relative;
    height: 100%;
    bottom: 0;
  }
  .intro-conectividad .intro-conectividad-text .titulo {
    text-align: center;
    font-size: 4vw;
  }
  .intro-conectividad .intro-conectividad-text .barra {
    margin: 0 auto 3vw auto;
  }
  .intro-conectividad .intro-conectividad-text .contenido {
    width: 80%;
    margin: 0 auto;
    font-size: 2.2vw;
  }
  .intro-conectividad .intro-conectividad-img {
    height: 50%;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
  }
  .intro-conectividad .intro-conectividad-img .cont-img {
    width: 28vw;
    margin: 0;
    height: 100%;
  }
}
@media only screen and (min-width: 321px) and (max-width: 480px) {
  .intro-conectividad {
    height: 55vw;
  }
  .intro-conectividad .fondo-intro-conectividad {
    top: 0;
    width: 100%;
    height: 100%;
  }
  .intro-conectividad .cont-intro-conectividad {
    flex-flow: column;
  }
  .intro-conectividad .intro-conectividad-text {
    height: 40%;
    width: 100%;
    padding: 0;
  }
  .intro-conectividad .intro-conectividad-text .content-intro-conectividad-text {
    position: relative;
    height: 100%;
    bottom: 0;
  }
  .intro-conectividad .intro-conectividad-text .titulo {
    text-align: center;
    font-size: 4vw;
  }
  .intro-conectividad .intro-conectividad-text .barra {
    margin: 0 auto 3vw auto;
  }
  .intro-conectividad .intro-conectividad-text .contenido {
    width: 80%;
    margin: 0 auto;
    font-size: 2.2vw;
  }
  .intro-conectividad .intro-conectividad-img {
    height: 50%;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
  }
  .intro-conectividad .intro-conectividad-img .cont-img {
    width: 28vw;
    margin: 0;
    height: 100%;
  }
}
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .intro-conectividad {
    height: 55vw;
  }
  .intro-conectividad .fondo-intro-conectividad {
    top: 0;
    width: 100%;
    height: 100%;
  }
  .intro-conectividad .cont-intro-conectividad {
    flex-flow: column;
  }
  .intro-conectividad .intro-conectividad-text {
    height: 40%;
    width: 100%;
    padding: 0;
  }
  .intro-conectividad .intro-conectividad-text .content-intro-conectividad-text {
    position: relative;
    height: 100%;
    bottom: 0;
  }
  .intro-conectividad .intro-conectividad-text .titulo {
    text-align: center;
    font-size: 4vw;
  }
  .intro-conectividad .intro-conectividad-text .barra {
    margin: 0 auto 3vw auto;
  }
  .intro-conectividad .intro-conectividad-text .contenido {
    width: 80%;
    margin: 0 auto;
    font-size: 2.2vw;
  }
  .intro-conectividad .intro-conectividad-img {
    height: 50%;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
  }
  .intro-conectividad .intro-conectividad-img .cont-img {
    width: 28vw;
    margin: 0;
    height: 100%;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {

}
@media only screen and (min-width: 1025px) and (max-width: 1199px) {
  
}
</style>

<script>
  export default {
  }
</script>