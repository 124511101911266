import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import VueAxios from "vue-axios";
import VueScrollTo from "vue-scrollto";
import * as VueGoogleMaps from "vue2-google-maps";
import Chartkick from 'vue-chartkick';
import Chart from 'chart.js';
import ScrollAnimation from './directives/animateScrolling';


import { library } from "@fortawesome/fontawesome-svg-core";

import {
    faEnvelope,
    faLock,
    faMapMarked,
    faUser,
    faSignOutAlt,
    faMapMarkedAlt,
    faMapMarker,
    faLocationArrow,
    faInfoCircle,
    faParking,
    faTimes,
    faSearch,
    faStar,
    faBars,
    faHeart,
    faCamera,
    faUserCircle,
    faSignInAlt,
    faUserTie,
    faUserPlus,
    faArrowAltCircleRight,
    faArrowCircleLeft,
    faTimesCircle,
    faArrowCircleRight,
    faArrowLeft,
    faArrowRight,
    faHome,
    faUniversity,
    faBriefcase,
    faSignature,
    faBook,
    faUsers,
    faEnvelopeOpenText,
    faCalendarAlt,
    faProjectDiagram,
    faExpand,
    faChevronLeft,
    faChevronRight,
    faChevronDown,
    faChevronUp,
    faPaperPlane,
    faCheckCircle,
    faSpinner,
    faCameraRetro,
    faSave,
    faExclamationTriangle,
    faArrowDown,
    faCaretDown,
    faUserTimes,
    faUserEdit,
    faCalendarPlus,
    faCalendarCheck,
    faCalendar,
    faCalendarTimes,
    faNewspaper,
    faPlus,
    faAnkh,
    faCircle,
    faPlusCircle,
    faMinusCircle,
    faQuestionCircle,
    faFire,
    faTag,
    faRss,
    faBell,
    faChevronCircleRight,
    faGlobe,
    faClone,
    faShareAlt,
    faUndo,
    faReply,
    faAngleDown,
    faLongArrowAltRight,
    faAngleDoubleUp,
    faCogs,
    faAngleRight
} from "@fortawesome/free-solid-svg-icons";
import { faHeart as farHeart } from "@fortawesome/free-regular-svg-icons";
import { faFacebookSquare, faLinkedin, faTwitter, faTwitterSquare, faFacebook } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(
    faCaretDown,
    faEnvelope,
    faAngleDoubleUp,
    faLongArrowAltRight,
    faLock,
    faMapMarked,
    faFacebook,
    faUser,
    faFacebookSquare,
    faTwitterSquare,
    faLinkedin,
    faChevronDown,
    faUserTimes,
    faUserEdit,
    faCalendarPlus,
    faCalendarTimes,
    faCalendarCheck,
    faCalendar,
    faEnvelope,
    faGlobe,
    faNewspaper,
    faSignOutAlt,
    faClone,
    faShareAlt,
    faMapMarkedAlt,
    faMapMarker,
    faExclamationTriangle,
    faMinusCircle,
    faQuestionCircle,
    faFire,
    faBell,
    faLocationArrow,
    faCogs,
    faInfoCircle,
    faBars,
    faParking,
    faReply,
    faChevronRight,
    faChevronCircleRight,
    faTimes,
    faTimesCircle,
    faSearch,
    faStar,
    faPlus,
    faUndo,
    faSignOutAlt,
    faHeart,
    farHeart,
    faCamera,
    faUserCircle,
    faSignInAlt,
    faUserTie,
    faUserPlus,
    faArrowAltCircleRight,
    faBars,
    faChevronDown,
    faAngleRight,
    faAngleDown,
    faArrowCircleLeft,
    faArrowCircleLeft,
    faArrowCircleRight,
    faArrowLeft,
    faArrowRight,
    faArrowDown,
    faBars,
    faAnkh,
    faHome,
    faUniversity,
    faBriefcase,
    faSignature,
    faBook,
    faTag,
    faRss,
    faUsers,
    faCircle,
    faEnvelopeOpenText,
    faInfoCircle,
    faCalendarAlt,
    faProjectDiagram,
    faExpand,
    faPaperPlane,
    faPlusCircle,
    faChevronLeft,
    faChevronRight,
    faChevronDown,
    faChevronUp,
    faTwitter,
    faLinkedin,
    faPaperPlane,
    faTimesCircle,
    faTimesCircle,
    faCheckCircle,
    faSpinner,
    faSearch,
    faCameraRetro,
    faSave,
    faExclamationTriangle
);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

Vue.use(Chartkick.use(Chart))

Vue.directive('scrollanimation', ScrollAnimation);

Vue.use(VueGoogleMaps, {
    load: {
        Key: "AIzaSyDdjCqvP4JImZDetCCNoo0-objWeRJ-Q64",
        //key: "AIzaSyAq3QQ-adKG1ZSx97jyBTucEjYyVJGTvVc",
        libraries: "places", // necessary for places input
        v: 3.26
    }
});

axios.defaults.headers.common["Access-Control-Allow-CrossOrigin"] = "*";

Vue.use(VueAxios, axios);

Vue.use(VueScrollTo);

new Vue({
    router,
    render: h => h(App)
}).$mount("#app");