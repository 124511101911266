<template>
  <div class="lambdas full-width row" v-scrollanimation>
    <div class="fondo-lambdas" v-scrollanimation></div>
    <div class="cont-lambdas flex-row-center full-height">
      <div class="middle lambdas-img flex-row-center align-row-center" v-scrollanimation>
        <div class="box" v-scrollanimation>
          <div class="cont-img">
            <img src="../../../assets/img/soluciones/icono-lambdas.png" alt="" />
          </div>
          <span class="titulo title_section" v-scrollanimation>Lambdas</span>
          <span class="content" v-scrollanimation>Te ofrecemos lambdas de 10 G, 100 G y 200 G, a través de las señales de transmisión ópticas puras (OTN) de alta velocidad y conmutación 1+1 y 1+n </span>
        </div>
      </div>
      <div class="middle lambdas-text flex-column-center align-column-center" v-scrollanimation>

        <div class="cont-img-mapa flex-row-center align-row-center" v-scrollanimation>
          <img src="../../../assets/img/soluciones/mapa-lambdas.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.lambdas {
  height: 55vw;
  position: relative;
}

.lambdas .fondo-lambdas {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url("../../../assets/img/soluciones/fondo-lambdas.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.lambdas .cont-lambdas {

}

.lambdas .cont-lambdas .lambdas-img {
  width: 40%;
  position: relative;
  justify-content: center;
  align-items: center;
}

.lambdas .cont-lambdas .lambdas-img .box {
  width: calc(85% - 1.6vw);
  margin: 0 0.8vw;
  height: 60%;
  border-radius: 1vw;
  background: #17e3d1;  
}

.lambdas .cont-lambdas .lambdas-img .box .cont-img {
  width: 10vw;
  height: 10vw;
  display: block;
  margin: -5vw auto 2vw auto;
}

.lambdas .cont-lambdas .lambdas-img .box .cont-img img { 
  width: 100%;
  height: 100%;
}

.lambdas .cont-lambdas .lambdas-img .box span {
  display: block;
  width: calc(100% - 3vw);
  padding: 0 1.5vw;
  color: #606060;
}

.lambdas .cont-lambdas .lambdas-img .box .titulo {
  text-transform: uppercase;
  margin-bottom: 2vw;
  color: #1A4C94;
  text-align: center;
  font-size: 1.4vw;
}

.lambdas .cont-lambdas .lambdas-img .box .content {
  height: calc(100% - 12vw);
  overflow: scroll;
  width: calc(100% - 6vw);
  padding: 0 3vw;
  text-align: justify;
  line-height: 1.3vw;
  color: #333333;
}

.lambdas .cont-lambdas .lambdas-text {
  width: 60%;
  position: relative;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}

.lambdas .cont-lambdas .lambdas-text .cont-img-mapa {
  width: 100%;
  height: 60%;
}

.lambdas .cont-lambdas .lambdas-text .cont-img-mapa img {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}
@media only screen and (max-width: 320px) {
  .lambdas {
    height: 100vw;
  }
  .lambdas .cont-lambdas {
    flex-flow: column;
  }
  .lambdas .cont-lambdas .lambdas-img {
    width: 100%;
    height: calc(50% - 12vw);
    margin-top: 12vw;
  }
  .lambdas .cont-lambdas .lambdas-img .box {
    width: 90%;
    height: 65%;
    margin: 0;
  }
  .lambdas .cont-lambdas .lambdas-img .box .titulo {
    font-size: 2.4vw;
  }
  .lambdas .cont-lambdas .lambdas-img .box .content {
    font-size: 2.2vw;
    line-height: 2.5vw;
    font-family: montserrat_regular;
  }

  .lambdas .cont-lambdas .lambdas-text {
    width: 85%;
    height: 50%;

  }
}
@media only screen and (min-width: 321px) and (max-width: 480px) {
  .lambdas {
    height: 100vw;
  }
  .lambdas .cont-lambdas {
    flex-flow: column;
  }
  .lambdas .cont-lambdas .lambdas-img {
    width: 100%;
    height: calc(50% - 12vw);
    margin-top: 12vw;
  }
  .lambdas .cont-lambdas .lambdas-img .box {
    width: 90%;
    height: 65%;
    margin: 0;
  }
  .lambdas .cont-lambdas .lambdas-img .box .titulo {
    font-size: 2.4vw;
  }
  .lambdas .cont-lambdas .lambdas-img .box .content {
    font-size: 2.2vw;
    line-height: 2.5vw;
    font-family: montserrat_regular;
  }

  .lambdas .cont-lambdas .lambdas-text {
    width: 85%;
    height: 50%;

  }
  
}
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .lambdas {
    height: 100vw;
  }
  .lambdas .cont-lambdas {
    flex-flow: column;
  }
  .lambdas .cont-lambdas .lambdas-img {
    width: 100%;
    height: calc(50% - 12vw);
    margin-top: 12vw;
  }
  .lambdas .cont-lambdas .lambdas-img .box {
    width: 90%;
    height: 65%;
    margin: 0;
  }
  .lambdas .cont-lambdas .lambdas-img .box .titulo {
    font-size: 2.4vw;
  }
  .lambdas .cont-lambdas .lambdas-img .box .content {
    font-size: 2.2vw;
    line-height: 2.5vw;
    font-family: montserrat_regular;
  }

  .lambdas .cont-lambdas .lambdas-text {
    width: 85%;
    height: 50%;

  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  
}
@media only screen and (min-width: 1025px) and (max-width: 1199px) {
  
}
</style>

<script>
  export default {
    data: function () {
      return {
        it: [
          {
            a:[
              "Presencia en el NAP de Miami y Bogotá, en donde nos interconectamos a más de 8 TIER 1.",
              "Conexión directa a Google, Facebook y demás redes de distribución de contenidos (CDNs).",
              "Estamos conectados al NAP de Ecuador y tenemos CDNs de Google, Facebook, Netflix y Akamai en Guayaquil, Quito, Santo Domingo, Manta Machala y Cuenca."
            ],
            b: [
              "Presencia en el NAP de Miami y Bogotá, en donde nos interconectamos a más de 8 TIER 1.",
              "Conexión directa a Google, Facebook y demás redes de distribución de contenidos (CDNs).",
              "Estamos conectados al NAP de Ecuador y tenemos CDNs de Google, Facebook, Netflix y Akamai en Guayaquil, Quito, Santo Domingo, Manta Machala y Cuenca."
            ]
          },
          {
            a:[
              "Presencia en el NAP de Miami y Bogotá, en donde nos interconectamos a más de 8 TIER 1.",
              "Conexión directa a Google, Facebook y demás redes de distribución de contenidos (CDNs).",
              "Estamos conectados al NAP de Ecuador y tenemos CDNs de Google, Facebook, Netflix y Akamai en Guayaquil, Quito, Santo Domingo, Manta Machala y Cuenca."
            ],
            b: [
              "Presencia en el NAP de Miami y Bogotá, en donde nos interconectamos a más de 8 TIER 1.",
              "Conexión directa a Google, Facebook y demás redes de distribución de contenidos (CDNs).",
              "Estamos conectados al NAP de Ecuador y tenemos CDNs de Google, Facebook, Netflix y Akamai en Guayaquil, Quito, Santo Domingo, Manta Machala y Cuenca."
            ]
          },
          {
            a:[
              "Presencia en el NAP de Miami y Bogotá, en donde nos interconectamos a más de 8 TIER 1.",
              "Conexión directa a Google, Facebook y demás redes de distribución de contenidos (CDNs).",
              "Estamos conectados al NAP de Ecuador y tenemos CDNs de Google, Facebook, Netflix y Akamai en Guayaquil, Quito, Santo Domingo, Manta Machala y Cuenca."
            ],
            b: [
              "Presencia en el NAP de Miami y Bogotá, en donde nos interconectamos a más de 8 TIER 1.",
              "Conexión directa a Google, Facebook y demás redes de distribución de contenidos (CDNs).",
              "Estamos conectados al NAP de Ecuador y tenemos CDNs de Google, Facebook, Netflix y Akamai en Guayaquil, Quito, Santo Domingo, Manta Machala y Cuenca."
            ]
          }
        ],
        slide: {
          size: 0,
          slides: {
            active: 0,
            buttons: [],
            items: []
          }
        }
      };
    },
    beforeMount: function() {
      //this.loadItems();
    },
    methods: {
      getClassSlideItem: function(index) {
        return {
          slide: true,
          active: this.slide.slides.active === index
        };
      },
      getClassItem: function() {
        return {
          item: true
        };
      },
      getClassButton: function(index) {
        return {
          shadow: true,
          button: true,
          active: this.slide.slides.active === index
        };
      },
      changeActiveSection: function(index) {
        this.slide.slides.active = index;
      },
      loadItems:  function() {
        this.slide.size = this.it.length;
        let indices = 0;
        for (let i = 0; i < this.slide.size; i += 1) {
          let aux = {
            key: "slide_" + i,
            index: indices,
            item: []
          };
          for (let j = 0; j < 1; j++) {
            aux.item.push(
              i + j < this.slide.size ? this.it[i + j] : null
            );
          }
          this.slide.slides.items.push(aux);
          indices += 1;
        }
        for (let j = 0; j < this.slide.slides.items.length; j++) {
          this.slide.slides.buttons.push({
            key: "button_" + j,
            index: j
          });
        }
      }
    }
  }
</script>