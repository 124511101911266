<template>
  <div class="red full-width row" v-scrollanimation>
    <div class="cont-red flex-column-center full-height row">
      <div class="slider" v-scrollanimation>
        <div
          :key="s.key"
          v-for="s in slide.slides.items"
          :class="getClassSlideItem(s.index)"
        >
          <div
            :key="'item_' + pos"
            v-for="(i, pos) in s.item"
            :class="getClassItem()"
            class="flex-row-center align-row-center"
            v-show="i !== null"
          >
            <div class="middle flex-column-center a">
              <div class="cont-mapa contenedor-red">
                <img :src="i.a" alt="" v-if="i !== null" />
              </div>
            </div>
            <div class="middle flex-column-center b">
              <span class="title_section titulo" v-if="i !== null">
                {{ i.titulo }}
              </span>
              <div class="cont-caja contenedor-red">
                <img :src="i.b" alt="" v-if="i !== null" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="buttons" v-scrollanimation>
        <div
          :key="btn.key"
          v-for="btn in slide.slides.buttons"
          :class="getClassButton(btn.index)"
          @click="changeActiveSection(btn.index)"
        ></div>
      </div>
    </div>
  </div>
</template>

<style>
.red {
  height: 45vw;
  position: relative;
}

.red .cont-red {
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  width: 100%;
  height: 100%;
  top: -6vw;
  left: 0;
}

.red .cont-red .slider {
  position: relative;
  width: 90%;
  height: calc(100% - 6vw);
  margin: 0 auto;
}

.red .cont-red .slider .slide {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row wrap;

  position: absolute;
  z-index: -1;
  opacity: 0;
  transition: all 0.3s 0.3s ease;
}

.red .cont-red .slider .slide.active {
  z-index: 1;
  opacity: 1;
  transition: all 0.3s 0.3s ease;
}

.red .cont-red .slider .slide .item {
  width: 100%;
  height: 100%;
}

.red .cont-red .slider .slide .middle.b {
  width: calc(50% - 1.5vw);
  padding-left: 1.5vw;
}

.red .cont-red .slider .slide .contenedor-red {
  width: 100%;
  height: 100%;
  display: block;
}

.red .cont-red .slider .slide .contenedor-red img { 
  width: 100%;
  display: block;
  height: 100%;
  margin: 0 auto;
}
.red .cont-red .slider .slide .contenedor-red.cont-caja {
  width: 80%;
  height: 88%; 
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.red .cont-red .slider .slide .contenedor-red.cont-mapa {
  
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  align-items: flex-end;
}
.red .cont-red .slider .slide .contenedor-red.cont-mapa img {
  width: 100%;
  display: block;
  margin: 0 auto;
}

.red .cont-red .slider .slide .middle.b span {
  color: #1A4C94;
  text-transform: uppercase;
  display: block;
  width: calc(100% - 6vw);
  padding-left: 6vw;
}

.red .cont-red .slider .slide .middle.b span.titulo {
  margin-bottom: 0.6vw;
}

.red .cont-red .slider .slide .middle.b span.capacidad{
  width: calc(100% - 5vw);
  padding-left: 5vw;
}

.red .cont-red .slider .slide .middle.b span.capacidad span{
  padding: 0.5vw 1vw;
  background: #f4f4f4;
  width: auto !important;
  display: unset;
  border-radius: 1vw;
}

.red .cont-red .buttons {
  width: 100%;
  height: 4vw;
  margin: 0 auto;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}
.red .cont-red .buttons .button {
  background: #1A4C94;
  width: 0.9vw;
  height: 0.9vw;
  border-radius: 50%;
  margin: 0 0.2vw;
  cursor: pointer;
  transition: all 0.3s 0.3s ease;
}
.red .cont-red .buttons .button:active {
  transform: scale(0.98);
}
.red .cont-red .buttons .button.active {
  background: #1A4C94;
  width: 1.5vw;
  height: 1.5vw;
  transition: all 0.3s 0.3s ease;
}


@media only screen and (max-width: 320px) {
  .red {
    height: 100vw;
  }
  .red .cont-red .slider .slide .item {
    flex-flow: column;
  }

  .red .cont-red .slider .slide .item .a {
    width: 100%;
    height: 50%;
  }

  .red .cont-red .slider .slide .contenedor-red {
    max-width: 80%;
    max-height: 100%;
    width: auto;
    height: auto;
    margin: 0 auto;
    display: block;
  }
  .red .cont-red .slider .slide .contenedor-red img{
    max-width: 70%;
    max-height: 100%;
    width: auto;
    height: auto;
  }

  .red .cont-red .slider .slide .middle.b span.titulo {
    font-size: 2.4vw;
  }
  .red .cont-red .slider .slide .item .b {
    width: 100%;
    height: 50%;
  }
  .red .cont-red .slider .slide .contenedor-red.cont-caja img {
    width: 52%;
  }

  .red .cont-red .buttons .button {
    margin: 0 0.8vw;
    width: 1.2vw;
    height: 1.1vw;
  }
  .red .cont-red .buttons .button.active {
    width: 1.88vw;
    height: 2vw;
  }
}
@media only screen and (min-width: 321px) and (max-width: 480px) {
  .red {
    height: 100vw;
  }
  .red .cont-red .slider .slide .item {
    flex-flow: column;
  }

  .red .cont-red .slider .slide .item .a {
    width: 100%;
    height: 50%;
  }

  .red .cont-red .slider .slide .contenedor-red {
    max-width: 80%;
    max-height: 100%;
    width: auto;
    height: auto;
    margin: 0 auto;
    display: block;
  }
  .red .cont-red .slider .slide .contenedor-red img{
    max-width: 70%;
    max-height: 100%;
    width: auto;
    height: auto;
  }

  .red .cont-red .slider .slide .middle.b span.titulo {
    font-size: 2.4vw;
  }
  .red .cont-red .slider .slide .item .b {
    width: 100%;
    height: 50%;
  }
  .red .cont-red .slider .slide .contenedor-red.cont-caja img {
    width: 52%;
  }

  .red .cont-red .buttons .button {
    margin: 0 0.8vw;
    width: 1.2vw;
    height: 1.1vw;
  }
  .red .cont-red .buttons .button.active {
    width: 1.88vw;
    height: 2vw;
  }
  
}
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .red {
    height: 100vw;
  }
  .red .cont-red .slider .slide .item {
    flex-flow: column;
  }

  .red .cont-red .slider .slide .item .a {
    width: 100%;
    height: 50%;
  }

  .red .cont-red .slider .slide .contenedor-red {
    max-width: 80%;
    max-height: 100%;
    width: auto;
    height: auto;
    margin: 0 auto;
    display: block;
  }
  .red .cont-red .slider .slide .contenedor-red img{
    max-width: 70%;
    max-height: 100%;
    width: auto;
    height: auto;
  }

  .red .cont-red .slider .slide .middle.b span.titulo {
    font-size: 2.4vw;
  }
  .red .cont-red .slider .slide .item .b {
    width: 100%;
    height: 50%;
  }
  .red .cont-red .slider .slide .contenedor-red.cont-caja img {
    width: 52%;
  }

  .red .cont-red .buttons .button {
    margin: 0 0.8vw;
    width: 1.2vw;
    height: 1.1vw;
  }
  .red .cont-red .buttons .button.active {
    width: 1.88vw;
    height: 2vw;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  
}
@media only screen and (min-width: 1025px) and (max-width: 1199px) {
  
}
</style>


<script>
import mapA from "../../../assets/img/soluciones/mapA.png";
import mapB from "../../../assets/img/soluciones/mapB.png";
import txtA from "../../../assets/img/soluciones/txtA.png";
import txtB from "../../../assets/img/soluciones/txtB.png";

export default {
  data: function() {
    return {
      it: [
        {
          a: mapB,
          b: txtB,
          titulo: "Red de transporte nacional 1:",
          capacidad: "500 GB"
        },
        {
          a: mapA,
          b: txtA,
          titulo: "Red de transporte nacional 2:",
          capacidad: "500 GB"
        }
      ],
      slide: {
        size: 0,
        slides: {
          active: 0,
          buttons: [],
          items: []
        }
      }
    };
  },
  beforeMount: function() {
    this.loadItems();
  },
  methods: {
    getClassSlideItem: function(index) {
      return {
        slide: true,
        active: this.slide.slides.active === index
      };
    },
    getClassItem: function() {
      return {
        item: true
      };
    },
    getClassButton: function(index) {
      return {
        shadow: true,
        button: true,
        active: this.slide.slides.active === index
      };
    },
    changeActiveSection: function(index) {
      this.slide.slides.active = index;
    },
    loadItems: function() {
      this.slide.size = this.it.length;
      let indices = 0;
      for (let i = 0; i < this.slide.size; i += 1) {
        let aux = {
          key: "slide_" + i,
          index: indices,
          item: []
        };
        for (let j = 0; j < 1; j++) {
          aux.item.push(i + j < this.slide.size ? this.it[i + j] : null);
        }
        this.slide.slides.items.push(aux);
        indices += 1;
      }
      for (let j = 0; j < this.slide.slides.items.length; j++) {
        this.slide.slides.buttons.push({
          key: "button_" + j,
          index: j
        });
      }
    }
  }
};
</script>