<template>
  <div class="acceso full-width row" v-scrollanimation>
    <div class="fondo-acceso" v-scrollanimation></div>
    
    <div class="cont-acceso flex-row-center full-height">
      <div class="middle acceso-img flex-row-center align-row-center" v-scrollanimation>
        <div class="box">
          <div class="cont-img">
            <img src="../../../assets/img/soluciones/icono-acceso.png" alt="" />
          </div>
          <span class="titulo title_section"> Acceso a Internet</span>
          <span class="content">Somos un Carrier Dual-Multihomed porque tenemos conexiones redundantes con más de 8 Tier 1 y presencia en los principales IXs del Planeta. Obtén el acceso a Internet con la menor latencia y el menor número de saltos con la conexión IP de alta velocidad y disponibilidad de Nedetel. </span>
        </div>
      </div>
      <div class="middle acceso-text flex-column-center align-column-center" v-scrollanimation>

        <div class="actions flex-row-center">
          <div v-for="act in actions" :key="act.key" class="action flex-column-center" @click="selectSlider(act.key)" :class="getStyleActions(act.key)">
            <span class="title_section">{{ act.titulo }}</span>
            <div class="line"></div>
          </div>
        </div>

        <div class="slider esquema" v-if="slide_img.active">
          <div class="cont-img flex-column-center align-column-center">
            <img src="../../../assets/img/soluciones/mapa-acceso.png" alt="">
          </div>
        </div>

        <div class="slider beneficios" v-if="slide.active" v-scrollanimation>
          <div :key="s.key" v-for="s in slide.slides.items" :class="getClassSlideItem(s.index)">
            <div
              :key="'item_' + pos"
              v-for="(i, pos) in s.item"
              :class="getClassItem()"
              class="flex-row-center align-row-center"
              v-show="i !== null"
            >
              <div class="middle flex-column-center a">
                <div class="texto flex-row-center" v-for="(txt, index) in i.a" :key="index" >
                  <div class="circle" v-if="txt !== ''">
                    <span></span>
                  </div>
                  <span class="content">{{txt}}</span>
                </div>
              </div>
              <div class="middle flex-column-center b">
                <div class="texto flex-row-center" v-for="(txt, index) in i.b" :key="index" >
                  <div class="circle" v-if="txt !== ''">
                    <span></span>
                  </div>
                  <span class="content">{{txt}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="buttons" v-if="slide.active" v-scrollanimation>
          <div
            :key="btn.key"
            v-for="btn in slide.slides.buttons"
            :class="getClassButton(btn.index)"
            @click="changeActiveSection(btn.index)"
          ></div>
        </div>

        <div class="slider caracteristicas" v-if="slide_crt.active">
          <div :key="s.key" v-for="s in slide_crt.slides.items" :class="getClassSlideCrtItem(s.index)">
            <div
              :key="'item_' + pos"
              v-for="(i, pos) in s.item"
              :class="getClassCrtItem()"
              class="flex-row-center align-row-center"
              v-show="i !== null"
            >
              <div class="middle flex-column-center a">
                <div class="texto flex-row-center" v-for="(txt, index) in i.a" :key="index" >
                  <div class="circle" v-if="txt !== ''">
                    <span></span>
                  </div>
                  <span class="content">{{txt}}</span>
                </div>
              </div>
              <div class="middle flex-column-center b">
                <div class="texto flex-row-center" v-for="(txt, index) in i.b" :key="index" >
                  <div class="circle" v-if="txt !== ''">
                    <span></span>
                  </div>
                  <span class="content">{{txt}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="buttons" v-if="slide_crt.active" v-scrollanimation>
          <div
            :key="btn.key"
            v-for="btn in slide_crt.slides.buttons"
            :class="getClassCrtButton(btn.index)"
            @click="changeActiveCrtSection(btn.index)"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.acceso {
  height: 55vw;
  position: relative;
}

.acceso .fondo-acceso {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -2;
  background-image: url("../../../assets/img/soluciones/fondo-acceso.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.acceso .cont-acceso {

}

.acceso .cont-acceso .acceso-img {
  width: 40%;
  position: relative;
}

.acceso .cont-acceso .acceso-img .box {
  width: calc(85% - 1.6vw);
  margin: 0 0.8vw;
  height: 60%;
  border-radius: 1vw;
  background: #fff;
  position: absolute;
  bottom: 4vw;
}

.acceso .cont-acceso .acceso-img .box .cont-img {
  width: 10vw;
  height: 10vw;
  display: block;
  margin: -5vw auto 2vw auto;
}

.acceso .cont-acceso .acceso-img .box .cont-img img { 
  width: 100%;
  height: 100%;
}

.acceso .cont-acceso .acceso-img .box span {
  display: block;
  width: calc(100% - 3vw);
  padding: 0 1.5vw;
}

.acceso .cont-acceso .acceso-img .box .titulo {
  text-transform: uppercase;
  margin-bottom: 2vw;
  color: #1A4C94;
  text-align: center;
  font-size: 1.4vw;
}


.acceso .cont-acceso .acceso-img .box .content {
  height: calc(100% - 12vw);
  overflow: scroll;
  width: calc(100% - 6vw);
  padding: 0 3vw;
  text-align: justify;
  line-height: 1.3vw;
  color: #333333;
}

.acceso .cont-acceso .acceso-text {
  width: 60%;
  position: relative;
  margin: 0 auto;
  align-items: flex-end;
  justify-content: flex-end;
}

.acceso .cont-acceso .acceso-text .actions { 
  width: 90%;
  justify-content: flex-start;
  align-items: center;
  height: 2.5vw;
  margin: 0 auto;
}

.acceso .cont-acceso .acceso-text .actions .action {
  height: 100%;
  width: auto;
  padding: 0 2.5vw 0 0;
  transform: scale(0.94);
  transition: all 0.1s 0.1s ease-in;
}

.acceso .cont-acceso .acceso-text .actions .action.active {
  transform: scale(1.1);
  transition: all 0.1s 0.1s ease-in-out;
}

.acceso .cont-acceso .acceso-text .actions .action:hover { 
  cursor: pointer;
}

.acceso .cont-acceso .acceso-text .actions .action span { 
  font-size: 1.4vw;
  text-transform: uppercase;
}

.acceso .cont-acceso .acceso-text .actions .action .line { 
  background: #fff;
  height: 0.3vw;
  border-radius: 6px;
  display: block;
  width: 60%;
  float: left;
}

.acceso .cont-acceso .acceso-text .slider.esquema { 
  height: calc(35vw - 4vw);
  display: flex;
  margin-bottom: 4vw;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}

.acceso .cont-acceso .acceso-text .slider.esquema .cont-img { 
  width: 100%;
  height: 71%;
  background: #fff;
  border-radius: 8px;

}

.acceso .cont-acceso .acceso-text .slider.esquema .cont-img img { 
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  border-radius: 8px;
}

.acceso .cont-acceso .acceso-text .slider { 
  position: relative;
  width: 90%;
  height: calc(35vw - 10vw);
  margin: 0 auto;
  transition: all 0.1s 0.1s ease-in-out;
}

.acceso .cont-acceso .acceso-text .slider .slide {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row wrap;

  position: absolute;
  z-index: -1;
  opacity: 0;
  transition: all 0.3s 0.3s ease;
}

.acceso .cont-acceso .acceso-text .slider .slide.active {
  z-index: 1;
  opacity: 1;
  transition: all 0.3s 0.3s ease;
}

.acceso .cont-acceso .acceso-text .slider .slide .texto {
  height: auto;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 2vw;
}

.acceso .cont-acceso .acceso-text .slider .slide .middle.a {
  width: calc(50% - 1.5vw);
  height: calc(100% - 4vw);
  padding-right: 1.5vw;
  padding-top: 4vw;
  justify-content: flex-start;
}

.acceso .cont-acceso .acceso-text .slider .slide .middle.b {
  width: calc(50% - 1.5vw);
  height: calc(100% - 4vw);
  padding-left: 1.5vw;
  padding-top: 4vw;
  justify-content: flex-start;
}

.acceso .cont-acceso .acceso-text .slider .slide .texto .circle {
  width: calc(100% - 90%);
  height: calc(100% - 0.5vw);
  padding-top: 0.5vw;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: flex-start;
}

.acceso .cont-acceso .acceso-text .slider .slide .texto .circle span {
  background: #fff;
  width: 0.6vw;
  height: 0.6vw;
  border-radius: 50%;
}

.acceso .cont-acceso .acceso-text .slider .slide .texto .content {
  height: 100%;
  width: calc(100% - 10%);
  font-size: 1vw;
  text-align: justify;
}

.acceso .cont-acceso .acceso-text .buttons {
  width: 90%;
  height: 10vw;
  margin: 0 auto;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
}
.acceso .cont-acceso .acceso-text .buttons .button {
  background: #f2f2f2;
  width: 0.9vw;
  height: 0.9vw;
  border-radius: 50%;
  margin: 0 0.2vw;
  cursor: pointer;
  transition: all 0.3s 0.3s ease;
}
.acceso .cont-acceso .acceso-text .buttons .button:active {
  transform: scale(0.98);
}
.acceso .cont-acceso .acceso-text .buttons .button.active {
  background: #fff;
  width: 1.5vw;
  height: 1.5vw;
  transition: all 0.3s 0.3s ease;
}

@media only screen and (max-width: 320px) {
  .acceso {
    height: 110vw;
  }
  .acceso .cont-acceso {
    flex-flow: column;
  }
  .acceso .cont-acceso .acceso-img {
    width: 100%;
    height: 42%;
  }
  .acceso .cont-acceso .acceso-img .box {
    width: 90%;
    margin: 0;
  }

  .acceso .cont-acceso .acceso-img .box .titulo {
    font-size: 2.4vw;
  }
  .acceso .cont-acceso .acceso-img .box .content {
    font-size: 2.2vw;
    line-height: 2.5vw;
    font-family: montserrat_regular;
  }
  .acceso .cont-acceso .acceso-text {
    width: 100%;
    height: 50%;
  }

  .acceso .cont-acceso .acceso-text .actions {
    height: 8.5vw;
  }

  .acceso .cont-acceso .acceso-text .actions .action span {
    font-size: 2vw;
  }

  .acceso .cont-acceso .acceso-text .slider.esquema {
    height: calc(44vw - 4vw);
  }

  .acceso .cont-acceso .acceso-text .slider.beneficios {
    height: calc(56vw - 4vw);
  }

  .acceso .cont-acceso .acceso-text .slider.caracteristicas {
    height: calc(56vw - 4vw);
  }

  .acceso .cont-acceso .acceso-text .slider.esquema .cont-img {
    height: 100%;
  }
  .acceso .cont-acceso .acceso-text .slider.esquema .cont-img img {
    height: auto;
    max-height: 98%;
    max-width: 100%;
    width: auto;
  }

  .acceso .cont-acceso .acceso-text .slider .slide .texto .content {
    font-size: 1.9vw;
    font-family: montserrat_regular;
  }
  
}
@media only screen and (min-width: 321px) and (max-width: 480px) {
  .acceso {
    height: 110vw;
  }
  .acceso .cont-acceso {
    flex-flow: column;
  }
  .acceso .cont-acceso .acceso-img {
    width: 100%;
    height: 42%;
  }
  .acceso .cont-acceso .acceso-img .box {
    width: 90%;
    margin: 0;
  }

  .acceso .cont-acceso .acceso-img .box .titulo {
    font-size: 2.4vw;
  }
  .acceso .cont-acceso .acceso-img .box .content {
    font-size: 2.2vw;
    line-height: 2.5vw;
    font-family: montserrat_regular;
  }
  .acceso .cont-acceso .acceso-text {
    width: 100%;
    height: 50%;
  }

  .acceso .cont-acceso .acceso-text .actions {
    height: 8.5vw;
  }

  .acceso .cont-acceso .acceso-text .actions .action span {
    font-size: 2vw;
  }

  .acceso .cont-acceso .acceso-text .slider.esquema {
    height: calc(44vw - 4vw);
  }

  .acceso .cont-acceso .acceso-text .slider.beneficios {
    height: calc(56vw - 4vw);
  }

  .acceso .cont-acceso .acceso-text .slider.caracteristicas {
    height: calc(56vw - 4vw);
  }

  .acceso .cont-acceso .acceso-text .slider.esquema .cont-img {
    height: 100%;
  }
  .acceso .cont-acceso .acceso-text .slider.esquema .cont-img img {
    height: auto;
    max-height: 98%;
    max-width: 100%;
    width: auto;
  }

  .acceso .cont-acceso .acceso-text .slider .slide .texto .content {
    font-size: 1.9vw;
    font-family: montserrat_regular;
  }
}
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .acceso {
    height: 110vw;
  }
  .acceso .cont-acceso {
    flex-flow: column;
  }
  .acceso .cont-acceso .acceso-img {
    width: 100%;
    height: 42%;
  }
  .acceso .cont-acceso .acceso-img .box {
    width: 90%;
    margin: 0;
  }

  .acceso .cont-acceso .acceso-img .box .titulo {
    font-size: 2.4vw;
  }
  .acceso .cont-acceso .acceso-img .box .content {
    font-size: 2.2vw;
    line-height: 2.5vw;
    font-family: montserrat_regular;
  }
  .acceso .cont-acceso .acceso-text {
    width: 100%;
    height: 50%;
  }

  .acceso .cont-acceso .acceso-text .actions {
    height: 8.5vw;
  }

  .acceso .cont-acceso .acceso-text .actions .action span {
    font-size: 2vw;
  }

  .acceso .cont-acceso .acceso-text .slider.esquema {
    height: calc(44vw - 4vw);
  }

  .acceso .cont-acceso .acceso-text .slider.beneficios {
    height: calc(56vw - 4vw);
  }

  .acceso .cont-acceso .acceso-text .slider.caracteristicas {
    height: calc(56vw - 4vw);
  }

  .acceso .cont-acceso .acceso-text .slider.esquema .cont-img {
    height: 100%;
  }
  .acceso .cont-acceso .acceso-text .slider.esquema .cont-img img {
    height: auto;
    max-height: 98%;
    max-width: 100%;
    width: auto;
  }

  .acceso .cont-acceso .acceso-text .slider .slide .texto .content {
    font-size: 1.9vw;
    font-family: montserrat_regular;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  
}
@media only screen and (min-width: 1025px) and (max-width: 1199px) {
  
}
</style>

<script>
  export default {
    data: function () {
      return {

        actions: [
          {
            key: 0,
            titulo: "Esquema  "
          },
          {
            key: 1,
            titulo: "Beneficios"
          },
          {
            key: 2,
            titulo: "Características"
          }
        ],  


        beneficios: [
          {
            a:[
              "Contamos con cinco salidas Internacionales, tres submarinas y dos terrestres.",
              "Latencias iguales o menores a 70 ms.",
              "Estamos conectados al NAP de Ecuador y tenemos CDNs de Google, Facebook, Netflix y Akamai en Guayaquil, Quito, Santo Domingo, Manta Machala y Cuenca.",
              "SLA 99.95%.",
            ],
            b: [
              "Gestión y monitoreo de la red 24x7x365 a través de nuestro NOC con personal propio.",
              "Presencia en el NAP de Miami y Bogotá, en donde nos interconectamos a más de 8 TIER 1.",
              "Conexión directa a Google, Facebook y demás redes de distribución de contenidos (CDNs).",
              "",
            ]
          }
        ],
        slide_active: 0,
        slide_img: {
          active: true
        },
        slide: {
          active: false,
          size: 0,
          slides: {
            active: 0,
            buttons: [],
            items: []
          }
        },
        caracteristicas: [
          {
            a:[
              "5 Salidas internacionales ruteadas a través de los cables submarinos y terrestres: PCCS (Pacific Caribbean Cable System), SAM-1 (South America 1), PAN-AM (Cable Panamerico), SAC (South American Crossing) CFX (Colombia-Florida Express).",
              "Gestión y monitoreo de la red 24x7x365 a través de nuestro NOC con personal propio.",
              "Diferentes conexiones a operadores Tier1."
              
            ],
            b: [
              "Sin Reuso, disponibilidad permanente del ancho de banda contratado, sin degradación del servicio y simetría 1:1",
              "Plataforma de enrutamiento que soporta Terabytes de Throughput que garantiza que no habrá congestión por las altas demandas de tráfico.",
              "Conexión al NAP del Ecuador, Bogotá y Miami."
            ]
          },
          {
            a:[
              "El sistema está diseñado con interfaces de 1G Eth, 10G Eth y 100G Eth, lo que permite un aprovisionamiento más rápido para incrementos de capacidad o demos.",
              "Enrutamiento en IPv4 e IPv6 nativo en toda la red y proveedores internacionales."
              
            ],
            b: [
              "Información permanente en caso de falla sobre el avance, la solución de los eventos y el tiempo de restitución de los servicios.",
              ""
            ]
          }
        ],
        slide_crt: {
          active: false,
          size: 0,
          slides: {
            active: 0,
            buttons: [],
            items: []
          }
        }
      };
    },
    beforeMount: function() {
      this.loadItems();
      this.loadCrtItems();
    },
    methods: {
      getClassSlideItem: function(index) {
        return {
          slide: true,
          active: this.slide.slides.active === index
        };
      },
      getClassItem: function() {
        return {
          item: true
        };
      },
      getClassButton: function(index) {
        return {
          shadow: true,
          button: true,
          active: this.slide.slides.active === index
        };
      },
      changeActiveSection: function(index) {
        this.slide.slides.active = index;
      },
      loadItems:  function() {
        this.slide.size = this.beneficios.length;
        let indices = 0;
        for (let i = 0; i < this.slide.size; i += 1) {
          let aux = {
            key: "slide_" + i,
            index: indices,
            item: []
          };
          for (let j = 0; j < 1; j++) {
            aux.item.push(
              i + j < this.slide.size ? this.beneficios[i + j] : null
            );
          }
          this.slide.slides.items.push(aux);
          indices += 1;
        }
        for (let j = 0; j < this.slide.slides.items.length; j++) {
          this.slide.slides.buttons.push({
            key: "button_" + j,
            index: j
          });
        }
      },


      selectSlider: function(index) {
        this.slide_active = index;
        if(index === 0) {
          this.slide_img.active = true;
          this.slide_crt.active = false;
          this.slide.active = false;
        } else if(index === 1) {
          this.slide_img.active = false;
          this.slide_crt.active = false;
          this.slide.active = true;
        } else if(index === 2){
          this.slide_img.active = false;
          this.slide.active = false;
          this.slide_crt.active = true;
        }
      },

      getStyleActions: function(index) {
        return {
          active: this.slide_active === index
        }
      },

      getClassSlideCrtItem: function(index) {
        return {
          slide: true,
          active: this.slide_crt.slides.active === index
        };
      },
      getClassCrtItem: function() {
        return {
          item: true
        };
      },
      getClassCrtButton: function(index) {
        return {
          shadow: true,
          button: true,
          active: this.slide_crt.slides.active === index
        };
      },
      changeActiveCrtSection: function(index) {
        this.slide_crt.slides.active = index;
      },
      loadCrtItems:  function() {
        this.slide_crt.size = this.caracteristicas.length;
        let indices = 0;
        for (let i = 0; i < this.slide_crt.size; i += 1) {
          let aux = {
            key: "slide_" + i,
            index: indices,
            item: []
          };
          for (let j = 0; j < 1; j++) {
            aux.item.push(
              i + j < this.slide_crt.size ? this.caracteristicas[i + j] : null
            );
          }
          this.slide_crt.slides.items.push(aux);
          indices += 1;
        }
        for (let j = 0; j < this.slide_crt.slides.items.length; j++) {
          this.slide_crt.slides.buttons.push({
            key: "button_" + j,
            index: j
          });
        }
      }


    }
  }
</script>