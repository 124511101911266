<template>
  <div class="sections-sol">
    <div class="cont-sections-sol flex-column-center full-width full-height row">
      <div class="menu-sol flex-row-center align-row-center">
        <div class="buttons flex-row-center align-row-center full-width full-height">
          <div
            class="button flex-column-center"
            :key="btn.key"
            v-for="btn in menu.buttons"
            :class="getButtonSolClass(btn.key)"
            @click="changeSolActive(btn.key)"
          >
            <div class="cont-img">
              <img :src="btn.url" alt="" class="full-width full-height"/>
            </div>
            <span class="titulo-button title_section">{{ btn.title }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="subcmps">
      <transition
        :duration="1000"
        appear
        appear-class="out_component"
        appear-to-class="enter_component"
        appear-active-class="enter_component"
        enter-class="out_component"
        enter-to-lass="enter_component"
        enter-active-class="enter_component"
        leave-class="enter_component"
        leave-to-class="out_component"
      >
        <div :class="getSlideClass(0)" class="cnt" v-if="menu.active === 0">
          <Conectividad></Conectividad>
          <Footer></Footer>
        </div>
        <div :class="getSlideClass(1)" class="inf" v-else-if="menu.active === 1">
          <Infra></Infra>
          <Footer></Footer>
        </div>
        <div :class="getSlideClass(2)" class="sgr" v-else-if="menu.active === 2">
          <Seguridad></Seguridad>
          <Footer></Footer>
        </div>
        <div :class="getSlideClass(3)" class="nb" v-else-if="menu.active === 3">
          <Nube></Nube>
          <Footer></Footer>
        </div>
      </transition>
    </div>
  </div>
</template>

<style>

.sections-sol {
  color: #fff;
  height: auto;
}

.sections-sol .cont-sections-sol {
  position: relative;
  height: 28vw;
  background: rgb(40, 78, 143);
}

.sections-sol .cont-sections-sol .menu-sol{
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: block;
}

.sections-sol .cont-sections-sol .menu-sol .buttons{ 
  position: relative;
} 

.sections-sol .cont-sections-sol .menu-sol .buttons .button {
  position: relative;
  width: calc(100% / 4);
  align-items: center;
  height: 100%;
  cursor: pointer;
  transition: all 0.3s 0.3s ease-in;
}

.sections-sol .cont-sections-sol .menu-sol .buttons .button.active {
  height: 100%;
  transition: all 0.3s 0.3s ease-in-out;
}

.sections-sol .cont-sections-sol .menu-sol .buttons .button .titulo-button {
  color: #fff;
  font-size: 1.2vw;
  height: 4vw;
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.3s 0.3s ease-in;
}

.sections-sol .cont-sections-sol .menu-sol .buttons .button.active .titulo-button {
  color: #fff;
  font-size: 1.8vw;
  height: 4vw;
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.3s 0.3s ease-in-out;
}

.sections-sol .cont-sections-sol .menu-sol .buttons .button .cont-img {
  width: 12vw;
  height: 12vw;
  margin-bottom: 1vw;
  transform: scale(0.85);
  transition: all 0.3s 0.3s ease-in;
}
.sections-sol .cont-sections-sol .menu-sol .buttons .button.active .cont-img {
  margin-bottom: 2vw;
  transform: scale(1.2);
  transition: all 0.3s 0.3s ease-in-out;
}

.sections-sol .subcmps {
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.sections-sol .subcmps .info_sol.active.cnt {
  height: 260vw;
  transition: all 0.3s 0.3s ease-in-out;
}

.sections-sol .subcmps .info_sol.active.inf {
  height: 90vw;
  transition: all 0.3s 0.3s ease-in-out;
}

.sections-sol .subcmps .info_sol.active.sgr {
  height: 45vw;
  transition: all 0.3s 0.3s ease-in-out;
}

.sections-sol .subcmps .info_sol.active.nb {
  height: 45vw;
  transition: all 0.3s 0.3s ease-in-out;
}

.sections-sol .subcmps .info_sol {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: none;
  flex-flow: column;
  opacity: 0;
  z-index: -1;
  transition: all 0.3s 0.3s ease-in;
}
.sections-sol .subcmps .info_sol.active {
  display: block;
  opacity: 1;
  z-index: 1;
  transition: all 0.3s 0.3s ease-in-out;
}

@media only screen and (max-width: 320px) {
  .sections-sol .cont-sections-sol .menu-sol .buttons .button .titulo-button {
    font-size: 2vw;
  }
  .sections-sol .cont-sections-sol .menu-sol .buttons .button.active .titulo-button {
    font-size: 2.2vw;
  }
  .sections-sol .subcmps .info_sol.active.cnt {
    height: 468vw;
  }
  .sections-sol .subcmps .info_sol.active.inf {
    height: 200vw;
  }
  .sections-sol .subcmps .info_sol.active.sgr {
    height: 65vw;
  }
  .sections-sol .subcmps .info_sol.active.nb {
    height: 65vw;
  }

}
@media only screen and (min-width: 321px) and (max-width: 480px) {
  .sections-sol .cont-sections-sol .menu-sol .buttons .button .titulo-button {
    font-size: 2vw;
  }
  .sections-sol .cont-sections-sol .menu-sol .buttons .button.active .titulo-button {
    font-size: 2.2vw;
  }
  .sections-sol .subcmps .info_sol.active.cnt {
    height: 468vw;
  }
  .sections-sol .subcmps .info_sol.active.inf {
    height: 200vw;
  }
  .sections-sol .subcmps .info_sol.active.sgr {
    height: 65vw;
  }
  .sections-sol .subcmps .info_sol.active.nb {
    height: 65vw;
  }
}
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .sections-sol .cont-sections-sol .menu-sol .buttons .button .titulo-button {
    font-size: 2vw;
  }
  .sections-sol .cont-sections-sol .menu-sol .buttons .button.active .titulo-button {
    font-size: 2.2vw;
  }
  .sections-sol .subcmps .info_sol.active.cnt {
    height: 468vw;
  }
  .sections-sol .subcmps .info_sol.active.inf {
    height: 200vw;
  }
  .sections-sol .subcmps .info_sol.active.sgr {
    height: 65vw;
  }
  .sections-sol .subcmps .info_sol.active.nb {
    height: 65vw;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .sections-sol .cont-sections-sol .menu-sol .buttons .button .titulo-button {

  }
}
@media only screen and (min-width: 1025px) and (max-width: 1199px) {
  .sections-sol .cont-sections-sol .menu-sol .buttons .button .titulo-button {

  }
}

</style>

<script>
import conectividad from "@/assets/img/soluciones/conectividad.png";
import infraestructura from "@/assets/img/soluciones/infraestructura.png";
import seguridad from "@/assets/img/soluciones/seguridad.png";
import nube from "@/assets/img/soluciones/nube.png";

import Conectividad from "../soluciones/subcomponents/Conectividad.vue";
import Infra from "../soluciones/subcomponents/Infra.vue";
import Seguridad from "../soluciones/subcomponents/Seguridad.vue";
import Nube from "../soluciones/subcomponents/Nube.vue";

import Footer from "../Footer.vue";

  export default {
    data: function() {
      return {
        menu: {
          active: 0,
          size: 4,
          buttons: [
            {
              key: 0, 
              title: "conectividad",
              url: conectividad
            },
            {
              key: 1, 
              title: "infraestructura",
              url: infraestructura
            },
            {
              key: 2, 
              title: "seguridad",
              url: seguridad
            },
            {
              key: 3, 
              title: "nube",
              url: nube
            }
          ]
        }
      };
    },
    beforeMount: function() {
      this.menu.active = this.section_active;
    },
    watch: {
      section_active: function(index) {
        this.menu.active = index;
      }
    },
    props: {
      section_active: {
        type: Number,
        default: 0
      }
    },
    methods: {
      getButtonSolClass(key) {
        return {
          button: true,
          active: this.menu.active == key ? true : false,
          last: this.menu.size == key + 1 ? true : false,
        };
      },
      changeSolActive(key) {
        this.menu.active = key;
      },
      getSlideClass(key) {
        return {
          info_sol: true,
          active: this.menu.active == key ? true : false,
        };
      }
    },
    components: {
      Conectividad,
      Infra,
      Seguridad,
      Nube,
      Footer
    }
  }
</script>