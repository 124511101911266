<template>
  <div class="contacto flex-column-center" v-scrollanimation>
    <Info></Info>

    <div class="info"></div>

    <div class="mapa" v-scrollanimation>
      <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d647.808806250413!2d-79.89919123131887!3d-2.1504285602413553!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x902d6d75617a4659%3A0x285b099becef5a37!2sNedetel!5e0!3m2!1ses!2sec!4v1606152187387!5m2!1ses!2sec" width="100%" height="100%" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe> -->
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7974.401886166871!2d-79.9268159584501!3d-2.075477250408481!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x902d0d9dafe3ed71%3A0x57908340d38c1e4!2sCiudad%20Digital%20Nedetel!5e0!3m2!1ses!2sec!4v1631038994726!5m2!1ses!2sec" width="100%" height="100%" rameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0" loading="lazy"></iframe>
      <!-- <gmap-map
        ref="map"
        :options="map.options"
        :center="map.coordenadas"
        :zoom="map.zoom"
        style="width:100%;height:100%;"
      >
        <gmap-marker
          :position="map.coordenadas"
          ref="marker"
          :draggable="true"
          @drag="setNewLocation($event.latLng)"
        ></gmap-marker>
      </gmap-map> -->
    </div>

    <div class="form row" @submit.prevent="send" v-scrollanimation>
      <div class="section">
        <span class="title_section a" v-scrollanimation>Contáctanos</span>

        <form action="" @action.prevent="" class="flex-column-center align-column-center" v-scrollanimation>

          <div class="label flex-row-center align-row-center">
            <span class="title_section">Nombres Completos <span class="obl title_section">*</span></span>
          </div>
          <input type="text" v-model="form.nombre.value" :placeholder="form.nombre.placeholder" required>

          <div class="label flex-row-center align-row-center">
            <span class="title_section">Contacto Telefónico <span class="obl title_section">*</span></span>
          </div>
          <input type="text" v-model="form.telefono.value" :placeholder="form.telefono.placeholder" required>

          <div class="label flex-row-center align-row-center">
            <span class="title_section">Correo electrónico <span class="obl title_section">*</span></span>
          </div>
          <input type="email" v-model="form.email.value" :placeholder="form.email.placeholder" required>

          <div class="label flex-row-center align-row-center">
            <span class="title_section">Asunto del mensaje <span class="obl title_section">*</span></span>
          </div>
          <input type="text" v-model="form.asunto.value" :placeholder="form.asunto.placeholder" required>
          
          <div class="label flex-row-center align-row-center">
            <span class="title_section">Mensaje<span class="obl title_section">*</span></span>
          </div>
          <textarea name="" id="" cols="30" rows="10" v-model="form.mensaje.value" :placeholder="form.mensaje.placeholder" required></textarea>

          <input type="submit" class="btn" value="Enviar" >
          <Status
            :mostrar="status.mostrar"
            :tipo="status.tipo"
            :mensaje="status.mensaje"
            :esperando="status.waiting"
            @changeStatus="changeStatus"
          ></Status>

        </form>

      </div>
    </div>
  </div>
</template>


<script>
import Info from "../home/Info.vue";
//import { gmapsMap, gmapsMarker } from 'x5-gmaps'
import Status from "../Status";

export default {
  name: "Contacto",
  data: function() {
    return {
      map: {
        zoom: 14,
        coordenadas: { lat: -2.1503253, lng: -79.9007817 },
        options: {
          zoomControl: true,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          disableDefaultUi: false
        },
        markers: [],
        info: {
          menu_class: {
            mapa_menu: true,
            shadow: true,
            active: false
          },
          tipo: -1,
          data: null
        },
        mapa_buttons: {
          active: -1
        }
      },
      status: {
        waiting: false,
        tipo: 0,
        mostrar: false,
        mensaje: ""
      },

      form: {
        nombre:{
          value: null,
          placeholder: "Ingrese sus nombres completos"
        },

        email:{
          value: null,
          placeholder: "Ingrese su correo electrónico"
        },

        telefono:{
          value: null,
          placeholder: "Número de teléfono"
        },

        asunto:{
          value: null,
          placeholder: "Asunto del mensaje"
        },

        mensaje:{
          value: null,
          placeholder: "Escriba aquí su mensaje"
        }
      }
    };
  },
  components: {
    Info,
    Status
    /* gmapsMap,
    gmapsMarker */
  },
  methods: {
    openB() {
      this.$refs.b.open()
    },

    getData: function() {
      return {
        nombre: this.form.nombre.value,
        email: this.form.email.value,
        telefono: this.form.telefono.value,
        asunto: this.form.asunto.value,
        mensaje: this.form.mensaje.value
      }
    },

    checkData: function() {
      let res = false;
      let { nombre, email, telefono, asunto, mensaje } = this.form;
      if(nombre.value && email.value && telefono.value && asunto.value && mensaje.value){
        res =  true;
      } 
      return res;
    },
    

    sendMail(data) {
      return this.axios.post(
        "https://us-central1-nedetel-43cc9.cloudfunctions.net/sendEmail",
        //"http://localhost:5001/nedetel-43cc9/us-central1/sendEmail",
        data
      );
    },

    send: async function() {
      let data = this.getData();
      if (this.checkData()) {
        this.changeStatusTo(0, "Enviando... Por favor espere.", true);
        let d = await this.sendMail(data);
        this.changeStatusTo(d.data.code === 0 ? 2 : 1, d.data.data, true);
        this.cleanForm();
      } else {
        this.changeStatusTo(1, "Aún existen campos incompletos", true);
      }
    },

    cleanForm: function() {
      this.form = {
        nombre:{
          value: null,
          placeholder: "Ingrese sus nombres completos"
        },

        email:{
          value: null,
          placeholder: "Ingrese su correo electrónico"
        },
        
        telefono:{
          value: null,
          placeholder: "Número de teléfono"
        },

        asunto:{
          value: null,
          placeholder: "Asunto del mensaje"
        },

        mensaje:{
          value: null,
          placeholder: "Escriba aquí su mensaje"
        }
      };
    },

    changeStatus: function(value) {
      this.status.mostrar = value;
    },
    changeStatusTo: function(tipo, mensaje, mostrar) {
      this.status.tipo = tipo;
      this.status.mensaje = mensaje;
      this.status.mostrar = mostrar;
    },
  }
};
</script>


<style>
.contacto {
  position: relative;
}

.contacto .info {
}

.contacto .mapa {
  width: 100%;
  height: 35vw;
  background: #f4f4f4;
}

.contacto .form {
  height: calc(100% - 16vw);
  background: #f0f0f0;
  transition: all 0.3s 0.3s ease-in-out;
}

.contacto .form .section {
  width: 100%;
  text-align: center;
  color: #1a4c94;
}

.contacto .form .section span.a {
  display: block;
  font-size: 2.4vw;
  margin-top: 8vw;
  text-transform: uppercase;
}

.contacto .form .section span.b {
  display: block;
  font-size: 1.2vw;
}

.contacto .form form {
  width: 65%;
  height: 100%;
  margin: 2vw auto 10vw auto;
}

.contacto .form form .label {
  margin-bottom: 0.7vw;
  width: 100%;
  text-align: left;
  padding-left: 2vw;
}
.contacto .form form .label span {
  width: 100%;
  text-align: left;
}

.contacto .form form .label .obl { 
  margin-left: 0.2vw;
  color: red;
  width: auto;
}


.contacto .form form input {
  width: calc(100% - 2vw);
  font-size: 0.9vw;
  font-family: montserrat_light;
  border-radius: 1vw;
  border: none;
  padding: 1vw;
  outline-width: 0;
  margin-bottom: 0.7vw;
}
.contacto .form form input:focus,
.contacto .form form textarea:focus {
  box-shadow: 0 0 0.5vw 0.05vw #1A4C94;
}

.contacto .form form textarea {
  margin-bottom: 0.7vw;
  width: calc(100% - 2vw);
  font-size: .9vw;
  font-family: montserrat_light;
  border-radius: 1vw;
  border: none;
  padding: 1vw;
  outline-width: 0;
  height: calc(100% - 2vw);
  resize: none;
}

.contacto .form form input.btn {
  background: #1A4C94;
  font-family: montserrat_medium;
  text-transform: uppercase;
  color: #fff;
  font-size: 0.9vw;
  cursor: pointer;
  margin: 0.2vw auto;
  display: block;
  width: 100%;
}

@media only screen and (max-width: 320px) {
  .contacto .mapa { 
    height: 50vw;
  }
  .contacto .form .section span.a {
    font-size: 4vw;
  }
  .contacto .form form {
    width: 80%;
  }
  .contacto .form form .label span {
    font-size: 1.8vw; 
  }
  .contacto .form form input {
    font-size: 1.6vw;
    margin-bottom: 1vw;
  }
  .contacto .form form textarea {
    font-size: 1.6vw;
    margin-bottom: 1vw; 
  }
  .contacto .form form input.btn {
    font-size: 1.8vw;
    margin: 0.8vw auto;
  }
}
@media only screen and (min-width: 321px) and (max-width: 480px) {
  .contacto .mapa { 
    height: 50vw;
  }
  .contacto .form .section span.a {
    font-size: 4vw;
  }
  .contacto .form form {
    width: 80%; 
  }
  .contacto .form form .label span {
    font-size: 1.8vw;
  }
  .contacto .form form input {
    font-size: 1.6vw;
    margin-bottom: 1vw;
  }
  .contacto .form form textarea {
    font-size: 1.6vw;
    margin-bottom: 1vw; 
  }
  .contacto .form form input.btn {
    font-size: 1.8vw;
    margin: 0.8vw auto;
  }
}
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .contacto .mapa { 
    height: 50vw;
  }
  .contacto .form .section span.a {
    font-size: 4vw;
  }
  .contacto .form form {
    width: 80%;
  }
  .contacto .form form .label span {
    font-size: 1.8vw;
  }
  .contacto .form form input {
    font-size: 1.6vw;
    margin-bottom: 1vw;
  }
  .contacto .form form textarea {
    font-size: 1.6vw;
    margin-bottom: 1vw; 
  }
  .contacto .form form input.btn {
    font-size: 1.8vw;
    margin: 0.8vw auto;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .contacto .form .section span.a {
    font-size: 4vw;
  }
  .contacto .form form {
    width: 80%;
  }
  .contacto .form form .label span {
    font-size: 1.8vw;
  }
  .contacto .form form input {
    font-size: 1.6vw;
    margin-bottom: 1vw;
  }
  .contacto .form form textarea {
    font-size: 1.6vw;
    margin-bottom: 1vw; 
  }
  .contacto .form form input.btn {
    font-size: 1.8vw;
    margin: 0.8vw auto;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1199px) {
  .contacto .form .section span.a {
    font-size: 4vw;
  }
  .contacto .form form {
    width: 80%;
  }
  .contacto .form form .label span {
    font-size: 1.8vw;
  }
  .contacto .form form input {
    font-size: 1.6vw;
    margin-bottom: 1vw;
  }
  .contacto .form form textarea {
    font-size: 1.6vw;
    margin-bottom: 1vw; 
  }
  .contacto .form form input.btn {
    font-size: 1.8vw;
    margin: 0.8vw auto;
  }
}


</style>


