<template>
  <div class="transporte full-width row" v-scrollanimation>
    <div class="fondo-transporte" v-scrollanimation></div>
    <div class="line"></div>


     <div class="cont-transporte flex-row-center full-height">
      
      <div class="middle transporte-text flex-column-center align-column-center" v-scrollanimation>

        <div class="actions flex-row-center">
          <div v-for="act in actions" :key="act.key" class="action flex-column-center" @click="selectSlider(act.key)" :class="getStyleActions(act.key)">
            <span class="title_section">{{ act.titulo }}</span>
            <div class="line-actions"></div>
          </div>
        </div>


        <div class="slider">
          <div :key="s.key" v-for="s in slide.slides.items" :class="getClassSlideItem(s.index)">
            <div
              :key="'item_' + pos"
              v-for="(i, pos) in s.item"
              :class="getClassItem()"
              class="flex-row-center align-row-center"
              v-show="i !== null"
            >
              <div class="middle flex-column-center a">
                <div class="texto flex-row-center" v-for="(txt, index) in i.a" :key="index" >
                  <div class="circle" v-if="txt !== ''">
                    <span></span>
                  </div>
                  <span class="content">{{txt}}</span>
                </div>
              </div>
              <div class="middle flex-column-center b">
                <div class="texto flex-row-center" v-for="(txt, index) in i.b" :key="index" >
                  <div class="circle" v-if="txt !== ''">
                    <span></span>
                  </div>
                  <span class="content">{{txt}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="buttons">
          <div
            :key="btn.key"
            v-for="btn in slide.slides.buttons"
            :class="getClassButton(btn.index)"
            @click="changeActiveSection(btn.index)"
          ></div>
        </div>
      </div>
      <div class="middle transporte-img flex-row-center align-row-center" v-scrollanimation>
        <div class="box" v-scrollanimation>
          <div class="cont-img" v-scrollanimation>
            <img src="../../../assets/img/soluciones/icono-transporte.png" alt="" />
          </div>
          <span class="titulo title_section" v-scrollanimation>Transporte de datos</span>
          <span class="content" v-scrollanimation>Ofrecemos servicios de transporte transparente en capa 2 y capa 3, sobre circuitos físico o virtuales. Tenemos diferentes tecnologías que permiten seleccionar la opción más adecuada para resolver las necesidades de conectividad, desde y hacia los sitios requeridos por nuestros clientes. </span>
        </div>
      </div>
    </div>


  </div>
</template>

<style>
.transporte {
  position: relative;
  height: 60vw;
  border-bottom: solid 0.1px;
}

.transporte .fondo-transporte {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -2;
  background-image: url("../../../assets/img/soluciones/fondo-transporte.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.transporte .line {
  height: 0.5vw;
  width: 100%;
  background: #f0f0f0;
  position: absolute;
  bottom: 13vw;
  left: 0;
  z-index: -2;
}
.transporte .cont-transporte .transporte-img {
  width: 40%;
  position: relative;
  align-items: flex-start;
}

.transporte .cont-transporte .transporte-img .box {
  width: calc(85% - 1.6vw);
  margin: 0 0.8vw;
  height: 60%;
  border-radius: 1vw;
  background: #17d0ff;
  position: absolute;
  top: 8vw;
}

.transporte .cont-transporte .transporte-img .box .cont-img {
  width: 10vw;
  height: 10vw;
  display: block;
  margin: -5vw auto 2vw auto;
}

.transporte .cont-transporte .transporte-img .box .cont-img img { 
  width: 100%;
  height: 100%;
}

.transporte .cont-transporte .transporte-img .box span {
  display: block;
  width: calc(100% - 3vw);
  padding: 0 1.5vw;
}

.transporte .cont-transporte .transporte-img .box .titulo {
  text-transform: uppercase;
  margin-bottom: 2vw;
  color: #1A4C94;
  text-align: center;
  font-size: 1.4vw;
}


.transporte .cont-transporte .transporte-img .box .content {
  height: calc(100% - 12vw);
  overflow: scroll;
  width: calc(100% - 6vw);
  padding: 0 3vw;
  text-align: justify;
  line-height: 1.3vw;
  color: #333333;
}

.transporte .cont-transporte .transporte-text {
  width: 60%;
  position: relative;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}

.transporte .cont-transporte .transporte-text .actions { 
  width: 90%;
  justify-content: flex-start;
  align-items: center;
  height: 2.5vw;
  margin: 0 auto;
}

.transporte .cont-transporte .transporte-text .actions .action {
  height: 100%;
  width: auto;
  padding: 0 2.5vw 0 0;
  transform: scale(0.94);
  transition: all 0.1s 0.1s ease-in;
}

.transporte .cont-transporte .transporte-text .actions .action.active {
  transform: scale(1.1);
  transition: all 0.1s 0.1s ease-in-out;
}

.transporte .cont-transporte .transporte-text .actions .action:hover { 
  cursor: pointer;
}

.transporte .cont-transporte .transporte-text .actions .action span { 
  font-size: 1.4vw;
  text-transform: uppercase;
  color: #1A4C94;
}

.transporte .cont-transporte .transporte-text .actions .action .line-actions { 
  background: #1A4C94;
  height: 0.3vw;
  border-radius: 6px;
  display: block;
  width: 60%;
  float: left;
}

.transporte .cont-transporte .transporte-text .slider { 
  position: relative;
  width: 90%;
  height: calc(38vw - 12vw);
  margin: 0 auto;
}

.transporte .cont-transporte .transporte-text .slider .slide {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row wrap;

  position: absolute;
  z-index: -1;
  opacity: 0;
  transition: all 0.3s 0.3s ease;
}

.transporte .cont-transporte .transporte-text .slider .slide.active {
  z-index: 1;
  opacity: 1;
  transition: all 0.3s 0.3s ease;
}

.transporte .cont-transporte .transporte-text .slider .slide .texto {
  height: auto;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 2vw;
}

.transporte .cont-transporte .transporte-text .slider .slide .middle.a {
  width: calc(50% - 1.5vw);
  height: calc(100% - 4vw);
  padding-right: 1.5vw;
  padding-top: 4vw;
  justify-content: flex-start;
}

.transporte .cont-transporte .transporte-text .slider .slide .middle.b {
  width: calc(50% - 1.5vw);
  height: calc(100% - 4vw);
  padding-right: 1.5vw;
  padding-top: 4vw;
  justify-content: flex-start;
}

.transporte .cont-transporte .transporte-text .slider .slide .texto .circle {
  width: calc(100% - 90%);
  height: calc(100% - 0.5vw);
  padding-top: 0.5vw;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: flex-start;
}

.transporte .cont-transporte .transporte-text .slider .slide .texto .circle span {
  background: #1A4C94;
  width: 0.6vw;
  height: 0.6vw;
  border-radius: 50%;
}

.transporte .cont-transporte .transporte-text .slider .slide .texto .content {
  height: 100%;
  width: calc(100% - 10%);
  font-size: 1vw;
  text-align: justify;
  color: #1A4C94;
}

.transporte .cont-transporte .transporte-text .buttons {
  width: 90%;
  height: 12vw;
  margin: 0 auto;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
}
.transporte .cont-transporte .transporte-text .buttons .button {
  background: #1A4C94;
  width: 0.9vw;
  height: 0.9vw;
  border-radius: 50%;
  margin: 0 0.2vw;
  cursor: pointer;
  transition: all 0.3s 0.3s ease;
}
.transporte .cont-transporte .transporte-text .buttons .button:active {
  transform: scale(0.98);
}
.transporte .cont-transporte .transporte-text .buttons .button.active {
  background: #1A4C94;
  width: 1.5vw;
  height: 1.5vw;
  transition: all 0.3s 0.3s ease;
}
@media only screen and (max-width: 320px) {

  .transporte {
    height: 100vw;
  }

  .transporte .cont-transporte {
    flex-flow: column;
  }

  .transporte .cont-transporte .transporte-img {
    width: 100%;
    height: 42%;
    position: absolute;
    left: 0;
    top: 0;

  }
  .transporte .cont-transporte .transporte-img .box {
    width: 80%;
    height: 66%;
    margin: 0;
  }

  .transporte .cont-transporte .transporte-img .box .titulo {
    font-size: 2.4vw;
  }
  .transporte .cont-transporte .transporte-img .box .content {
    font-size: 2.2vw;
    line-height: 2.5vw;
    font-family: montserrat_regular;
  }

  .transporte .cont-transporte .transporte-text {
    width: 100%;
    height: 45%;
    position: absolute;
    left: 0;
    top: 40vw;
  }
  .transporte .cont-transporte .transporte-text .actions {
    height: 8.5vw;
    width: 75%;
  }

  .transporte .cont-transporte .transporte-text .actions .action span {
    font-size: 2vw;
  }

  .transporte .cont-transporte .transporte-text .slider {
    height: calc(80vw - 12vw);
    width: 80%;
  }

  .transporte .cont-transporte .transporte-text .slider .slide .texto .content {
    font-size: 1.9vw;
    font-family: montserrat_regular;
  }
  .transporte .cont-transporte .transporte-text .buttons {
    display: none;
  }
  
}
@media only screen and (min-width: 321px) and (max-width: 480px) {
  .transporte {
    height: 100vw;
  }

  .transporte .cont-transporte {
    flex-flow: column;
  }

  .transporte .cont-transporte .transporte-img {
    width: 100%;
    height: 42%;
    position: absolute;
    left: 0;
    top: 0;

  }
  .transporte .cont-transporte .transporte-img .box {
    width: 80%;
    height: 66%;
    margin: 0;
  }

  .transporte .cont-transporte .transporte-img .box .titulo {
    font-size: 2.4vw;
  }
  .transporte .cont-transporte .transporte-img .box .content {
    font-size: 2.2vw;
    line-height: 2.5vw;
    font-family: montserrat_regular;
  }

  .transporte .cont-transporte .transporte-text {
    width: 100%;
    height: 45%;
    position: absolute;
    left: 0;
    top: 40vw;
  }
  .transporte .cont-transporte .transporte-text .actions {
    height: 8.5vw;
    width: 75%;
  }

  .transporte .cont-transporte .transporte-text .actions .action span {
    font-size: 2vw;
  }

  .transporte .cont-transporte .transporte-text .slider {
    height: calc(80vw - 12vw);
    width: 80%;
  }

  .transporte .cont-transporte .transporte-text .slider .slide .texto .content {
    font-size: 1.9vw;
    font-family: montserrat_regular;
  }
  .transporte .cont-transporte .transporte-text .buttons {
    display: none;
  }
  
}
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .transporte {
    height: 100vw;
  }

  .transporte .cont-transporte {
    flex-flow: column;
  }

  .transporte .cont-transporte .transporte-img {
    width: 100%;
    height: 42%;
    position: absolute;
    left: 0;
    top: 0;

  }
  .transporte .cont-transporte .transporte-img .box {
    width: 80%;
    height: 66%;
    margin: 0;
  }

  .transporte .cont-transporte .transporte-img .box .titulo {
    font-size: 2.4vw;
  }
  .transporte .cont-transporte .transporte-img .box .content {
    font-size: 1.8vw;
    line-height: 2.5vw;
    font-family: montserrat_regular;
  }

  .transporte .cont-transporte .transporte-text {
    width: 100%;
    height: 45%;
    position: absolute;
    left: 0;
    top: 40vw;
  }
  .transporte .cont-transporte .transporte-text .actions {
    height: 8.5vw;
    width: 75%;
  }

  .transporte .cont-transporte .transporte-text .actions .action span {
    font-size: 2vw;
  }

  .transporte .cont-transporte .transporte-text .slider {
    height: calc(80vw - 12vw);
    width: 80%;
  }

  .transporte .cont-transporte .transporte-text .slider .slide .texto .content {
    font-size: 1.9vw;
    font-family: montserrat_regular;
  }
  .transporte .cont-transporte .transporte-text .buttons {
    display: none;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  
}
@media only screen and (min-width: 1025px) and (max-width: 1199px) {
  
}
</style>

<script>
  export default {
    data: function () {
      return {
        actions: [
          {
            key: 0,
            titulo: "Soluciones"
          },
          {
            key: 1,
            titulo: "Beneficios"
          },
          {
            key: 2,
            titulo: "Características"
          }
        ],  
        slide_active: 0,
        slide: {
          size: 0,
          slides: {
            active: 0,
            buttons: [],
            items: []
          }
        },
        
        soluciones: [
          {
            a:[
              "Transporte en Capa 2 y 3, conexión nacional e internacional entre Sedes, en interfaces de 1G Eth o 10G Eth",
              "Lambdas, conectividad óptica a través de la red de transmisión OTN, en interfaces de 10G, 100G y 200G",
            ],
            b: [
              "Espectro Óptico, Asignación de una porción del espectro de la fibra para 100G o 200G",
              ""
            ]
          }
        ],
        beneficios: [
          {
            a:[
              "Restauración automática de servicios",
              "Protección predefinida de servicios 1+1 con tiempos de conmutación menores a 50 ms",
              "Protección automática de servicios 1+n con tiempos de conmutación menores a 50 ms"
            ],
            b: [
              "Múltiples caminos a nivel de fibra",
              "Red cuenta con ROADM lo que permite re-direccionar las Longitudes de Onda acorde a demanda",
              ""
            ]
          }
        ],
        caracteristicas: [
          {
            a:[
              "Lambdas, conectividad óptica a través de la red de transmisión OTN, en interfaces de 10G, 100G y 200G",
              "Servicio de transporte Transparente.",
              "Matriz de Cross-conexión de alta capacidad.",
              "Capacidad inicial instalada en puertos de servicios 140 ptos x 10G (1,4 Tbps Para servicios protegidos)."
            ],
            b: [
              "Cobertura en 20 ciudades a nivel Nacional.",
              "SLA 99,95%",
              "Gestión y monitoreo de la red 24x7x365 a través de nuestro NOC con personal propio"
            ]
          }
        ]
      };
    },
    beforeMount: function() {
      this.loadItems(this.soluciones);
    },
    methods: {

      cleanSlide: function() {
        this.slide = {
          size: 0,
          slides: {
            active: 0,
            buttons: [],
            items: []
          }
        }
      },

      selectSlider: function(index) {
        this.slide_active = index;
        let info = [];
        if(index === 0) {
          this.cleanSlide();
          info = this.soluciones;
          this.loadItems(info);
        } else if(index === 1) {
          this.cleanSlide();
          info = this.beneficios;
          this.loadItems(info);
        } else if(index === 2){
          this.cleanSlide();
          info = this.caracteristicas;
          this.loadItems(info);
        }
      },

      getStyleActions: function(index) {
        return {
          active: this.slide_active === index
        }
      },

      getClassSlideItem: function(index) {
        return {
          slide: true,
          active: this.slide.slides.active === index
        };
      },
      getClassItem: function() {
        return {
          item: true
        };
      },
      getClassButton: function(index) {
        return {
          shadow: true,
          button: true,
          active: this.slide.slides.active === index
        };
      },
      changeActiveSection: function(index) {
        this.slide.slides.active = index;
      },
      loadItems:  function(info) {
        this.slide.size = info.length;
        let indices = 0;
        for (let i = 0; i < this.slide.size; i += 1) {
          let aux = {
            key: "slide_" + i,
            index: indices,
            item: []
          };
          for (let j = 0; j < 1; j++) {
            aux.item.push(
              i + j < this.slide.size ? info[i + j] : null
            );
          }
          this.slide.slides.items.push(aux);
          indices += 1;
        }
        for (let j = 0; j < this.slide.slides.items.length; j++) {
          this.slide.slides.buttons.push({
            key: "button_" + j,
            index: j
          });
        }
      }
    }
  }
</script>