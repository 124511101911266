<template>
  <div class="solucionesst row flex-column-center align-column-center">

    <div class="section"  v-scrollanimation>
      <span class="title_section a">Soluciones de Nedetel</span>
      <span class="subtitle b">Nedetel posee más de 5000 km de fibra interurbana, desplegada en todo el país, Tenemos más de 100 PoPs en todo el Ecuador Contamos con 5 Centros de datos en las ciudades de Quito, Guayaquil, Santo Domingo, Manta y Cuenca. Utilizamos las últimas tecnologías de Dense Wave Division Multiplexing (DWDM), para transportar lambdas de 10Gbps, 100Gbps y 200Gbps.</span>
    </div>

    <div class="cont-sol flex-row-center align-center" v-scrollanimation>
      <div class="fondo" v-scrollanimation></div>


      <div v-for="sl in soluciones" :key="sl.key" class="box shadow">
        <div class="cont-img">
          <img :src="sl.img" alt="" />
        </div>
        <span class="titulo title_section">{{ sl.titulo }}</span>

        <span class="content">{{ sl.content }}</span>

        <div class="button flex-row-center align-row-centert shadow" @click="goSection(sl.key)">
          <span>Consultar</span>
          <font-awesome-icon :icon="['fas', 'long-arrow-alt-right']" class="icon" />
        </div>
      </div>
    </div>
    
  </div>
</template>


<script>

import conectividad from "../../assets/img/home/conectividad.png";
import infraestructura from "../../assets/img/home/infraestructura.png";
import seguridad from "../../assets/img/home/seguridad.png";
import nube from "../../assets/img/home/nube.png";

export default {
  data: function(){ 
    return {
      soluciones: [
        {
          key: 0,
          titulo: "Conectividad",
          content: "Te presentamos una propuesta innovadora de conectividad, al ser el único Carrier del país con dos redes de transporte totalmente independientes en estaciones y rutas de fibra; lo que nos permite ofrecer el mejor SLA.",
          img: conectividad
        },
        {
          key: 1,
          titulo: "Infraestructura",
          content: "En nuestras soluciones de Infraestructura, te ofrecemos Independencia, Neutralidad y Confiabilidad. Debido a que, instalamos, gestionamos y operamos nuestra propia red de fibra óptica, nuestras redes de transporte, nuestros puntos de presencia (PoPs) y Datacenters.",
          img: infraestructura
        },
        {
          key: 2,
          titulo: "Seguridad",
          content: "Mediante alianzas estratégicas con partners expertos en soluciones de ciberseguridad, Diseñamos e implementamos sistemas de seguridad perimetral de siguiente generación, protección avanzada frente a DDoS y soluciones endpoint.",
          img: seguridad
        },
        {
          key: 3,
          titulo: "Nube",
          content: "Con nuestra plataforma de Hiperconvergencia, satisfacemos las necesidades informáticas de nuestros clientes y en nuestra nube privada te ofrecemos Xaas (Todo como Servicio).",
          img: nube
        }
      ]
    };
  },
  methods: {
    goSection: function(index){ 
      let path = this.$route.path;
      if (index === 0) {
        this.$router.push({ path: "/soluciones", query: { section: index } });
      } else if (index === 1) {
        this.$router.push({ path: "/soluciones", query: { section: index } });
      } else if (index === 2) {
        this.$router.push({ path: "/soluciones", query: { section: index } });
      } else if (index === 3) {
        this.$router.push({ path: "/soluciones", query: { section: index } });
      } 
    }
  }
};
</script>

<style>
.solucionesst {
  height: 60vw;
}

.solucionesst .section {
  width: 100%;
  text-align: center;
  color: #1A4C94;
  padding-top: 8vw;
  transition-delay: .1s;
}

.solucionesst .section span.a {
  display: block;
  font-size: 2.4vw;
  text-transform: uppercase;
  transition-delay: .2s;
  margin-bottom: 1.4vw;
}

.solucionesst .section span.b {
  display: block;
  font-size: 1.2vw;
  transition-delay: .2s;
}

.solucionesst .cont-sol {
  width: calc(100% - 8vw);
  height: 100%;
  position: relative;
  padding: 0 4vw;
  transition-delay: .1s;
}

.solucionesst .cont-sol .fondo {
  position: absolute;
  background: #E2E2E2;
  top: calc(50% - 7vw);
  left: 0;
  height: 14vw;
  width: 100%;
  z-index: -2;
  transition-delay: .1s;
}

.solucionesst .cont-sol .box {
  width: calc(25% - 1.6vw);
  margin: 0 0.8vw;
  height: 64%;
  border-radius: 0.5vw;
  background: #fff;
  transition-delay: .1s;
}

.solucionesst .cont-sol .box.first {
  width: calc(25% - 0.8vw);
  margin: 0 0.8vw 0 0;
  transition-delay: .2s;
}

.solucionesst .cont-sol .box.second {
  transition-delay: .4s;
}

.solucionesst .cont-sol .box.third {
  transition-delay: .6s;
}

.solucionesst .cont-sol .box.last {
  width: calc(25% - 0.8vw);
  margin: 0 0 0 0.8vw;
  transition-delay: .8s;
}

.solucionesst .cont-sol .box .cont-img {
  width: 10vw;
  height: 10vw;
  display: block;
  margin: -5vw auto 2vw auto;
}

.solucionesst .cont-sol .box .cont-img img {
  width: 100%;
  height: 100%;
}

.solucionesst .cont-sol .box span {
  display: block;
  width: calc(100% - 3vw);
  padding: 0 1.5vw;
}

.solucionesst .cont-sol .box .titulo {
  text-transform: uppercase;
  color: #333333;
  margin-bottom: 1vw;
}

.solucionesst .cont-sol .box .content {
  height: 14vw;
  overflow: scroll;
  margin-bottom: 1vw;
}

.solucionesst .cont-sol .box .button {
  width: calc(100% - 4vw);
  margin: 0 1.5vw;
  padding: 0.5vw 0 0.5vw 1vw;
  border-radius: 0.8vw;
  background: #fff;
}

.solucionesst .cont-sol .box .button:hover {
  cursor: pointer;

}

.solucionesst .cont-sol .box .button span {
  padding: 0;
  margin: 0;
}

@media only screen and (max-width: 320px) {
  .solucionesst {
    height: auto;
  }

  .solucionesst .section span.a {
    font-size: 4vw;
  }

  .solucionesst .section span.b {
    display: block;
    font-size: 2.1vw;
    line-height: 2.8vw;
    transition-delay: .2s;
  }

  .solucionesst .cont-sol {
    width: calc(100% - 4vw);
    margin-top: 10vw;
    padding: 0 2vw;
    flex-flow: column;
    height: auto;
  }
  .solucionesst .cont-sol .fondo {
    display: none;
  }
  .solucionesst .cont-sol .box {
    height: auto;
    width: calc(80% - 3vw);
    padding: 0 1.5vw;
    margin-bottom: 8vw;
  }
  .solucionesst .cont-sol .box .titulo {
    font-size: 2.4vw;
    width: 100%;
    text-align: center;
    margin: 0 auto 1vw auto;
    padding: 0;
  }
  .solucionesst .cont-sol .box .content {
    height: auto;
    margin-bottom: 2vw;
    font-size: 2.1vw;
    text-align: justify;
  }
  .solucionesst .cont-sol .box .button {
    margin-bottom: 2vw;
    width: auto;
    float: right;
    padding: 0.5vw 0;
    justify-content: center;
    align-content: center;
  }
  .solucionesst .cont-sol .box .button span {
    font-size: 2vw;
    margin: 0 1vw 0 1vw;
    padding: 0;
    width: auto;
  }
  .solucionesst .cont-sol .box .button .icon {
    font-size: 1.3vw;
    margin-right: 1vw;
  }
}
@media only screen and (min-width: 321px) and (max-width: 480px) {
  .solucionesst {
    height: auto;
  }
  .solucionesst .section span.a {
    font-size: 4vw;
  }
  .solucionesst .section span.b {
    display: block;
    font-size: 2.1vw;
    line-height: 2.8vw;
    transition-delay: .2s;
  }
  .solucionesst .cont-sol {
    width: calc(100% - 4vw);
    margin-top: 10vw;
    padding: 0 2vw;
    flex-flow: column;
    height: auto;
  }
  .solucionesst .cont-sol .fondo {
    display: none;
  }
  .solucionesst .cont-sol .box {
    height: auto;
    width: calc(80% - 3vw);
    padding: 0 1.5vw;
    margin-bottom: 8vw;
  }
  .solucionesst .cont-sol .box .titulo {
    font-size: 2.4vw;
    width: 100%;
    text-align: center;
    margin: 0 auto 1vw auto;
    padding: 0;
  }
  .solucionesst .cont-sol .box .content {
    height: auto;
    margin-bottom: 2vw;
    font-size: 2.1vw;
    text-align: justify;
  }
  .solucionesst .cont-sol .box .button {
    margin-bottom: 2vw;
    width: auto;
    float: right;
    padding: 0.5vw 0;
    justify-content: center;
    align-content: center;
  }
  .solucionesst .cont-sol .box .button span {
    font-size: 1.7vw;
    margin: 0 1vw 0 1vw;
    padding: 0;
    width: auto;
  }
  .solucionesst .cont-sol .box .button .icon {
    font-size: 1.3vw;
    margin-right: 1vw;
  }
} 
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .solucionesst {
    height: auto;
  }
  .solucionesst .section span.a {

  }
  .solucionesst .section span.b {
    display: block;
    font-size: 2.1vw;
    line-height: 2.8vw;
    transition-delay: .2s;
  }
  .solucionesst .cont-sol {
    width: calc(100% - 4vw);
    margin-top: 10vw;
    padding: 0 2vw;
    flex-flow: column;
    height: auto;
  }
  .solucionesst .cont-sol .fondo {
    display: none;
  }
  .solucionesst .cont-sol .box {
    height: auto;
    width: calc(80% - 3vw);
    padding: 0 1.5vw;
    margin-bottom: 8vw;
  }
  .solucionesst .cont-sol .box .titulo {
    font-size: 2.4vw;
    width: 100%;
    text-align: center;
    margin: 0 auto 1vw auto;
    padding: 0;
  }
  .solucionesst .cont-sol .box .content {
    height: auto;
    margin-bottom: 2vw;
    font-size: 2.1vw;
    text-align: justify;
  }
  .solucionesst .cont-sol .box .button {
    margin-bottom: 2vw;
    width: auto;
    float: right;
    padding: 0.5vw 0;
    justify-content: center;
    align-content: center;
  }
  .solucionesst .cont-sol .box .button span {
    font-size: 1.7vw;
    margin: 0 1vw 0 1vw;
    padding: 0;
    width: auto;
  }
  .solucionesst .cont-sol .box .button .icon {
    font-size: 1.8vw;
    margin-right: 1vw;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .solucionesst {
    height: 70vw;
  }
  .solucionesst .section span.a {

  }
  .solucionesst .section span.b {
    display: block;
    font-size: 1.85vw;
    line-height: 2.8vw;
    transition-delay: .2s;
  }
  .solucionesst .cont-sol {
    width: calc(100% - 4vw);
    margin-top: 2vw;
    padding: 0 2vw;
  }
  .solucionesst .cont-sol .box {
    height: 73%;
    width: calc(80% - 3vw);
    padding: 0 1.5vw;
  }
  .solucionesst .cont-sol .box .titulo {
    font-size: 1.4vw;
  }
  .solucionesst .cont-sol .box .content {
    height: 18vw;
    font-size: 1.1vw;
  }
  .solucionesst .cont-sol .box .button span {
    font-size: 1vw;
    margin: 0 1vw 0 1vw;
  }
  .solucionesst .cont-sol .box .button .icon {
    font-size: 1.3vw;
    margin-right: 1vw;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1199px) {
  .solucionesst {
    height: 70vw;
  }
  .solucionesst .section span.a {

  }
  .solucionesst .section span.b {
    display: block;
    font-size: 1.6vw;
    line-height: 2.4vw;
    transition-delay: .2s;
  }
  .solucionesst .cont-sol {
    width: calc(100% - 4vw);
    margin-top: 2vw;
    padding: 0 2vw;
  }
  .solucionesst .cont-sol .fondo {
    display: none;
  }
  .solucionesst .cont-sol .box {
    height: 68%;
  }
  .solucionesst .cont-sol .box .titulo {
    font-size: 1.6vw;
  }
  .solucionesst .cont-sol .box .content {
    height: 17vw;
    font-size: 1vw;
  }
  .solucionesst .cont-sol .box .button span {
    font-size: 1.25vw;
  }
  .solucionesst .cont-sol .box .button .icon {
    font-size: 1.3vw;
  }
}

</style>