<template>
  <div class="seguridad full-width full-height">
    <Intro></Intro>
  </div>
</template>


<script>
import Intro from "../seguridad/Intro.vue";
export default {
  components: {
    Intro
  }
}

</script>

