<template>
  <div class="nube full-width full-height">
   <Intro></Intro>
  </div>
</template>


<script>
import Intro from "../nube/Intro.vue";
export default {
  components: {
    Intro
  }
}

</script>


