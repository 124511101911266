export default {
  config: {
    apiKey: "AIzaSyB0j9uWbKXxdIUr2XWNPWr_kcNYWBJPNLs",
    authDomain: "nedetel-43cc9.firebaseapp.com",
    databaseURL: "https://nedetel-43cc9.firebaseio.com",
    projectId: "nedetel-43cc9",
    storageBucket: "nedetel-43cc9.appspot.com",
    messagingSenderId: "361756198738",
    appId: "1:361756198738:web:e07745456ced12b1d31c8b",
    measurementId: "G-2WY9MPLQR9"
  }
};
