import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Nosotros from "../views/Nosotros.vue";
import Soluciones from "../views/Soluciones.vue";


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    props(route) {
      if (route.query) {
        route.query.section = parseInt(route.query.section);
        return route.query;
      } else {
        return {};
      }
    }
  },
  {
    path: "/nosotros",
    name: "Nosotros",
    component: Nosotros,
    props(route) {
      if (route.query) {
        route.query.section = parseInt(route.query.section);
        return route.query;
      } else {
        return {};
      }
    }
  },
  {
    path: "/soluciones",
    name: "Soluciones",
    component: Soluciones,
    props(route) {
      if (route.query) {
        route.query.section = parseInt(route.query.section);
        return route.query;
      } else {
        return {};
      }
    }
  }

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
