<template>
  <div
    :class="active ? 'acordeon active' : 'acordeon'"
    @click="$emit('openSection', index)"
    v-scrollanimation
  >
    <div class="title_section title">{{section.titulo}}</div>
    <div class="content">
      <div v-show="index === 0" class="x" v-scrollanimation>
        <div class="cont-info-content flex-row-center">
          <div class="item flex-row-center" v-for="(item, i) in section.content" :key="i">
            <div class="sprt">
              <span></span>
            </div>
            <span class="subtitle">{{ item }}</span>
          </div>
        </div>
      </div>
      <div v-show="index === 1" class="x" v-scrollanimation>
        <div class="cont-info-content flex-row-center">
          <div class="item flex-row-center" v-for="(item, i) in section.content" :key="i">
            <div class="sprt">
              <span></span>
            </div>
            <span class="subtitle">{{ item }}</span>
          </div>
        </div>
      </div>
        
      <div v-show="index === 2" class="x" v-scrollanimation>
        <div class="cont-info-content flex-row-center">
          <div class="item flex-row-center" v-for="(item, i) in section.content" :key="i">
            <div class="sprt">
              <span></span>
            </div>
            <span class="subtitle">{{ item }}</span>
          </div>
        </div>
      </div>
    </div>
    <font-awesome-icon
      v-if="active === false"
      :icon="['fas', 'chevron-down']"
      class="icon"
    />
  </div>
</template>

<style>
.acordeon {
  padding: 1vw 2vw;
  margin-bottom: 1vw;
  display: flex;
  flex-flow: column;
  justify-content: center;
  width: calc(100% - 4vw);
  height: calc(100% - 2vw);
  position: relative;
  border-top: solid 0.1vw #1A4C94;
  border-bottom: solid 0.1vw #1A4C94;
}

.acordeon:hover {
  cursor: pointer;
}

.acordeon.active .title {
  margin-bottom: 1vw;
  transition: all 0.4s ease-in-out;
}

.acordeon.active .title::after {
  transform: translateY(-50%) rotate(180deg);
  transition: all 0.4s linear;
}

.acordeon .icon {
  position: absolute;
  right: 2vw;
  color: #1A4C94;
  transition: all 0.2s 0.2s ease;
}

.acordeon .title {
  font-size: 1.6vw;
  color: #1A4C94;
  display: block;
  text-transform: uppercase;
  transition: all 0.2s 0.2s ease-in-out;
}

.acordeon .title::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0px;
  translate: translateY(-50%) rotate(0deg);
  transition: all 0.2s 0.2s linear;
}

.acordeon .content {
  font-size: 1.2vw;
  line-height: 1.1vw;
  opacity: 0;
  max-height: 0px;
  overflow: hidden;
  transition: all 0.2s 0.2s ease;
  color: #606060;
}

.acordeon.active .content {
  opacity: 1;
  max-height: 1000px;
  overflow: initial;
}

.acordeon.active .content .x {
  height: 2vw;
}

.acordeon .content .cont-info-content {
  width: 100%;
  height: 100%;
}

.acordeon .content .sprt {
  width: 2vw;
  height: 1vw;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}

.acordeon .content .sprt span {
  width: 0.2vw;
  height: 0.2vw;
  background: #606060;
  border-radius: 50%;
  border: solid 0.1vw #606060;
}


@media only screen and (max-width: 320px) {
  .acordeon .title {
    font-size: 1.8vw;
  }
  .acordeon .content .cont-info-content {
    font-size: 1.8vw;
  }
}
@media only screen and (min-width: 321px) and (max-width: 480px) {
  .acordeon .title {
    font-size: 1.8vw;
  }
  .acordeon .content .cont-info-content {
    font-size: 1.8vw;
  }
}
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .acordeon .title {
    font-size: 1.8vw;
  }
  .acordeon .content .cont-info-content {
    font-size: 1.8vw;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
 .acordeon .title {
  }
  .acordeon .content .cont-info-content {
    font-size: 1.8vw;
  } 
}
@media only screen and (min-width: 1025px) and (max-width: 1199px) {
  .acordeon .title { 
  }
  .acordeon .content .cont-info-content {
    font-size: 1.8vw;
  }
}
</style>

<script>
export default {
  name: "Acordeon",
  props: ["section", "index", "active"]
};
</script>