<template>
  <div class="indicador one">
    <div class="months flex-row-center align-row-center shadow">
      <span
        v-for="(m, i) in months"
        :key="i"
        @click="selectMonth(i)"
        class="month flex-row-center align-row-center"
        :class="getStyleMonth(i)"
      >{{ m }}</span>
    </div>

    <bar-chart v-if="datos !== null" suffix="%" :colors="['#1a4c94']" :data="datos" class="grafico"></bar-chart>
  </div>
</template>

<style>
.indicador.one {
  width: 100%;
  height: 100%;
}

.indicador.one .grafico {
  height: 45vw !important;
  width: 85% !important;
  margin: 0 auto !important;
  display: block !important;
}

.indicador.one .months {
  background: #fff;
  height: 4vw;
  margin: 1vw auto 3vw auto;
  width: 100%;
}

.indicador.one .months .month {
  background: #fff;
  color: #1a4c94;
  padding: 0 1vw;
  height: 100%;
  width: calc(100% / 12);
  transition: all 0.1s 0.1s ease-in;
}

.indicador.one .months .month:hover {
  cursor: pointer;
}

.indicador.one .months .month.active {
  background: #1a4c94;
  color: #fff;
  transition: all 0.1s 0.1s ease-in-out;
}
</style>

<script>
export default {
  data: function() {
    return {
      data: [
        {
          num: [
            ["Azuay", 7.02],
            ["Bolívar", 0.14],
            ["Cañar", 3.44],
            ["Carchi", 1.16],
            ["Chimborazo", 1.92],
            ["Cotopaxi", 3.21],
            ["El Oro", 11.03],
            ["Esmeraldas", 3.67],
            ["Guayas", 17.43],
            ["Imbabura", 1.52],
            ["Loja", 3.38],
            ["Los Ríos", 8.89],
            ["Manabí", 9.38],
            ["Morona Santiago", 1.68],
            ["Napo", 0.41],
            ["Orellana", 1.23],
            ["Pastaza", 0.06],
            ["Pichincha", 8.49],
            ["Santa Elena", 2.95],
            ["Sto. Dgo. Tsáchilas", 5.77],
            ["Sucumbíos", 1.38],
            ["Tungurahua", 4.43],
            ["Zamora Chinchipe", 1.42]
          ]
        },
        {
          num: [
            ["Azuay", 6.83],
            ["Bolívar", 0.18],
            ["Cañar", 3.39],
            ["Carchi", 1.07],
            ["Chimborazo", 1.80],
            ["Cotopaxi", 3.21],
            ["El Oro", 11.20],
            ["Esmeraldas", 3.55],
            ["Guayas", 16.87],
            ["Ibarra", 0.05],
            ["Imbabura", 2.04],
            ["Loja", 3.41],
            ["Los Ríos", 9.17],
            ["Manabí", 9.20],
            ["Morona Santiago", 1.63],
            ["Napo", 0.40],
            ["Orellana", 1.20],
            ["Pastaza", 0.09],
            ["Pichincha", 8.46],
            ["Santa Elena", 2.84],
            ["Sto. Dgo. Tsáchilas", 5.63],
            ["Sucumbíos", 1.80],
            ["Tungurahua", 4.65],
            ["Zamora Chinchipe", 1.33]
          ]
        },
        {
          num: [
            ["Azuay", 7.11],
            ["Bolívar", 0.18],
            ["Cañar", 2.56],
            ["Carchi", 1.00],
            ["Chimborazo", 1.70],
            ["Cotopaxi", 3.11],
            ["El Oro", 11.67],
            ["Esmeraldas", 3.39],
            ["Guayas", 17.35],
            ["Ibarra", 0.05],
            ["Imbabura", 2.19],
            ["Loja", 3.18],
            ["Los Ríos", 9.20],
            ["Manabí", 9.42],
            ["Morona Santiago", 1.68],
            ["Napo", 0.38],
            ["Orellana", 1.13],
            ["Pastaza", 0.11],
            ["Pichincha", 8.48],
            ["Santa Elena", 2.76],
            ["Sto. Dgo. Tsáchilas", 5.49],
            ["Sucumbíos", 1.67],
            ["Tungurahua", 4.73],
            ["Zamora Chinchipe", 1.47]
          ]
        },
        {
          num: [
            ["Azuay", 6.83],
            ["Bolívar", 0.17],
            ["Cañar", 1.71],
            ["Carchi", 1.00],
            ["Chimborazo", 1.91],
            ["Cotopaxi", 3.22],
            ["El Oro", 12],
            ["Esmeraldas", 3.35],
            ["Guayas", 16.53],
            ["Ibarra", 0.04],
            ["Imbabura", 2.16],
            ["Loja", 3.13],
            ["Los Ríos", 9.57],
            ["Manabí", 9.36],
            ["Morona Santiago", 1.69],
            ["Napo", 0.37],
            ["Orellana", 1.11],
            ["Pastaza", 0.10],
            ["Pichincha", 8.58],
            ["Santa Elena", 2.64],
            ["Sto. Dgo. Tsáchilas", 5.19],
            ["Sucumbíos", 1.81],
            ["Tungurahua", 5.06],
            ["Zamora Chinchipe", 1.48]
          ]
        },
        {
          num: [
            ["Azuay", 6.75],
            ["Bolívar", 0.21],
            ["Cañar", 2.59],
            ["Carchi", 1.01],
            ["Chimborazo", 1.83],
            ["Cotopaxi", 3.18],
            ["El Oro", 11.89],
            ["Esmeraldas", 3.32],
            ["Guayas", 16.62],
            ["Ibarra", 0.05],
            ["Imbabura", 2.28],
            ["Loja", 3.01],
            ["Los Ríos", 10.27],
            ["Manabí", 9.28],
            ["Morona Santiago", 1.73],
            ["Napo", 0.36],
            ["Orellana", 1.14],
            ["Pastaza", 0.12],
            ["Pichincha", 8.57],
            ["Santa Elena", 2.69],
            ["Sto. Dgo. Tsáchilas", 5.34],
            ["Sucumbíos", 1.71],
            ["Tungurahua", 4.64],
            ["Zamora Chinchipe", 1.38]
          ]
        },
        {
          num: [
            ["Azuay", 6.77],
            ["Bolívar", 0.25],
            ["Cañar", 2.62],
            ["Carchi", 1.00],
            ["Chimborazo", 1.78],
            ["Cotopaxi", 3.25],
            ["El Oro", 11.75],
            ["Esmeraldas", 3.14],
            ["Guayas", 17.38],
            ["Ibarra", 0.05],
            ["Imbabura", 2.17],
            ["Loja", 2.90],
            ["Los Ríos", 10.37],
            ["Manabí", 9.19],
            ["Morona Santiago", 1.68],
            ["Napo", 0.36],
            ["Orellana", 1.32],
            ["Pastaza", 0.10],
            ["Pichincha", 8.17],
            ["Santa Elena", 2.91],
            ["Sto. Dgo. Tsáchilas", 5.05],
            ["Sucumbíos", 1.67],
            ["Tungurahua", 4.79],
            ["Zamora Chinchipe", 1.31]
          ]
        }
      ],
      months: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio"
      ],
      datos: null,
      monthActive: 0
    };
  },
  methods: {
    selectMonth: function(i) {
      this.monthActive = i;
      this.datos = this.data[i].num;
    },
    getStyleMonth: function(i) {
      return {
        active: this.monthActive == i ? true : false
      };
    }
  },
  beforeMount: function() {
    this.selectMonth(0);
  }
};
</script>