<template>
  <div class="conectividad full-width full-height">
    <Intro></Intro>
    <Acceso></Acceso>
    <Transporte></Transporte>
    <Red></Red>
    <Lambdas></Lambdas>
  </div>
</template>

<script>
import Intro from "../conectividad/Intro.vue";
import Acceso from "../conectividad/Acceso.vue";
import Transporte from "../conectividad/Transporte.vue";
import Red from "../conectividad/Red.vue";
import Lambdas from "../conectividad/Lambdas.vue";
export default {
  data: function() {
    return {

    };
  },
  components: {
    Intro,
    Acceso,
    Transporte,
    Red,
    Lambdas
  }
}

</script>
