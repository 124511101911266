<template>
  <div class="evolucion row" v-scrollanimation>
    <div class="section">
      <span class="title_section a" v-scrollanimation>Evolución Empresarial</span>
    </div>

    <div class="subtitle intro-evolucion" v-scrollanimation>
      <span class="subtitle info" v-scrollanimation>
        Ser líderes en brindar cobertura de portadores e internet a nivel nacional, además de innovar y generar una mejor asistencia íntegra y personalizada con nuestros clientes, para establecer una mejora continua de nuestros servicios.
      </span>
    </div>

    <div class="cont-timeline one-second" v-scrollanimation>

      <Timeline></Timeline>
      
    </div>

  </div>
</template>


<style>

.evolucion {
  position: relative;
  height: auto;
}

.evolucion .section {
  width: 100%;
  text-align: center;
  color: #1a4c94;
  padding-top: 8vw;
}

.evolucion .section span.a {
  display: block;
  font-size: 2.4vw;
  text-transform: uppercase;
}

.evolucion .intro-evolucion {
  display: block;
  font-size: 1.5vw;
  color: #606060;
  padding-top: 2vw;
  width: 75%;
  margin: 2vw auto 0 auto;
  text-align: justify;
}

.evolucion .cont-timeline {
  height: auto;
  width: 100%;
  padding: 6vw 0 8vw 0;
  position: relative;
}

@media only screen and (max-width: 320px) {
  .evolucion .section span.a {
    font-size: 4vw;
  }
  .evolucion .intro-evolucion {
    font-size: 2.4vw;
  }
  
}
@media only screen and (min-width: 321px) and (max-width: 480px) {
  .evolucion .section span.a {
    font-size: 4vw; 
  }
  .evolucion .intro-evolucion {
    font-size: 2.4vw;
    
  }
}
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .evolucion .section span.a {
    font-size: 4vw;
  }
  .evolucion .intro-evolucion {
    font-size: 2.4vw;
  } 
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .evolucion .section span.a {
    font-size: 4vw;
  }
  .evolucion .intro-evolucion {
    font-size: 2.4vw;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1199px) {
  .evolucion .section span.a {
    
  }
  .evolucion .intro-evolucion {
    
  }
}

</style>


<script>
import Timeline from "./subcomponents/Timeline";

export default {
  components: {
    Timeline
  }
}

</script>