<template>
  <div class="mision row" v-scrollanimation>
    <div class="cont-mision flex-row-center full-width full-height">
      <div class="middle flex-column-center align-column-center mision">
        <span class="title_section title" v-scrollanimation>
          Misión
        </span>
        <span class="content" v-scrollanimation>
          Desarrollar redes de transmisión de datos e internet con tecnología de punta y así brindar un servicio de portadores de telecomunicaciones para satisfacer las necesidades y expectativas del cliente, maximizando el rendimiento global de la empresa.
        </span>

      </div>
      <div class="middle flex-column-center align-column-center vision" v-scrollanimation>
        <span class="title_section title" v-scrollanimation>
          Visión
        </span>
        <span class="content" v-scrollanimation>
          Ser líderes en brindar cobertura de portadores e internet a nivel nacional, además de innovar y generar una mejor asistencia íntegra y personalizada con nuestros clientes, para establecer una mejora continua de nuestros servicios.
        </span>
      </div>
    </div>
  </div>
</template>

<style>

.mision {
  background: rgb(40, 78, 143);
  color: #fff;
  height: 30vw;
}

.mision .cont-mision {
  position: relative;
}

.mision .cont-mision .mision, .vision {
  padding: 0 4vw;
  width: calc(50% - 8vw);
}

.mision .cont-mision .title {
  display: block;
  width: 100%;
  font-size: 3.2vw;
  margin-bottom: 0.7vw;
}

.mision .cont-mision .content {
  display: block;
  text-align: justify;
  font-size: 1.2vw;
  line-height: 1.8vw;
  width: 100%;
}


@media only screen and (max-width: 320px) {

  .mision {
    height: 50vw;
  }
  .mision .cont-mision { 
    flex-flow: column;
  }
  .mision .cont-mision .mision, .vision {
    width: 80%; 
  }
  .mision .cont-mision .title {
    font-size: 4vw;
  }
  .mision .cont-mision .content {
    font-size: 2vw;
    line-height: 2.5vw;
  }
}
@media only screen and (min-width: 321px) and (max-width: 480px) {
  .mision {
    height: 68vw;
  }
  .mision .cont-mision { 
    flex-flow: column;
  }
  .mision .cont-mision .mision, .vision {
    width: 80%; 
  }
  .mision .cont-mision .title {
    font-size: 4vw;
  }
  .mision .cont-mision .content {
    font-size: 2.4vw;
    line-height: 4vw;
  }
}
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .mision {
    height: 50vw;
  }
  .mision .cont-mision { 
    flex-flow: column;
  }
  .mision .cont-mision .mision, .vision {
    width: 80%; 
  }
  .mision .cont-mision .title {
    font-size: 4vw;
  }
  .mision .cont-mision .content {
    font-size: 2vw;
    line-height: 2.5vw;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .mision {
    
  }
  .mision .cont-mision { 
    
  }
  .mision .cont-mision .mision, .vision {
    
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1199px) {
  .mision {
    
  }
  .mision .cont-mision { 
    
  }
  .mision .cont-mision .mision, .vision {
    
  }
}


</style>

<script>
  export default {
    
  }
</script>