<template>
  <div class="row clientes" v-scrollanimation>
    <div class="fondo" v-scrollanimation></div>
    <div class="section">
      <span class="title_section a" v-scrollanimation>Nuestros Clientes</span>
    </div>

    <div class="cont-slider flex-column-center align-column-center">
      <div class="slider" v-scrollanimation>
        <div :key="s.key" v-for="s in slide.slides.items" :class="getClassSlideItem(s.index)">
          <div
            :key="'item_' + pos"
            v-for="(i, pos) in s.item"
            :class="getClassItem()"
            v-show="i !== null"
          >
            <div class="cont-img flex-column-center align-column-center">
              <img v-if="i !== null" :src="i.url" />
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="buttons">
        <div
          :key="btn.key"
          v-for="btn in slide.slides.buttons"
          :class="getClassButton(btn.index)"
          @click="changeActiveSection(btn.index)"
        ></div>
      </div> -->
    </div>
  </div>
</template>


<script>
import claro from "../../assets/img/home/cl-claro.png"
import cedia from "../../assets/img/home/cl-cedia.png"
import cw from "../../assets/img/home/cw.png"
import telefonica from "../../assets/img/home/telefonica.png"
import pccw from "../../assets/img/home/pccw.png"
import cnt from "../../assets/img/home/cnt.png"
import century from "../../assets/img/home/century.png"
import puntonet from "../../assets/img/home/puntonet.png"




export default {
  data: function() {
    return {
      it: [
        {
          url: claro
        },
        {
          url: cedia
        },
        {
          url: cw
        },
        {
          url: telefonica
        },
        {
          url: pccw
        },
        {
          url: century
        },
        {
          url: puntonet
        }
      ],
      slide: {
        size: 0,
        slides: {
          active: 0,
          buttons: [],
          items: []
        }
      }
    };
  },
  beforeMount: function() {
    this.loadItems();
  },
  methods: {
    getClassSlideItem: function(index) {
      return {
        slide: true,
        active: this.slide.slides.active === index
      };
    },
    getClassItem: function() {
      return {
        item: true
      };
    },
    getClassButton: function(index) {
      return {
        shadow: true,
        button: true,
        active: this.slide.slides.active === index
      };
    },
    changeActiveSection: function(index) {
      this.slide.slides.active = index;
    },

    loadItems:  function() {
      this.slide.size = this.it.length;
      let indices = 0;
      for (let i = 0; i < this.slide.size; i += 8) {
        let aux = {
          key: "slide_" + i,
          index: indices,
          item: []
        };
        for (let j = 0; j < 8; j++) {
          aux.item.push(
            i + j < this.slide.size ? this.it[i + j] : null
          );
        }
        this.slide.slides.items.push(aux);
        indices += 1;
      }
      for (let j = 0; j < this.slide.slides.items.length; j++) {
        this.slide.slides.buttons.push({
          key: "button_" + j,
          index: j
        });
      }
    }

  }
}

</script>

<style>
.clientes {
  height: auto;
  position: relative;
  background: #f0f0f0;
}

.clientes .fondo {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  background-image: url("../../assets/img/home/fondo-clientes.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
}

.clientes .section {
  width: 100%;
  text-align: center;
  color: #1a4c94;
  padding-top: 8vw;
}

.clientes .section span.a {
  display: block;
  font-size: 2.4vw;
  text-transform: uppercase;
}

.clientes .section span.b {
  display: block;
  font-size: 1.2vw;
}


.clientes .cont-slider {
  width: 100%;
  height: calc(100% - 8vw);
  padding: 0vw 0 8vw 0;
  align-items: flex-end;
  position: relative;
  z-index: 2;
}

.clientes .cont-slider .slider {
  position: relative;
  width: 90%;
  height: 22vw;
  margin: 0 auto;
}

.clientes .cont-slider .slider .slide {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row wrap;

  position: absolute;
  z-index: -1;
  opacity: 0;
  transition: all 0.3s 0.3s ease;
}

.clientes .cont-slider .slider .slide.active {
  z-index: 1;
  opacity: 1;
  transition: all 0.3s 0.3s ease;
}

.clientes .cont-slider .slider .slide .item {
  width: calc((100% / 4) - 1vw);
  height: calc(12vw - 2vw);
  margin: 0vw 0.5vw 2vw 0.5vw;
  display: flex;
  flex-flow: column;
  justify-content: start;
  align-items: center;
  position: relative;
  border-radius: 8px;
  transition: all 0.1s 0.1s;
}
.clientes .cont-slider .slider .slide .item .cont-img {
  width: 90%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.clientes .cont-slider .slider .slide .item .cont-img img {
  width: 100%;
  height: 100%;
}

.clientes .cont-slider .buttons {
  width: 90%;
  height: 2vw;
  margin: 2vw auto 0 auto;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
}
.clientes .cont-slider .buttons .button {
  background: #f2f2f2;
  width: 0.9vw;
  height: 0.9vw;
  border-radius: 50%;
  margin: 0 0.2vw;
  cursor: pointer;
  transition: all 0.3s 0.3s ease;
}
.clientes .cont-slider .buttons .button:active {
  transform: scale(0.98);
}
.clientes .cont-slider .buttons .button.active {
  background: #0D2C7C;;
  width: 1vw;
  height: 1vw;
  transition: all 0.3s 0.3s ease;
}

@media only screen and (max-width: 320px) {

  .clientes .section span.a {
    font-size: 4vw;
  }
  
}
@media only screen and (min-width: 321px) and (max-width: 480px) {
  .clientes .section span.a {
    font-size: 4vw;
  }
  
}
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .clientes .section span.a {
    font-size: 4vw;
  }
  
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .clientes .section span.a {
    font-size: 4vw;
  }
  
}
@media only screen and (min-width: 1025px) and (max-width: 1199px) {
  .clientes .section span.a {
    font-size: 4vw;
  }
  
}
</style>