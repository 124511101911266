<template>
  <div class="home">
    <NavBar @updateSection="updateSection"></NavBar>
    <Portada></Portada>
    <Nedetel id="section_0"></Nedetel>
    <Soluciones id="section_1"></Soluciones>
    <Partners id="section_2"></Partners>
    <Trayectoria id="section_3"></Trayectoria>
    <Clientes id="section_4"></Clientes>
    <Historias id="section_5"></Historias>
    <Noticias id="section_6"></Noticias>
    <Contacto id="section_7"></Contacto>
    <Footer></Footer>
  </div>
</template>

<script>
import NavBar from "../components/Navbar.vue";
import Footer from "../components/Footer.vue";
import Portada from "../components/home/Portada.vue";
import Nedetel from "../components/home/Nedetel.vue";
import Soluciones from "../components/home/Soluciones.vue";
import Partners from "../components/home/Partners.vue";
import Trayectoria from "../components/home/Trayectoria.vue";
import Clientes from "../components/home/Clientes.vue";
import Historias from "../components/home/Historias.vue";
import Noticias from "../components/home/Noticias.vue";
import Contacto from "../components/home/Contacto.vue";
import { scroller } from "vue-scrollto/src/scrollTo";
export default {
  name: "Home",
  data: function() {
    return {
      section_active: 0
    };
  },
  components: {
    NavBar,
    Portada,
    Nedetel,
    Soluciones,
    Partners,
    Trayectoria,
    Clientes,
    Historias,
    Noticias,
    Contacto,
    Footer
  },
  props: {
    section: {
      type: Number,
      default: 0
    }
  },
  beforeMount() {
    this.section_active = this.section ? this.section : 0;
  },
  mounted() {
    if (this.section >= 0) {
      let scrool = scroller();
      scrool("#section_" + this.section);
    }
  },
  methods: {
    updateSection: function(index) {
      let scrool = scroller();
      scrool("#section_" + index);
      this.section_active = index;
    }
  }
};
</script>