<template>
  <div class="historias row" v-scrollanimation>
    <div class="section">
      <span class="title_section a" v-scrollanimation>Historias de éxito</span>
    </div>
    <div class="cont-slider flex-column-center align-column-center">
      <div class="slider shadow" v-scrollanimation>
        <div :key="s.key" v-for="s in slide.slides.items" :class="getClassSlideItem(s.index)">
          <div
            :key="'item_' + pos"
            v-for="(i, pos) in s.item"
            :class="getClassItem()"
            v-show="i !== null"
          >
            <div class="texto">
              <div class="subtexto">
                <img src="../../assets/img/home/open-mark.png" alt="" class="mark open">
                <img src="../../assets/img/home/close-mark.png" alt="" class="mark close">
                <span class="content" v-if="i !== null">{{i.critic}}</span>
              </div>
            </div>
            <span class="name title_section flex-row-center align-row-start" v-if="i !== null">{{i.empresa}}</span>
          </div>
        </div>
      </div>
      <div class="buttons">
        <div
          :key="btn.key"
          v-for="btn in slide.slides.buttons"
          :class="getClassButton(btn.index)"
          @click="changeActiveSection(btn.index)"
        ></div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data: function() {
    return {
      it: [
        {
          critic: "Nedetel es lo mejor que hemos contratado en estos tiempos.",
          empresa: "Nubia Morán"
        },
        {
          critic: "Nunca había experimentado tanta calidad como con Nedetel.",
          empresa: "Juan Chávez"
        },
        {
          critic: "Muy agradecido por ese gran apoyo y de igual forma para todo el equipo de trabajo de tan prestigiosa empresa NEDETEL.",
          empresa: "Luis Pacheco"
        },
        {
          critic: "Me siento muy orgulloso de pertenecer a una excelente empresa.",
          empresa: "Galo Alva"
        },
        {
          critic: "Muy agradecido con la buena gestión de Nedetel, eso nos posibilita mejorar y competir.",
          empresa: "Vicente Sánchez"
        },
        {
          critic: "Todos hacemos Nedetel directa o indirectamente. Felicitaciones.",
          empresa: "Juan Carlos Gónzalez"
        },
        {
          critic: "Un gusto ser parte indirecta de los logros de Nedetel.",
          empresa: "Samuel Martínez"
        }
      ],
      slide: {
        size: 0,
        slides: {
          active: 0,
          buttons: [],
          items: []
        }
      }
    };
  },
  beforeMount: function() {
    this.loadItems();
  },
  methods: {
    getClassSlideItem: function(index) {
      return {
        slide: true,
        active: this.slide.slides.active === index
      };
    },
    getClassItem: function() {
      return {
        item: true
      };
    },
    getClassButton: function(index) {
      return {
        shadow: true,
        button: true,
        active: this.slide.slides.active === index
      };
    },
    changeActiveSection: function(index) {
      this.slide.slides.active = index;
    },

    loadItems:  function() {
      this.slide.size = this.it.length;
      let indices = 0;
      for (let i = 0; i < this.slide.size; i += 3) {
        let aux = {
          key: "slide_" + i,
          index: indices,
          item: []
        };
        for (let j = 0; j < 3; j++) {
          aux.item.push(
            i + j < this.slide.size ? this.it[i + j] : null
          );
        }
        this.slide.slides.items.push(aux);
        indices += 1;
      }
      for (let j = 0; j < this.slide.slides.items.length; j++) {
        this.slide.slides.buttons.push({
          key: "button_" + j,
          index: j
        });
      }
    }
  }
}

</script>


<style>
.historias {
  position: relative;
  background: #225DB7;  
  height: auto;
}

.historias .section {
  width: 100%;
  text-align: center;
  color: #FFF;
  padding-top: 8vw;
}

.historias .section span.a {
  display: block;
  font-size: 2.4vw;
  text-transform: uppercase;
}

.historias .section span.b {
  display: block;
  font-size: 1.2vw;
}


.historias .cont-slider {
  width: 100%;
  height: calc(100% - 12vw);
  padding: 4vw 0 6vw 0;
  align-items: flex-end;
  position: relative;
  z-index: 2;
}

.historias .cont-slider .slider {
  position: relative;
  width: 90%;
  height: 12vw;
  padding: 2vw 0;
  margin: 0 auto;
}

.historias .cont-slider .slider .slide {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row wrap;

  position: absolute;
  z-index: -1;
  opacity: 0;
  transition: all 0.3s 0.3s ease;
}

.historias .cont-slider .slider .slide.active {
  z-index: 1;
  opacity: 1;
  transition: all 0.3s 0.3s ease;
}

.historias .cont-slider .slider .slide .item {
  width: calc((100% / 3) - 2vw);
  height: calc(14vw - 2vw);
  margin: 0vw 1vw;
  display: flex;
  flex-flow: column;
  justify-content: start;
  align-items: center;
  position: relative;
  border-radius: 8px;
  transition: all 0.1s 0.1s;
}

.historias .cont-slider .slider .slide .item .texto {
  position: relative;
  width: 100%;
  height: calc(100% - 4vw);
  max-height: calc(100% - 4vw);
}

.historias .cont-slider .slider .slide .item .texto .subtexto {
  width: 100%;
  max-width: 100%;
  height: auto;
  max-height: 85%;
  position: relative;
  overflow: scroll;

}

.historias .cont-slider .slider .slide .item .texto .subtexto .content {
  color: #FFF;
  width: calc(100% - 3.4vw);
  height: calc(100% - 1.3vw);
  padding: 1.3vw 1.7vw 0vw 1.7vw;
  display: block;
  overflow: scroll;
  text-align: justify;
}

.historias .cont-slider .slider .slide .item .texto .subtexto .mark {
  position: absolute;
  width: 1.2vw;
  height: 0.8vw;
}

.historias .cont-slider .slider .slide .item .mark.open {
  top: 0;
  left: 0;
}

.historias .cont-slider .slider .slide .item .mark.close {
  bottom: 0;
  right: 0;
}

.historias .cont-slider .slider .slide .item .name {
  position: relative;
  width: calc(100% - 3.4vw);
  padding: 0 1.7vw;
  color: #FFF;
  text-transform: uppercase;
  max-height: 4vw;
  height: auto;
  font-size: 1.2vw;
}

.historias .cont-slider .buttons {
  width: 90%;
  height: 2vw;
  margin: 2vw auto 0 auto;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
}
.historias .cont-slider .buttons .button {
  background: #f2f2f2;
  width: 0.9vw;
  height: 0.9vw;
  border-radius: 50%;
  margin: 0 0.2vw;
  cursor: pointer;
  transition: all 0.3s 0.3s ease;
}
.historias .cont-slider .buttons .button:active {
  transform: scale(0.98);
}
.historias .cont-slider .buttons .button.active {
  background: #0D2C7C;;
  width: 1vw;
  height: 1vw;
  transition: all 0.3s 0.3s ease;
}

@media only screen and (max-width: 320px) {
  
  .historias .section span.a {
    font-size: 4vw;
  }

  .noticias .section span.b {

  }
  
  .historias .cont-slider .slider .slide .item {
    height: auto;
    font-size: 1.15vw;
  }
  .historias .cont-slider .slider .slide .item .content {
    font-size: 1.85vw;
    text-align: left;
  }
  .historias .cont-slider .buttons .button {
    margin: 0 0.8vw;
    width: 1.1vw;
    height: 1.1vw;
  }
  .historias .cont-slider .buttons .button.active {
    width: 1.8vw;
    height: 2vw;
  }
  .historias .cont-slider .slider .slide .item .name {
    font-size: 2vw;
  }
  .historias .cont-slider .slider {
    height: 26vw;
  }
}
@media only screen and (min-width: 321px) and (max-width: 480px) {
  .historias .section span.a {
    font-size: 4vw;
  }
  .historias .cont-slider .slider .slide .item {
    height: auto;
    font-size: 1.15vw;
  }
  .historias .cont-slider .slider .slide .item .content {
    font-size: 1.6vw;
    text-align: left;
  }
  .historias .cont-slider .buttons .button {
    margin: 0 0.8vw;
    width: 1.1vw;
    height: 1.1vw;
  }
  .historias .cont-slider .buttons .button.active {
    width: 1.8vw;
    height: 2vw;
  }
  .historias .cont-slider .slider .slide .item .name {
    font-size: 2vw;
  }
  .historias .cont-slider .slider {
    height: 22vw;
  }
}
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .historias .section span.a {
    font-size: 4vw;
  }
  .historias .cont-slider .slider {
   height: 18vw; 
  }
  .historias .cont-slider .slider .slide .item {
    height: calc(19vw - 0vw);
    font-size: 1.15vw;
  }
  .historias .cont-slider .slider .slide .item .content {
    font-size: 1.6vw;  
  }
  .historias .cont-slider .buttons .button {
    margin: 0 0.8vw;
    width: 1.1vw;
    height: 1.1vw;
  }
  .historias .cont-slider .buttons .button.active {
    width: 1.8vw;
    height: 2vw;
  }
  .historias .cont-slider .slider .slide .item .name {
    
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .historias .section span.a {
    font-size: 4vw;
  }
  .historias .cont-slider .slider .slide .item {
    height: calc(16vw - 0vw);
  }
  .historias .cont-slider .slider .slide .item .content {
    font-size: 1.1vw;  
  }
  .historias .cont-slider .slider .slide .item .name {
    
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1199px) {
  .historias .section span.a {
    font-size: 4vw;
  }
  .historias .cont-slider .slider .slide .item {
    height: calc(16vw - 0vw);
  }
  .historias .cont-slider .slider .slide .item .content {
    font-size: 1vw;  
  }
  .historias .cont-slider .slider .slide .item .name {
    
  }
}


</style>