<template>
  <div class="indicador one">
    <div class="months flex-row-center align-row-center shadow">
      <span
        v-for="(m, i) in months"
        :key="i"
        @click="selectMonth(i)"
        class="month flex-row-center align-row-center"
        :class="getStyleMonth(i)"
      >{{ m }}</span>
    </div>

    
    <bar-chart class="grafico" v-if="datos !== null" suffix="%" :colors="['#1a4c94']" :data="datos" :options="options"></bar-chart>

    
  </div>
</template>

<style>
.indicador.one {
  width: 100%;
  height: 100%;
  position: relative;
}

.indicador.one .grafico {
  height: 60vw !important;
  width: 90% !important;
  position: relative !important;
  margin: 0 auto !important;
  display: block !important;
}

.indicador.one .months {
  background: #fff;
  height: 4vw;
  margin: 1vw auto 3vw auto;
  width: 100%;
}

.indicador.one .months .month {
  background: #fff;
  color: #1a4c94;
  padding: 0 1vw;
  height: 100%;
  width: calc(100% / 12);
  transition: all 0.1s 0.1s ease-in;
}

.indicador.one .months .month:hover {
  cursor: pointer;
}

.indicador.one .months .month.active {
  background: #1a4c94;
  color: #fff;
  transition: all 0.1s 0.1s ease-in-out;
}
</style>

<script>
export default {
  data: function() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
      },
      data: [
        {
          num: [
            ["Azuay", 5.56],
            ["Bolívar", 0.04],
            ["Cañar", 1.2],
            ["Carchi", 1.36],
            ["Chimborazo", 0.85],
            ["Cotopaxi", 3.04],
            ["El Oro", 17.86],
            ["Esmeraldas", 1.13],
            ["Guayas", 18.57],
            ["Imbabura", 6.34],
            ["Loja", 4.23],
            ["Los Ríos", 2.52],
            ["Pichincha", 6.61],
            ["Manabí", 9.88],
            ["Morona Santiago", 1.68],
            ["Napo", 0.14],
            ["Orellana", 0.05],
            ["Pastaza", 0.06],
            ["Santa Elena", 2.65],
            ["Sto. Dgo. Tsáchilas", 5.98],
            ["Tungurahua", 4.67],
            ["Zamora Chinchipe", 1.35]
          ]
        },
        {
          num: [
            ["Azuay", 5.52],
            ["Bolívar", 0.04],
            ["Cañar", 1.13],
            ["Carchi", 1.48],
            ["Chimborazo", 1.12],
            ["Cotopaxi", 3.04],
            ["El Oro", 16.71],
            ["Esmeraldas", 1.15],
            ["Guayas", 18.65],
            ["Imbabura", 5.99],
            ["Loja", 4.55],
            ["Los Ríos", 6.41],
            ["Manabí", 9.65],
            ["Morona Santiago", 1.8],
            ["Napo", 0.14],
            ["Orellana", 1.13],
            ["Pastaza", 0.06],
            ["Pichincha", 7.07],
            ["Santa Elena", 2.75],
            ["Sto. Dgo. Tsáchilas", 5.79],
            ["Tungurahua", 4.54],
            ["Zamora Chinchipe", 1.27]
          ]
        },
        {
          num: [
            ["Azuay", 5.53],
            ["Bolívar", 0.04],
            ["Cañar", 1.35],
            ["Carchi", 1.41],
            ["Chimborazo", 1.11],
            ["Cotopaxi", 2.95],
            ["El Oro", 17.22],
            ["Esmeraldas", 1.1],
            ["Guayas", 18.62],
            ["Imbabura", 5.74],
            ["Loja", 4.44],
            ["Los Ríos", 6.31],
            ["Manabí", 9.59],
            ["Morona Santiago", 1.88],
            ["Napo", 0.15],
            ["Orellana", 1.07],
            ["Pastaza", 0.09],
            ["Pichincha", 6.75],
            ["Santa Elena", 2.67],
            ["Sto. Dgo. Tsáchilas", 5.74],
            ["Sucumbíos", 0.09],
            ["Tungurahua", 4.93],
            ["Zamora Chinchipe", 1.21]
          ]
        },
        {
          num: [
            ["Azuay", 5.17],
            ["Bolívar", 0.05],
            ["Cañar", 1.61],
            ["Carchi", 1.29],
            ["Chimborazo", 1.49],
            ["Cotopaxi", 3.1],
            ["El Oro", 15.79],
            ["Esmeraldas", 0.7],
            ["Guayas", 18.55],
            ["Imbabura", 5.99],
            ["Loja", 4.04],
            ["Los Ríos", 6.07],
            ["Manabí", 8.85],
            ["Morona Santiago", 1.74],
            ["Napo", 0.13],
            ["Orellana", 1.01],
            ["Pastaza", 0.08],
            ["Pichincha", 7.53],
            ["Santa Elena", 2.45],
            ["Sto. Dgo. Tsáchilas", 7.42],
            ["Sucumbíos", 0.52],
            ["Tungurahua", 5.19],
            ["Zamora Chinchipe", 1.23]
          ]
        },
        {
          num: [
            ["Azuay", 5.34],
            ["Bolívar", 0.05],
            ["Cañar", 1.5],
            ["Carchi", 1.53],
            ["Chimborazo", 1.81],
            ["Cotopaxi", 2.98],
            ["El Oro", 15.35],
            ["Esmeraldas", 0.67],
            ["Guayas", 17.56],
            ["Imbabura", 6.23],
            ["Loja", 4.18],
            ["Los Ríos", 6.51],
            ["Manabí", 7.96],
            ["Morona Santiago", 1.94],
            ["Napo", 0.15],
            ["Orellana", 1.1],
            ["Pastaza", 0.08],
            ["Pichincha", 8.28],
            ["Santa Elena", 2.23],
            ["Sto. Dgo. Tsáchilas", 7.26],
            ["Sucumbíos", 0.53],
            ["Tungurahua", 4.97],
            ["Zamora Chinchipe", 1.81]
          ]
        },
        {
          num: [
            ["Azuay", 5.74],
            ["Bolívar", 0.05],
            ["Cañar", 1.42],
            ["Carchi", 1.46],
            ["Chimborazo", 2.06],
            ["Cotopaxi", 3.05],
            ["El Oro", 15.3],
            ["Esmeraldas", 0.69],
            ["Guayas", 17.2],
            ["Imbabura", 6.32],
            ["Loja", 4.28],
            ["Los Ríos", 6.64],
            ["Manabí", 7.76],
            ["Morona Santiago", 2.01],
            ["Napo", 0.14],
            ["Orellana", 1.33],
            ["Pastaza", 0.08],
            ["Pichincha", 8.37],
            ["Santa Elena", 2.2],
            ["Sto. Dgo. Tsáchilas", 6.66],
            ["Sucumbíos", 0.54],
            ["Tungurahua", 4.95],
            ["Zamora Chinchipe", 1.74]
          ]
        },
        {
          num: [
            ["Azuay", 5.5],
            ["Bolívar", 0.06],
            ["Cañar", 1.38],
            ["Carchi", 1.48],
            ["Chimborazo", 2.14],
            ["Cotopaxi", 3.02],
            ["El Oro", 15.03],
            ["Esmeraldas", 0.65],
            ["Guayas", 18.25],
            ["Imbabura", 6.4],
            ["Loja", 4.05],
            ["Los Ríos", 6.66],
            ["Manabí", 7.46],
            ["Morona Santiago", 1.99],
            ["Napo", 0.13],
            ["Orellana", 1.32],
            ["Pastaza", 0.07],
            ["Pichincha", 8.09],
            ["Santa Elena", 2.18],
            ["Sto. Dgo. Tsáchilas", 6.38],
            ["Sucumbíos", 0.9],
            ["Tungurahua", 4.75],
            ["Zamora Chinchipe", 2.1]
          ]
        },
        {
          num: [
            ["Azuay", 5.47],
            ["Bolívar", 0.06],
            ["Cañar", 1.32],
            ["Carchi", 1.39],
            ["Chimborazo", 2.15],
            ["Cotopaxi", 3.01],
            ["El Oro", 16.72],
            ["Esmeraldas", 0.65],
            ["Guayas", 17.78],
            ["Imbabura", 5.9],
            ["Loja", 3.9],
            ["Los Ríos", 7.16],
            ["Manabí", 7.97],
            ["Morona Santiago", 1.83],
            ["Napo", 0.11],
            ["Orellana", 1.5],
            ["Pastaza", 0.07],
            ["Pichincha", 7.72],
            ["Santa Elena", 1.93],
            ["Sto. Dgo. Tsáchilas", 6.35],
            ["Sucumbíos", 0.52],
            ["Tungurahua", 4.72],
            ["Zamora Chinchipe", 1.75]
          ]
        },
        {
          num: [
            ["Azuay", 5.4],
            ["Bolívar", 0.06],
            ["Cañar", 1.37],
            ["Carchi", 1.31],
            ["Chimborazo", 2.15],
            ["Cotopaxi", 3.31],
            ["El Oro", 15.47],
            ["Esmeraldas", 0.64],
            ["Guayas", 16.94],
            ["Imbabura", 5.85],
            ["Loja", 3.78],
            ["Los Ríos", 8.34],
            ["Manabí", 8.2],
            ["Morona Santiago", 2.41],
            ["Napo", 0.12],
            ["Orellana", 1.49],
            ["Pastaza", 0.07],
            ["Pichincha", 8.16],
            ["Santa Elena", 2.1],
            ["Sto. Dgo. Tsáchilas", 6.11],
            ["Sucumbíos", 0.23],
            ["Tungurahua", 4.68],
            ["Zamora Chinchipe", 1.81]
          ]
        },
        {
          num: [
            ["Azuay", 5.48],
            ["Bolívar", 0.06],
            ["Cañar", 1.41],
            ["Carchi", 0.7],
            ["Chimborazo", 2.14],
            ["Cotopaxi", 3.34],
            ["El Oro", 16.03],
            ["Esmeraldas", 2.33],
            ["Guayas", 16.31],
            ["Imbabura", 3.5],
            ["Loja", 3.79],
            ["Los Ríos", 7.68],
            ["Manabí", 8.6],
            ["Morona Santiago", 2.37],
            ["Napo", 0.26],
            ["Orellana", 1.64],
            ["Pastaza", 0.07],
            ["Pichincha", 8.59],
            ["Santa Elena", 2.41],
            ["Sto. Dgo. Tsáchilas", 6.47],
            ["Sucumbíos", 0.33],
            ["Tungurahua", 4.69],
            ["Zamora Chinchipe", 1.79]
          ]
        },
        {
          num: [
            ["Azuay", 5.31],
            ["Bolívar", 0.06],
            ["Cañar", 1.41],
            ["Carchi", 0.81],
            ["Chimborazo", 2.05],
            ["Cotopaxi", 3.21],
            ["El Oro", 15.13],
            ["Esmeraldas", 2.39],
            ["Guayas", 16.24],
            ["Imbabura", 3.72],
            ["Loja", 3.57],
            ["Los Ríos", 8.84],
            ["Manabí", 8.4],
            ["Morona Santiago", 2.56],
            ["Napo", 0.24],
            ["Orellana", 1.61],
            ["Pastaza", 0.07],
            ["Pichincha", 8.99],
            ["Santa Elena", 2.33],
            ["Sto. Dgo. Tsáchilas", 6.11],
            ["Sucumbíos", 0.34],
            ["Tungurahua", 4.87],
            ["Zamora Chinchipe", 1.75]
          ]
        },
        {
          num: [
            ["Azuay", 5.28],
            ["Bolívar", 0.05],
            ["Cañar", 1.5],
            ["Carchi", 0.75],
            ["Chimborazo", 2.05],
            ["Cotopaxi", 3.27],
            ["El Oro", 14.3],
            ["Esmeraldas", 2.38],
            ["Guayas", 16.75],
            ["Imbabura", 3.64],
            ["Loja", 3.71],
            ["Los Ríos", 7.96],
            ["Manabí", 8.53],
            ["Morona Santiago", 2.38],
            ["Napo", 0.14],
            ["Orellana", 2.12],
            ["Pastaza", 0.07],
            ["Pichincha", 9.4],
            ["Santa Elena", 2.27],
            ["Sto. Dgo. Tsáchilas", 6.36],
            ["Sucumbíos", 0.33],
            ["Tungurahua", 4.89],
            ["Zamora Chinchipe", 1.89]
          ]
        }
      ],
      months: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre"
      ],
      datos: null,
      monthActive: 0
    };
  },
  methods: {
    selectMonth: function(i) {
      this.monthActive = i;
      this.datos = this.data[i].num;
    },
    getStyleMonth: function(i) {
      return {
        active: this.monthActive == i ? true : false
      };
    }
  },
  beforeMount: function() {
    this.selectMonth(0);
  }
};
</script>