<template>
  <div :class="getClassByTipo()" v-scrollanimation>
    <div class="a flex-row-center align-row-center">
      <img
        v-if="tipo === 0"
        class="icon loader-form"
        src="../assets/img/puff.svg"
      />
      <font-awesome-icon
        v-if="tipo === 1"
        :icon="['fas', 'exclamation-triangle']"
        class="icon"
      />
      <font-awesome-icon
        v-if="tipo === 2"
        :icon="['fas', 'check-circle']"
        class="icon"
      />
      <span>{{ mensaje }}</span>
    </div>
    <font-awesome-icon
      :icon="['fas', 'times-circle']"
      class="close"
      @click="changeShowStatus(false)"
    />
  </div>


</template>


<script>
export default {
  data: function() {
    return {};
  },
  props: {
    tipo: {
      type: Number,
      default() {
        return 0;
      }
    },
    mostrar: {
      type: Boolean,
      default() {
        return false;
      }
    },
    esperar: {
      type: Boolean,
      default() {
        return false;
      }
    },
    mensaje: {
      type: String,
      default() {
        return "Espere por favor...";
      }
    }
  },
  methods: {
    changeShowStatus: function(value) {
      if (this.tipo !== 0) {
        this.$emit("changeStatus", value);
      }
    },
    getClassByTipo: function() {
      return {
        shadow: true,
        show: this.mostrar,
        status: true,
        waiting: this.tipo === 0,
        error: this.tipo === 1,
        done: this.tipo === 2
      };
    }
  }
};
</script>


<style>

.status {
  display: none;
  width: auto;
  padding: 0.5vw 2vw ;
  height: auto;
  margin-top: 0.7vw;
  border-radius: 8px;
  transition: all 0.1s 0.1s ease-in;
}

.status.show {
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.1s 0.1s ease-in-out;
}

.status.waiting {
  background: #1a4c94;
  color: #f2f2f2;
}
.status.error {
  background: #ed143d;
  color: #f2f2f2;
}
.status.done {
  background: #388E3C;
  color: #f2f2f2;
}
.status .a .icon.loader-form {
  width: 10%;
  height: 10%;
  display: block;
  margin: 0 auto;
  margin-right: 1vw;
}
.status .a .icon {
  font-size: 1vw;
  margin-right: 1vw;
}
.status .a span {
  font-size: 0.9vw;
  font-family: montserrat_regular;
  margin-right: 1vw;
}
.status .close {
  font-size: 1.4vw;
  cursor: pointer;
}
.status .close:active {
  transform: scale(0.98);
}
.status.waiting .close {
  color: transparent;
}

</style>