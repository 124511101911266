<template>
  <div class="cobertura row" v-scrollanimation>
    <span
      class="subtitle intro a"
      v-scrollanimation
    >
      Gracias a sus diferentes colaboradores, su cobertura se extiende a lo largo del territorio ecuatoriano, brindando apoyo a todos aquellos emprendedores que desean iniciar en el mercado del Internet y mantener conectado a su comunidad.
    </span>

    <span class="subtitle intro b" v-scrollanimation>Contamos con clientes en las 3 regiones del país:</span>

    <div class="cont_acordeon flex-column-center align-column-center">
      <Acordeon
        v-for="(section, i) in sections"
        :key="i"
        :active="section.active"
        :section="section"
        :index="i"
        @openSection="openSection"
      > 
      </Acordeon>
    </div>

    <div class="cont-img-mapa" v-scrollanimation>
      <img src="../../assets/img/nosotros/mapa.png" alt="cobertura-nedetel" />
    </div>
  </div>
</template>

<style>
.cobertura {
  position: relative;
  height: auto;
}

.cobertura .intro {
  display: block;
  font-size: 1.5vw;
  color: #606060;
  padding-top: 2vw;
  width: 85%;
  text-align: justify;
}

.cobertura .cont_acordeon {
  padding: 2vw 0;
  width: 85%;
  height: auto;
}

.cobertura .cont-img-mapa {
  margin-bottom: 4vw;
}

.cobertura .cont-img-mapa img {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: block;
}

@media only screen and (max-width: 320px) {
  .cobertura .intro {
    font-size: 2.2vw;
  }

  .cobertura .intro {
    display: block;
    font-size: 2.2vw;
    color: #606060;
    padding-top: 2vw;
    width: 100%;
    text-align: justify;
    font-family: unisans_regular;
  }

  .cobertura .cont_acordeon {
    padding: 2vw 0;
    width: 100%;
    height: auto;
  }
}
@media only screen and (min-width: 321px) and (max-width: 480px) {
  .cobertura .intro {
    font-size: 2.2vw;
  }
  .cobertura .intro {
    display: block;
    font-size: 2.2vw;
    color: #606060;
    padding-top: 2vw;
    width: 100%;
    text-align: justify;
  }

  .cobertura .cont_acordeon {
    padding: 2vw 0;
    width: 100%;
    height: auto;
  }
}
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .cobertura .intro {
    font-size: 2.2vw;
    
  }
  .cobertura .intro {
    display: block;
    font-size: 2.2vw;
    color: #606060;
    padding-top: 2vw;
    width: 100%;
    text-align: justify;
  }

  .cobertura .cont_acordeon {
    padding: 2vw 0;
    width: 100%;
    height: auto;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
 .cobertura .intro {
    display: block;
    font-size: 1.5vw;
    color: #606060;
    padding-top: 2vw;
    width: 100%;
    text-align: justify;
  }

  .cobertura .cont_acordeon {
    padding: 2vw 0;
    width: 100%;
    height: auto;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1199px) {
  .cobertura .intro {
    display: block;
    font-size: 1.5vw;
    color: #606060;
    padding-top: 2vw;
    width: 100%;
    text-align: justify;
  }

  .cobertura .cont_acordeon {
    padding: 2vw 0;
    width: 100%;
    height: auto;
  }
}

</style>


<script>
import Acordeon from "./subcomponents/Acordeon";
export default {
  name: "Cobertura",
  data: function() {
    return {
      sections: [
        {
          titulo: "Costa",
          content: [
            "El Oro",
            "Esmeraldas",
            "Guayas",
            "Los Ríos",
            "Manabí",
            "Santa Elena",
            "Santo Domingo de los Tsáchilas"
          ],
          active: true
        },
        {
          titulo: "Sierra",
          content: [
            "Azuay",
            "Bolívar",
            "Carchi",
            "Cañar",
            "Chimborazo",
            "Cotopaxi",
            "Imbabura",
            "Loja",
            "Pichincha",
            "Tungurahua"
          ],
          active: false
        },
        {
          titulo: "Oriente",
          content: [
            "Sucumbíos",
            "Napo",
            "Orellana",
            "Pastaza",
            "Morona Santiago",
            "Zamora Chinchipe"
          ],
          active: false
        }
      ]
    };
  },
  components: {
    Acordeon
  },
  mounted: function() {
    
  },
  methods: {
    openSection: function(index) {
      this.sections = this.sections.map((sct, i) => {
        if (index === i) {
          sct.active = !sct.active;
        } else {
          sct.active = false;
        }
        return sct;
      });
    },

  }
};



</script>