<template>
  <div class="intro-seguridad full-width row" v-scrollanimation>
    <div class="cont-intro-seguridad flex-row-center align-row-center full-height">
      <div class="middle intro-seguridad-text flex-row-center align-row-center">
        <div class="content-intro-seguridad-text flex-column-center" v-scrollanimation>
          <span class="titulo title_section" v-scrollanimation>
            Seguridad
          </span>
          <div class="barra" v-scrollanimation></div>
          <div class="contenido subtitle" v-scrollanimation>
            Con 15 años de experiencia en el mercado de las telecomunaciones, Nedetel ha logrado posicionarse como uno de los Proveedores de Servicios de Internet (ISP) y Portadores más grandes a nivel nacional.
          </div>
        </div>
      </div>
      <div class="middle intro-seguridad-img flex-row-center align-row-center">
        <div class="cont-img">
          <img src="../../../assets/img/soluciones/intro-seguridad.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.intro-seguridad {
  height: 45vw;
  position: relative;
}

.intro-seguridad .intro-seguridad-img {
  align-items: center;
}

.intro-seguridad .intro-seguridad-text {
  padding-left: 8vw;
  width: calc(50% - 8vw);
  height: 100%;
  position: relative;
}

.intro-seguridad .intro-seguridad-text .content-intro-seguridad-text { 
  height: auto;
}


.intro-seguridad  .intro-seguridad-text .titulo { 
  color: #1A4C94;
  font-size: 2.8vw;
  text-transform: uppercase;
}

.intro-seguridad .intro-seguridad-text .barra {
  height: 0.5vw;
  width: 40%;
  border-radius: 6px;
  background: #1A4C94;
  margin-bottom: 3vw;
}

.intro-seguridad  .intro-seguridad-text .contenido {
  font-size: 1.4vw;
  color: #606060;
  text-align: justify;
}

.intro-seguridad .intro-seguridad-img .cont-img {
  width: 30vw;
  height: 30vw;
  margin-bottom: 2vw;
}

.intro-seguridad .intro-seguridad-img .cont-img img{
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 320px) {
  .intro-seguridad {
    height: 60vw;

  }

  .intro-seguridad .cont-intro-seguridad {
    flex-flow: column;
  }

  .intro-seguridad .intro-seguridad-text {
    width: 100%;
    height: 50%;
    padding: 0;
  }

  .intro-seguridad .intro-seguridad-text .titulo {
    text-align: center;
    font-size: 4vw;
  }

  .intro-seguridad .intro-seguridad-text .barra {
    margin: 0 auto 3vw auto;
  }

  .intro-seguridad .intro-seguridad-text .contenido {
    width: 80%;
    margin: 0 auto;
    font-size: 2.2vw;
  }

  .intro-seguridad .intro-seguridad-img {
    width: 100%;
    height: 50%;
    align-items: flex-start;
    justify-content: center;
  }



}
@media only screen and (min-width: 321px) and (max-width: 480px) {
  .intro-seguridad {
    height: 60vw;

  }

  .intro-seguridad .cont-intro-seguridad {
    flex-flow: column;
  }

  .intro-seguridad .intro-seguridad-text {
    width: 100%;
    height: 50%;
    padding: 0;
  }

  .intro-seguridad .intro-seguridad-text .titulo {
    text-align: center;
    font-size: 4vw;
  }

  .intro-seguridad .intro-seguridad-text .barra {
    margin: 0 auto 3vw auto;
  }

  .intro-seguridad .intro-seguridad-text .contenido {
    width: 80%;
    margin: 0 auto;
    font-size: 2.2vw;
  }

  .intro-seguridad .intro-seguridad-img {
    width: 100%;
    height: 50%;
    align-items: flex-start;
    justify-content: center;
  }
}
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .intro-seguridad {
    height: 60vw;

  }

  .intro-seguridad .cont-intro-seguridad {
    flex-flow: column;
  }

  .intro-seguridad .intro-seguridad-text {
    width: 100%;
    height: 50%;
    padding: 0;
  }

  .intro-seguridad .intro-seguridad-text .titulo {
    text-align: center;
    font-size: 4vw;
  }

  .intro-seguridad .intro-seguridad-text .barra {
    margin: 0 auto 3vw auto;
  }

  .intro-seguridad .intro-seguridad-text .contenido {
    width: 80%;
    margin: 0 auto;
    font-size: 2.2vw;
  }

  .intro-seguridad .intro-seguridad-img {
    width: 100%;
    height: 50%;
    align-items: flex-start;
    justify-content: center;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  
}
@media only screen and (min-width: 1025px) and (max-width: 1199px) {
  
}
</style>

<script>
  export default {
  }
</script>