<template>
  <div class="navbar full-width">
    <div class="before-nav full-width flex-row-center row" v-scrollanimation>
      <div class="left middle flex-row-center align-row-start" v-scrollanimation>
        <a href="https://www.facebook.com/Nedetel-111757390641044" target="_blank">
          <font-awesome-icon :icon="['fab', 'facebook-square']" class="icon" />
        </a>
        <a href="https://twitter.com/NEDETEL" target="_blank">
          <font-awesome-icon :icon="['fab', 'twitter-square']" class="icon" />
        </a>
        <a href="https://www.linkedin.com/company/nedetel/" target="_blank">
          <font-awesome-icon :icon="['fab', 'linkedin']" class="icon" />
        </a>
        <span>info@nedetel.net</span>
      </div>
      <div class="right middle flex-row-center align-row-end" v-scrollanimation>
        <span class="bl">Contáctanos: </span>
        <span class="wt subtitle">+593 (4) 600 8192</span>
      </div>
    </div>    

    <div class="menu row full-width flex-row-center" v-scrollanimation> 
      <div class="icon-menu-mobile flex-row-center" @click="activeMobile">
        <font-awesome-icon :icon="['fa', 'bars']" class="icon" />
      </div>
      <div class="left flex-row-center" v-scrollanimation>
        <div class="logo" @click="goHome()">
          <img src="../assets/img/logos/logo-nedetel.png" alt="logo-nedetel" />
        </div>
      </div>
      <div class="right flex-row-center align-row-end two-seconds" v-scrollanimation>
        <div class="flex-row-center">
          <div 
            v-for="m in menu" 
            :key="m.key"
            class="flex-column-center items" 
            @click="takeToPage(m.index)" 
            :class="getSubNavItemClass(m.index)"
            @mouseover="activeSubMenu(m.index)"
            
          >
            <div class="flex-row-center">
              <div :class="m.active ? 'detail active' : 'detail'"></div>
              <span>{{ m.item }}</span>
              <font-awesome-icon v-if="m.sub.length > 0" :icon="['fas','angle-down']" class="icon" />  
            </div>

            <div 
              :class="subnavitem.active !== m.index || subnavitem.active === null ? 'son_menu flex-column-center':'son_menu active flex-column-center'"
              @mouseover="activeSubMenu(m.index)"
              @mouseleave="noActiveSubMenu()"
              v-if="subnavitem.active !== null"
            >
              <div
                :key="'sub_' + pos"
                v-for="(s, pos) in menu[subnavitem.active].sub"
                class="son-item flex-row-center"
                @click="moveToSubSection(pos)"
              >
                <span>{{ s }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="buttons flex-row-center">
          <div 
            v-for="b in buttons" 
            :key="b.key" 
            class="button" 
            :style="getClassButtons(b.background, b.color, b.index)"
            @click="goToWindow(b.url)"
          >
            <span>{{ b.item }}</span>
          </div>
        </div>
        
      </div>
    </div>










    <div :class="activemm ? 'menu-mobile flex-column-center active': 'menu-mobile flex-column-center'">

      <div class="close-menu-mobile flex-column-center" @click="closeMobile">
        <font-awesome-icon :icon="['fa', 'times']" class="icon" />
      </div>

      <div class="cont-menu-mobile flex-column-center">
        <div 
          v-for="m in menu" 
          :key="m.key"
          class="flex-column-center items" 
          :class="getSubNavItemClass(m.index)"
          @click="activeSubMenu(m.index)"
        >

          <div class="flex-row-center cont-option-mobile">
            <font-awesome-icon :icon="['fas',m.icon]" class="icon" />  
            <span>{{ m.item }}</span>
          </div>

          <div 
            :class="subnavitem.active !== m.index || subnavitem.active === null ? 'subnav-mobile-menu flex-column-center':'subnav-mobile-menu active flex-column-center'"
            @click="activeSubMenu(m.index)"
            v-if="subnavitem.active !== null"
          >
            <div
              :key="'sub_' + pos"
              v-for="(s, pos) in menu[subnavitem.active].sub"
              class="sub-mobile-item flex-row-center"
              @click="moveToSubSection(pos)"
            >
              <span>{{ s }}</span>
            </div>
          </div>
        </div>
      </div>



      <div class="after-nav-mobile full-width flex-column-center" v-scrollanimation>
        <div class="left middle flex-row-center align-row-start" v-scrollanimation>
          <a href="https://www.facebook.com/Nedetel-111757390641044" target="_blank">
            <font-awesome-icon :icon="['fab', 'facebook-square']" class="icon" />
          </a>
          <a href="https://twitter.com/NEDETEL" target="_blank">
            <font-awesome-icon :icon="['fab', 'twitter-square']" class="icon" />
          </a>
          <a href="https://www.linkedin.com/company/nedetel/" target="_blank">
            <font-awesome-icon :icon="['fab', 'linkedin']" class="icon" />
          </a>
        </div>
        <div class="right middle flex-column-center align-row-end" v-scrollanimation>
          <span class="bl">Contáctanos: </span>
          <span class="wt">+593 (4) 600 8192</span>
          <span class="wt">info@nedetel.net</span>
        </div>
      </div> 
    </div>



















  </div>
</template>

<script>
import { scroller } from "vue-scrollto/src/scrollTo";
export default {
  
  data: function() {
    return {
      activemm: false,
      menu: [
        {
          key: "item_0",
          index: 0,
          item: "Inicio",
          active: false,
          icon: "home",
          sub: [
            "Nedetel",
            "Soluciones",
            "Partners",
            "Trayectoria",
            "Clientes",
            "Historias de éxito",
            "Noticias",
            "Contacto"
          ]
        },
        {
          key: "item_1",
          index: 1,
          active: false,
          item: "Nosotros",
          icon: "users",
          sub: [
            "Cobertura",
            "Misión - Visión",
            "Evolución",
            // "Indicadores de Consumo"
          ]
        },
        {
          key: "item_2",
          index: 2,
          active: false,
          item: "Soluciones",
          icon: "cogs",
          sub: [
            "Conectividad",
            "Infraestructura",
            "Seguridad",
            "Nube"
          ]
        }
      ],
      subnavitem: {
        active: null,
        class: {
          item: true,
          active: false
        }
      },
      buttons: [
        {
          key: "btn_0",
          index: 0,
          active: false,
          item: "Consultar Consumo",
          background: "#1A4C94",
          color: "#fff",
          url: "http://msnetwork.nedetel.net/cacti/"
        },
        {
          key: "btn_1",
          index: 1,
          active: false,
          item: "Código Ético Grupo Ufinet",
          background: "rgb(151, 215, 0)",
          color: "rgb(26, 76, 148)",
          url: "uploads/Codigo-Etico-1.pdf"
        }
      ]
    };
  },
  methods: {
    checkMenu: function() {
      let path = this.$route.path;
      if(path === "/") {
        this.menu[0].active = true;
      } else if(path === "/nosotros") {
        this.menu[1].active = true;
      } else if(path === "/soluciones") {
        this.menu[2].active = true;
      }
    },
    getClassButtons: function(bg, cl, index) {
      if(index === 0) {
        return {  
          "background" : bg,
          "color" : cl,
          "margin-left" : 0
        }
      } else {
        return {  
          "background" : bg,
          "color" : cl,
          "margin-left": "1.2vw"
        }
      }
      
    },
    takeToPage: function(index) {
      let path = this.$route.path;
      if(index === 0 && path !== "/") {
        this.$router.push({ path: "/" });
      } else if(index === 1 && path !== "/nosotros") {
        this.$router.push({ path: "../nosotros" });
      } else if(index === 2 && path !== "/soluciones") {
        this.$router.push({ path: "../soluciones" });
      }
    },
    activeSubMenu(index) {
      this.subnavitem.active = index;
      this.subnavitem.class.active = true;

    },
    noActiveSubMenu() {
      this.subnavitem.class.active = false;
      this.subnavitem.active = null;
    },
    getSubNavItemClass(index) {
      return {
        item: true,
        active_submenu: this.subnavitem.active == index
      };
    },
    goHome: function(){
      this.$router.push({ path: "/" });
    },
    moveToSubSection: function(index){
      let path = this.$route.path;
      let page = this.subnavitem.active;
      if (page === 0) {
        if (path === "/") {
          //Emitir
          this.$emit("updateSection", index); 
          this.closeMobile();
        } else {
          //redireccionar a la ruta with section
          this.$router.push({ path: "/", query: { section: index } });
        }
      } else if (page === 1) {
        if (path === "/nosotros") {
          //Emitir
          this.$emit("updateSection", index); 
          this.closeMobile();
        } else {
          //redireccionar a la ruta with section
          this.$router.push({ path: "/nosotros", query: { section: index } });
        }
      } else if (page === 2) {
        if (path === "/soluciones") {
          //Emitir
          this.$emit("updateSection", index); 
          this.closeMobile();
        } else {
          //redireccionar a la ruta with section
          this.$router.push({ path: "/soluciones", query: { section: index } });
        }
      }
    },
    goToWindow: function(url) { 
      window.open(url, '_blank');
    },
    activeMobile: function() {
      this.activemm = true;
    },
    closeMobile: function() {
      this.activemm = false;
    }
  },
  beforeMount: function() {
    this.checkMenu();
  }
};
</script>

<style>
.navbar {
  width: 100%;
  position: relative;
  height: auto;
  font-family: unisans_bold;
  letter-spacing: 0.02px;
}

/* Barra de informacion */

.navbar .before-nav {
  height: 4vw;
  background: #1A4C94;
  color: #fff;
  position: relative;
  transition-delay: .1s;
}

.navbar .before-nav a {
  width: 1.5vw;
  height: 1.5vw;
  margin-right: 1vw;
  color: #fff;
  text-decoration: none;
  transform: scale(0.96);
  transition-delay: .2s;
  transition: all 0.1s 0.1s;
}

.navbar .before-nav a:hover {
  cursor: pointer;
  transform: scale(1.3);
  transition: all 0.1s 0.1s;
}

.navbar .before-nav a .icon {
  
  width: 100%;
  height: 100%;
}

.navbar .before-nav span {
  font-size: 1vw;
  transition-delay: .2s;
}

.navbar .before-nav .right .bl {
  text-transform: uppercase;
  color: rgb(219, 148, 52);
  margin-right: 0.8vw;
}


/* Menu de opciones */
.navbar .menu {
  height: 10vw;
  /* position: absolute;
  top: 4vw; */
}

.navbar .menu .left {
  width: 30%;
  height: 100%;
}

.navbar .menu .left .logo {
  width: 12vw;
  height: 3vw;
}

.navbar .menu .left .logo:hover {
  cursor: pointer;
}

.navbar .menu .left .logo img {
  width: 100%;
  height: 100%;
  display: block;
  margin: 0 auto;
}

.navbar .menu .right {
  width: 70%;
  height: 100%;

}

.navbar .menu .right .items {
  font-size: 1.4vw;
  text-transform: uppercase;
  color: #1A4C94;
  margin-right: 2vw;
  padding: 0.5vw 0 0.2vw 0;
  position: relative;
}

.navbar .menu .right .items:hover {
  cursor: pointer;
}

.navbar .menu .right .son_menu.active {
  display: flex;
  opacity: 1;
  visibility: visible;
  z-index: 5;
  transition: all 0.3s 0.3s ease-in-out;
}
.navbar .menu .right .son_menu {
  width: 20vw;
  height: auto;
  padding: 1vw;
  background: #1A4C94;
  position: absolute;
  color: #fff;
  top: 3vw;
  left: 0;
  border-radius: 4px;

  display: none;
  opacity: -1;
  visibility: hidden;
  z-index: -2;
  transition: all 0.3s 0.3s ease-in;
}

.navbar .menu .right .son_menu .son-item {
  align-items: center;
  padding: 0.85vw 0.5vw 0.5vw 0.5vw;
  width: calc(100% - 1vw);
  border-radius: 4px;
  transition: all 0.1s 0.1s ease-in;
}

.navbar .menu .right .son_menu .son-item:hover {
  background: #fff;
  color: #1A4C94;
  transition: all 0.1s 0.1s ease-in-out;  
}

.navbar .menu .right .items .detail {
  position: absolute;
  bottom: 0;
  height: 0.3vw;
  background: #1A4C94;
  width: 100%;
  z-index: -2;
  opacity: 0;
  visibility: hidden;
  transition: all 0.1s 0.1s ease-in;
}

.navbar .menu .right .items .detail.active {
  opacity: 1;
  z-index: 0;
  visibility: visible;
  transition: all 0.1s 0.1s  ease-in-out;
}

.navbar .menu .right .items .icon {
  margin-left: 0.2vw;
}

.navbar .menu .right .items.active {
  border-bottom: solid #1A4C94 0.2vw;
  padding-top: calc(0.76vw - 1px);
}

.navbar .menu .right .buttons {
}

.navbar .menu .right .buttons .button {
  text-transform: uppercase;
  padding: 0.7vw 0 0.5vw 0;
  border-radius: 1.5vw;
  width: 12vw;
  text-align: center;
  transform: scale(0.98);
  transition: all 0.1s 0.1s ease-in;
}

.navbar .menu .right .buttons .button:hover {
  cursor: pointer;
  transform: scale(1);
  transition: all 0.1s 0.1s ease-in-out;
}

.navbar .menu .right .buttons .button span {
  width: auto;
  margin: 0 auto;
  display: block;
  font-size: 0.85vw;
}


/* Menu Móvil */

.navbar .icon-menu-mobile {
  display: none;
  justify-content: center;
  align-items: center;
  width: 4vw;
  height: 100%;
}

.navbar .icon-menu-mobile:active {
  transform: scale(0.96);
  transition: all 0.1s 0.1s ease-in-out;
}

.navbar .icon-menu-mobile .icon { 
  font-size: 3vw;
  color: #1A4C94; 
}

.navbar .menu-mobile {
  background: #1A4C94;
  max-width: calc(40% - 8vw);
  width: calc(40% - 8vw);
  padding: 2vw 4vw;
  height: calc(100vh - 4vw);
  max-height: calc(100vh - 4vw);
  position: absolute;
  top: 0;
  left: 0;

  justify-content: flex-start;

  opacity: 0;
  visibility: hidden;
  z-index: -999;
  transform: translateX(-100%);

  transition: all 0.3s 0.3s ease-in;
  
}

.navbar .menu-mobile.active {
  display: flex;

  opacity: 1;
  visibility: visible;
  z-index: 9999;
  transform: translateX(0%);

  transition: all 0.3s 0.3s ease-in-out;
  
}

.navbar .menu-mobile .close-menu-mobile { 
  width: 100%;
  height: 6vw;
  align-items: flex-end;
  position: relative;
}

.navbar .menu-mobile .close-menu-mobile:active {
  transform: scale(0.96);
  transition: all 0.1s 0.1s ease-in-out;
}

.navbar .menu-mobile .close-menu-mobile .icon { 
  font-size: 3vw;
  color: #fff; 
}

.navbar .menu-mobile .cont-menu-mobile {
  margin-top: 4vw;
  height: auto;
  position: relative;
  justify-content: flex-start;
}

.navbar .menu-mobile .cont-menu-mobile .items { 
  width: calc(100% - 2vw);
  height: auto;
  padding: 0 1vw;
  margin-bottom: 2.5vw;
}

.navbar .menu-mobile .cont-menu-mobile .items .cont-option-mobile { 
  width: 100%;
  height: 100%;
  color: #fff;
  align-items: center;
}

.navbar .menu-mobile .cont-menu-mobile .items .cont-option-mobile .icon { 
  font-size: 2.5vw;
  width: 2.5vw;
  height: 100%;
}

.navbar .menu-mobile .cont-menu-mobile .items .cont-option-mobile span { 
  font-size: 2.5vw;
  width: calc(100% - 6.5vw);
  vertical-align: middle;
  align-items: center;
  padding-top: 0.7vw;
  margin-left: 1.5vw;
}



.navbar .menu-mobile .subnav-mobile-menu.active {
  display: flex;
  opacity: 1;
  visibility: visible;
  z-index: 5;
  transform: translateY(0%);
  transition: all 0.6s 0.6s ease-in-out;
}
.navbar .menu-mobile .subnav-mobile-menu {
  width: calc(90% - 3vw);
  height: auto;
  margin-bottom: 2vw;
  padding: 1.5vw 1.5vw 1.5vw 3.5vw;
  background: #1A4C94;
  position: relative;
  color: #fff;
  top: 2vw;
  left: 0;
  border-radius: 2px;

  display: none;
  opacity: -1;
  visibility: hidden;
  z-index: -2;
  transform: translateY(-100%);
  transition: all 0.6s 0.6s ease-in;
}

.navbar .menu-mobile .subnav-mobile-menu .sub-mobile-item {
  align-items: center;
  padding: 0.85vw 0.5vw 0.5vw 0.5vw;
  width: calc(100% - 1vw);
  border-radius: 4px;
  transition: all 0.1s 0.1s ease-in;
}

.navbar .menu-mobile .subnav-mobile-menu .sub-mobile-item:active {
  transform: scale(0.96);
  transition: all 0.1s 0.1s ease-in-out;
}

.navbar .menu-mobile .subnav-mobile-menu .sub-mobile-item span {
  font-size: 2.5vw;
}

.navbar .menu-mobile .after-nav-mobile {
  height: auto;
  width: 100%;
}

.navbar .menu-mobile .after-nav-mobile .left {
  width: 100%;
  height: 50%;
  margin-bottom: 1.5vw;
}

.navbar .menu-mobile .after-nav-mobile .left a {
  width: 4vw;
  height: 100%;
  margin-left: 1vw;
}

.navbar .menu-mobile .after-nav-mobile .left a .icon {
  font-size: 4vw;
  height: 100%;
  margin: 0 auto;
  color: #fff;
}

.navbar .menu-mobile .after-nav-mobile .left a .icon:active {
  color: rgb(219, 148, 52);
}

.navbar .menu-mobile .after-nav-mobile .right {
  width: 100%;
  height: 50%;
  justify-content: flex-start;
}

.navbar .menu-mobile .after-nav-mobile .right span {
  color: #fff;
  font-size: 2.5vw;
  flex-flow: column;
  margin-bottom: 0.8vw;
  padding-left: 1.4vw;
  width: calc(100% - 1.4vw);
}

.navbar .menu-mobile .after-nav-mobile .right span.wt {
  padding-left: 4vw;
  width: calc(100% - 4vw);
}




@media only screen and (max-width: 320px) {
  .navbar .icon-menu-mobile {
    display: flex;
  }

  .navbar .before-nav { 
    display: none;
  }
  .navbar .menu {
    justify-content: center;
    align-items: center;
  }
  .navbar .menu .left {

    width: 100%;
  }

  .navbar .menu .left .logo {
    margin: 0 auto 0 auto;
    width: 22vw;
    height: 6vw;
  }

  .navbar .menu .right {
    display: none;
  }
  
}
@media only screen and (min-width: 321px) and (max-width: 480px) {
  .navbar .icon-menu-mobile {
    display: flex;
  }

  .navbar .before-nav { 
    display: none;
  }
  .navbar .menu {
    justify-content: center;
    align-items: center;
  }
  .navbar .menu .left {

    width: 100%;
  }

  .navbar .menu .left .logo {
    margin: 0 auto 0 auto;
    width: 22vw;
    height: 6vw;
  }

  .navbar .menu .right {
    display: none;
  }

  .navbar .menu-mobile .cont-menu-mobile .items .cont-option-mobile .icon {
    font-size: 4vw;
    width: 4vw;
  }

  .navbar .icon-menu-mobile .icon { 
    font-size: 5vw;
  }

  .navbar .menu-mobile .cont-menu-mobile .items .cont-option-mobile span { 
    font-size: 3.5vw;
  }

  .navbar .menu-mobile .subnav-mobile-menu .sub-mobile-item span {
    font-size: 3.5vw;
  }

  .navbar .menu-mobile .after-nav-mobile .right span {
    font-size: 3.5vw;
  }

  .navbar .menu-mobile .after-nav-mobile .right span.wt {
    font-size: 2.8vw;
  }
}
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .navbar .icon-menu-mobile {
    display: flex;
  }

  .navbar .before-nav { 
    display: none;
  }
  .navbar .menu {
    justify-content: center;
    align-items: center;
  }
  .navbar .menu .left {

    width: 100%;
  }

  .navbar .menu .left .logo {
    margin: 0 auto 0 auto;
    width: 20vw;
    height: 6vw;
  }

  .navbar .menu .right {
    display: none;
  }

  .navbar .menu-mobile .cont-menu-mobile .items .cont-option-mobile .icon {
    font-size: 4vw;
    width: 4vw;
  }

  .navbar .icon-menu-mobile .icon { 
    font-size: 5vw;
  }

  .navbar .menu-mobile .cont-menu-mobile .items .cont-option-mobile span { 
    font-size: 4vw;
  }

  .navbar .menu-mobile .subnav-mobile-menu .sub-mobile-item span {
    font-size: 4vw;
  }

  .navbar .menu-mobile .after-nav-mobile .right span {
    font-size: 4vw;
  }
  .navbar .menu-mobile .after-nav-mobile .right span.wt {
    font-size: 2.8vw;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  
}
@media only screen and (min-width: 1025px) and (max-width: 1199px) {
}



</style>

