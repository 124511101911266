<template>
  <div class="trayectoria row" v-scrollanimation>
    <div class="cont-trayectoria flex-row-center">
      <div class="middle a flex-column-center">

        <span class="title_section uno" v-scrollanimation>NEDETEL</span>
        <span class="title_section dos" v-scrollanimation>+ 100 PoPs</span>
        <span class="content tres" v-scrollanimation>a nivel nacional</span>

      </div>
      <div class="middle b">
        <div class="fondo one-second" v-scrollanimation></div>

        <div class="cont-slider flex-column-center align-column-center">
          <div class="slider two-seconds" v-scrollanimation>
            <div 
              :key="s.key"
              v-for="s in slide.slides.items"
              :class="getClassSlideItem(s.index)"
            >
              <div
                :key="'item_' + pos"
                v-for="(i, pos) in s.item"
                :class="getClassItem()"
                v-show="i !== null"
              >
                <div class="cont-img">
                  <img :src="i.url" v-if="i !== null"/>
                </div>
              </div>
            </div>
          </div>
          <div class="buttons" v-scrollanimation>
            <div
              :key="btn.key"
              v-for="btn in slide.slides.buttons"
              :class="getClassButton(btn.index)"
              @click="changeActiveSection(btn.index)"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import try1 from "../../assets/img/home/try1.png";
import try2 from "../../assets/img/home/try2.png";
import try3 from "../../assets/img/home/try3.png";
import try4 from "../../assets/img/home/try4.png";


export default {
  data: function() {
    return {
      it: [
        {
          url: try1
        },
        {
          url: try2
        },
        {
          url: try3
        },
        {
          url: try4
        }
      ],
      slide: {
        size: 0,
        slides: {
          active: 0,
          buttons: [],
          items: []
        }
      }
    };
  },
  beforeMount: function() {
    this.loadItems();
  },
  methods: {
    getClassSlideItem: function(index) {
      return {
        slide: true,
        active: this.slide.slides.active === index
      };
    },
    getClassItem: function() {
      return {
        item: true
      };
    },
    getClassButton: function(index) {
      return {
        shadow: true,
        button: true,
        active: this.slide.slides.active === index
      };
    },
    changeActiveSection: function(index) {
      this.slide.slides.active = index;
    },
    loadItems:  function() {
      this.slide.size = this.it.length;
      let indices = 0;
      for (let i = 0; i < this.slide.size; i += 1) {
        let aux = {
          key: "slide_" + i,
          index: indices,
          item: []
        };
        for (let j = 0; j < 1; j++) {
          aux.item.push(
            i + j < this.slide.size ? this.it[i + j] : null
          );
        }
        this.slide.slides.items.push(aux);
        indices += 1;
      }
      for (let j = 0; j < this.slide.slides.items.length; j++) {
        this.slide.slides.buttons.push({
          key: "button_" + j,
          index: j
        });
      }
    }
  }
}
</script>

<style>
.trayectoria {
  height: 45vw;
  position: relative;
}

.trayectoria .cont-trayectoria {
  width: 100%;
  height: 100%;
}

.trayectoria .cont-trayectoria .a {
  align-items: center;
  padding: 0 0 0 4vw;
  width: calc(50% - 4vw);
}

.trayectoria .cont-trayectoria .a span {
  display: block;
  width: 100%;
  text-align: left;
}

.trayectoria .cont-trayectoria .a span.uno {
  color: #0D2C7C;
  font-size: 3vw;
  margin-bottom: 1vw;
}

.trayectoria .cont-trayectoria .a span.dos {
  color: #606060;
  font-size: 3.6vw;
  margin-bottom: -1vw;
}

.trayectoria .cont-trayectoria .a span.tres {
  color: #606060;
  font-size: 2.4vw;
  font-family: unisans_regular;
}

.trayectoria .cont-trayectoria .b {
  position: relative;
}

.trayectoria .cont-trayectoria .b .fondo {
  position: absolute;
  left: -12vw;
  top: 4vw;
  width: 90%;
  height: 85%;
  z-index: -2;

  background-image: url("../../assets/img/home/fondo-mapa.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.trayectoria .cont-trayectoria .b .cont-slider {
  width: 100%;
  height: 100%;
  align-items: flex-end;
  position: relative;
  z-index: 2;
}

.trayectoria .cont-trayectoria .b .cont-slider .slider {
  position: relative;
  width: 90%;
  height: 30vw;
}

.trayectoria .cont-trayectoria .b .cont-slider .slider .slide {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row wrap;

  position: absolute;
  z-index: -1;
  opacity: 0;
  transition: all 0.3s 0.3s ease;
}

.trayectoria .cont-trayectoria .b .cont-slider .slider .slide.active {
  z-index: 1;
  opacity: 1;
  transition: all 0.3s 0.3s ease;
}

.trayectoria .cont-trayectoria .b .cont-slider .slider .slide .item {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  transition: all 0.1s 0.1s;
}
.trayectoria .cont-trayectoria .b .cont-slider .slider .slide .item .cont-img {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.trayectoria .cont-trayectoria .b .cont-slider .slider .slide .item .cont-img img {
  width: 100%;
  height: 100%;
}

.trayectoria .cont-trayectoria .b .cont-slider .buttons {
  width: 90%;
  height: 2vw;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
}
.trayectoria .cont-trayectoria .b .cont-slider .buttons .button {
  background: #f2f2f2;
  width: 0.9vw;
  height: 0.9vw;
  border-radius: 50%;
  margin: 0 0.2vw;
  cursor: pointer;
  transition: all 0.3s 0.3s ease;
}
.trayectoria .cont-trayectoria .b .cont-slider .buttons .button:active {
  transform: scale(0.98);
}
.trayectoria .cont-trayectoria .b .cont-slider .buttons .button.active {
  background: #0D2C7C;;
  width: 1vw;
  height: 1vw;
  transition: all 0.3s 0.3s ease;
}


@media only screen and (max-width: 320px) {
  .trayectoria .cont-trayectoria .a span.uno {
    font-size: 5vw;
  }

  .trayectoria .cont-trayectoria .a span.dos {
    font-size: 5.6vw;
  }

  .trayectoria .cont-trayectoria .a span.tres {
    font-size: 4.4vw;
  }

  .trayectoria .cont-trayectoria .b .cont-slider .slider {
    height: 36vw;
  }
}
@media only screen and (min-width: 321px) and (max-width: 480px) {
  .trayectoria .cont-trayectoria .a span.uno {
    font-size: 5vw;
  }

  .trayectoria .cont-trayectoria .a span.dos {
    font-size: 5.6vw;
  }

  .trayectoria .cont-trayectoria .a span.tres {
    font-size: 4.4vw;
  }
  .trayectoria .cont-trayectoria .b .cont-slider .slider {
    height: 36vw;
  }
}
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .trayectoria .cont-trayectoria .a span.uno {
    font-size: 5vw;
  }

  .trayectoria .cont-trayectoria .a span.dos {
    font-size: 5.6vw;
  }

  .trayectoria .cont-trayectoria .a span.tres {
    font-size: 4.4vw;
  }
  .trayectoria .cont-trayectoria .b .cont-slider .slider {
    height: 36vw;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .trayectoria .cont-trayectoria .a span.uno {
    font-size: 4vw;
  }

  .trayectoria .cont-trayectoria .a span.dos {
    font-size: 4.6vw;
  }

  .trayectoria .cont-trayectoria .a span.tres {
    font-size: 3.4vw;
  }
  .trayectoria .cont-trayectoria .b .cont-slider .slider {
    height: 36vw;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1199px) {
  .trayectoria .cont-trayectoria .a span.uno {
    font-size: 4vw;
  }

  .trayectoria .cont-trayectoria .a span.dos {
    font-size: 4.6vw;
  }

  .trayectoria .cont-trayectoria .a span.tres {
    font-size: 3.4vw;
  }
  .trayectoria .cont-trayectoria .b .cont-slider .slider {
    height: 36vw;
  }
}

</style>