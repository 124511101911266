<template>

  <div class="noticias row" v-scrollanimation>

    <div class="section flex-row-center align-row-center" v-scrollanimation>
      <div class="titulos flex-row-center align-row-center">
        <span class="title_section b" v-scrollanimation>Noticias</span>
        <!-- <span class="title_section a" v-scrollanimation>Leer más</span> -->
      </div>  
    </div>

    <div class="cont-slider flex-row-center align-row-center">
      <div class="caja">
        <div class="news">
          <div class="arrow_left" v-scrollanimation>
            <font-awesome-icon
              :icon="['fas', 'arrow-left']"
              :class="btn_left"
              @click="changeSectionActiveLeft"
            />
          </div>
          <div class="arrow_right" v-scrollanimation>
            <font-awesome-icon
              :icon="['fas', 'arrow-right']"
              :class="btn_right"
              @click="changeSectionActiveRight"
            />
          </div>
          <div class="slider shadow" v-scrollanimation>
            <div
              :key="s.index"
              v-for="s in slides"
              :class="getClassSlide(s.index)"
            >
              <div
                v-if="s.a != null"
                class="item a"
              >
                <div class="cont-img flex-row-center align-row-center">
                  <img src="../../assets/img/home/ntc1.png" alt="nedetel-noticia" />
                </div>
                <div class="resumen">
                  <span>{{s.a.titulo}}</span>
                </div>

                <!-- <span class="link title_section">{{s.a.date}}</span> -->

              </div>
              <div
                v-if="s.b != null"
                class="item b"
              >
                <div class="cont-img flex-row-center align-row-center">
                  <img src="../../assets/img/home/ntc1.png" alt="nedetel-noticia" />
                </div>
                <div class="resumen">
                  <span>{{s.b.titulo}}</span>
                </div>

                <!-- <span class="link title_section">{{s.b.date}}</span> -->

              </div>
              <div
                v-if="s.c != null"
                class="item c"
              >
                <div class="cont-img flex-row-center align-row-center">
                  <img src="../../assets/img/home/ntc2.png" alt="nedetel-noticia" />
                </div>
                <div class="resumen">
                  <span>{{s.c.titulo}}</span>
                </div>

              
                <!-- <span class="link title_section">{{s.c.date}}</span> -->

              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data: function() {
    return {
      news: [
        {
          titulo: "Nedetel, escala 288 posiciones en el ranking de las 1000 empresas más importantes del Ecuador",
          date: "02 de Octubre, 2020"
        },
        {
          titulo: "Nedetel escaló 1513 posiciones en el ranking de los Sistemas Autónomos del mundo.",
          date: "02 de Octubre, 2020"
        },
        {
          titulo: "ExpoFeria Virtual Nedetel-Zc Mayoristas Noviembre 2020.",
          date: "02 de Octubre, 2020"
        }
      ],
      size: -1,
      slides: [],
      index_activo: 0,
      btn_left: {
        icon: true,
        shadow: true,
        active: false
      },
      btn_right: {
        icon: true,
        shadow: true,
        active: true
      }
    };  
  },
  beforeMount: function() {
    this.loadNews();
  },
  methods: {
    getClassSlide(index) {
      return {
        slide: true,
        active: this.index_activo == index ? true : false

      };
    },
    changeSectionActiveLeft() {
      if (this.size > 1 && this.index_activo > 0) {
        this.btn_left.active = true;
        this.btn_right.active = true;
        this.index_activo -= 1;
        if (this.index_activo == 0) {
          this.btn_left.active = false;
        }
      } else {
        this.btn_left.active = false;
      }
    },
    changeSectionActiveRight() {
      if (this.size > 1 && this.index_activo < this.size - 1) {
        this.btn_left.active = true;
        this.btn_right.active = true;
        this.index_activo += 1;
        if (this.index_activo == this.size - 1) {
          this.btn_right.active = false;
        }
      } else {
        this.btn_right.active = false;
      }
    },

    loadNews: function() {
      let cont = 0;
      for (let i = 0; i < this.news.length; i += 3) {
        this.slides.push({
          index: cont,
          a: i < this.news.length ? this.news[i] : null,
          b: i + 1 < this.news.length ? this.news[i + 1] : null,
          c: i + 2 < this.news.length ? this.news[i + 2] : null
        });
        cont += 1;
      }
      this.size = cont;
      this.btn_right.active = this.size > 1 ? true : false;
    }
  }
}
</script>


<style>

.noticias {
  position: relative;
  background: #1A4C94;
  height: 65vw;
}

.noticias .section {
  height: 14vw;
  padding-top: 8vw;
  width: 100%;
  position: relative;
}
.noticias .section .titulos {
  position: relative;
  width: 100%;
  height: 100%;
} 

.noticias .section .titulos span {
  position: absolute;
  width: 45vw;
  text-align: center;
  font-size: 4vw;
  line-height: 9vw;
  letter-spacing: 0.2vw;
}

.noticias .section span.b {
  opacity: 0.7;
  color: #fff;
  font-size: 8vw;
  z-index: 1;
  top: 3.5vw;
  text-transform: uppercase;
}

.noticias .section span.a {
  display: block;
  font-size: 2.4vw;
  color: #fff;
}

.noticias .cont-slider {
  height: 26vw;
  position: relative;
}

.noticias .caja {
  position: absolute;
  height: 22vw;
  z-index: 50;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  margin: 4vw auto;
  display: flex;
  flex-flow: row;
  justify-content: center;
}

.noticias .news {
  position: relative;
  width: calc((100% / 4) * 4);
}
.noticias .news .arrow_left {
  position: absolute;
  z-index: 10;
  left: -2vw;
  height: 100%;
  width: 4vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.noticias .news .arrow_left .icon {
  background: #f5f5f5;
  color: #1A4C94;
  padding: 0.5vw;
  border-radius: 50%;
  cursor: pointer;
  display: none;
  z-index: -999;
  visibility: hidden;
  opacity: 0;
  transition: all 0.6s 0.6s ease-in;
}
.noticias .news .arrow_left .icon.active {
  display: block;
  z-index: 999;
  visibility: visible;
  opacity: 1;
  background: #f5f5f5;
  color: #1A4C94;
  transition: all 0.6s 0.6s ease-in-out;
}
.noticias .news .arrow_left .icon:active {
  transform: scale(0.96);
}
.noticias .news .arrow_right {
  position: absolute;
  z-index: 10;
  right: -2vw;
  height: 100%;
  width: 4vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.noticias .news .arrow_right .icon {
  background: #f5f5f5;
  color: #1A4C94;
  padding: 0.5vw;
  font-size: 0.9vw;
  border-radius: 50%;
  cursor: pointer;
  display: none;
  z-index: -999;
  visibility: hidden;
  opacity: 0;
  transition: all 0.6s 0.6s ease-in;
}
.noticias .news .arrow_right .icon.active {
  display: block;
  z-index: 999;
  visibility: visible;
  opacity: 1;
  background: #f5f5f5;
  color: #1A4C94;
  transition: all 0.6s 0.6s ease-in-out;
}
.noticias .news .arrow_right .icon:active {
  transform: scale(0.96);
}
.noticias .news .slider {
  height: 100%;
  position: relative;
  display: flex;
  flex-flow: row;
}
.noticias .news .slider .slide {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row;
  z-index: -1;
  opacity: 0;
  transition: all 0.3s 0.3s ease-in;
}
.noticias .news .slider .slide.active {
  z-index: 1;
  opacity: 1;
  transition: all 0.3s 0.3s ease-in-out;
}
.noticias .news .slider .slide .item {
  position: relative;
  overflow: hidden;
  width: calc(100% / 3);
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}
.noticias .news .slider .slide .item.a,
.noticias .news .slider .slide .item.b,
.noticias .news .slider .slide .item.c {
  width: calc((100% / 3) - 1.5px);
  margin: 0 1.5px;
}
.noticias .news .slider .slide .item.c {
  border-right: none !important;
}

.noticias .news .slider .slide .item .cont-img { 
  width: calc(7vw - 0.3vw);
  height: calc(7vw - 0.3vw);
}

.noticias .news .slider .slide .item .cont-img img { 
  width: calc(100% - 0.3vw);
  height: calc(100% - 0.3vw);
  border-radius: 50%;
  border: 0.3vw solid #DB9434;
  display: block;
  margin: 0 auto;
}

.noticias .news .slider .slide .item .resumen { 
  width: calc(100% - 4vw);
  height: 6vw;
  overflow: scroll;
  padding: 0 2vw;
  margin-top: 1.5vw;
}

.noticias .news .slider .slide .item .resumen span { 
  display: block;
  color: #fff;
  text-align: justify;
  width: 100%;
  height: 100%;
}

.noticias .news .slider .slide .item .link { 
  display: block;
  width: calc(100% - 2vw);
  margin-left: 2vw;
  color: #DB9434;
  text-transform: uppercase;
}


@media only screen and (max-width: 320px) {
  .noticias .section span.a {
    font-size: 4vw;
  }

  .noticias .section span.b {
    font-size: 12vw;
  }

  .noticias .news .slider .slide .item .resumen {
    height: 10vw;
  }

  .noticias .news .slider .slide .item .resumen span {
    font-size: 1.6vw;
  }
  
}
@media only screen and (min-width: 321px) and (max-width: 480px) {
  .noticias .section span.a {
    font-size: 4vw;
  }

  .noticias .section span.b {
    font-size: 12vw;
  }
  .noticias .news .slider .slide .item .resumen {
    height: 10vw;
  }
  .noticias .news .slider .slide .item .resumen span {
    font-size: 1.6vw;
  }
}
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .noticias .section span.a {
    font-size: 4vw;
  }

  .noticias .section span.b {
    font-size: 12vw;
  }
  .noticias .news .slider .slide .item .resumen {
    height: 10vw;
  }
  .noticias .news .slider .slide .item .resumen span {
    font-size: 1.6vw;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .noticias .section span.a {
    font-size: 4vw;
  }

  .noticias .section span.b {
    font-size: 12vw;
  }
  .noticias .news .slider .slide .item .resumen {
    height: 10vw;
  }
  .noticias .news .slider .slide .item .resumen span {
    font-size: 1.6vw;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1199px) {
  .noticias .section span.a {
    font-size: 4vw;
  }

  .noticias .section span.b {
    font-size: 12vw;
  }
  .noticias .news .slider .slide .item .resumen {
    height: 10vw;
  }
  .noticias .news .slider .slide .item .resumen span {
    font-size: 1.6vw;  
  }
}



</style>