<template>
  <div class="soluciones_nedetel">
    <NavBar @updateSection="updateSection"></NavBar>
    <Portada></Portada>
    <div id="div_to_scrool" class="div_to_scrool"></div>
    <Sections :section_active="section_active"></Sections>
  </div>
</template>

<style>
.div_to_scrool {
  position: relative;
  top: -18vw;
}
</style>

<script>
import NavBar from "../components/Navbar";
import Portada from "../components/soluciones/Portada";
import Sections from "../components/soluciones/Sections";
import { scroller } from "vue-scrollto/src/scrollTo";
export default {
  name: "Soluciones",
  data: function() {
    return {
      section_active: 0
    };
  },
  props: {
    section: {
      type: Number,
      default: 0
    }
  },
  components: {
    NavBar,
    Portada,
    Sections
  },
  beforeMount: function() {
    this.section_active = this.section ? this.section : 0;
  },
  mounted() {
    if (this.section >= 0) {
      let scrool = scroller();
      scrool("#div_to_scrool");
    }
  },
  methods: {
    updateSection: function(index) {
      let scrool = scroller();
      scrool("#div_to_scrool");
      this.section_active = index;
    }
  }
};
</script>