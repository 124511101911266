<template>
  <div class="indicadores row" v-scrollanimation>
    <div class="section">
      <span class="title_section a" v-scrollanimation>Indicadores de Consumo</span>
    </div>

    <div class="subtitle intro-indicadores" v-scrollanimation>
      <span
        class="subtitle info"
        v-scrollanimation
      >
        Ser líderes en brindar cobertura de portadores e internet a nivel nacional, además de innovar y generar una mejor asistencia íntegra y personalizada con nuestros clientes, para establecer una mejora continua de nuestros servicios.
      </span>
    </div>

    <div class="cont-graphs" v-scrollanimation>
      <div class="menu-graphs flex-row-center align-row-center">
        <div class="buttons flex-row-center align-row-center">
          <div
            class="button flex-column-center"
            :key="btn.key"
            v-for="btn in menu.buttons"
            :class="getButtonGraphClass(btn.key)"
            @click="changeGraphActive(btn.key)"
          >
            <span class="titulo-button title_section">{{ btn.title }}</span>
            <font-awesome-icon :icon="['fas', 'angle-double-up']" class="icon" />
          </div>
        </div>
      </div>
      <div class="graphs" v-scrollanimation>
        <div :class="getSlideClass(0)" v-scrollanimation>
          <Oneind></Oneind>
        </div>
        <div :class="getSlideClass(1)" v-scrollanimation>
          <Twoind></Twoind>
        </div>
        <div :class="getSlideClass(2)" v-scrollanimation>
          <Threeind></threeind>
        </div>
        <div :class="getSlideClass(3)" v-scrollanimation>
          <Fourind></Fourind>
        </div>
        <div :class="getSlideClass(4)">
          <Fiveind></Fiveind>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Oneind from "./subcomponents/Oneind";
import Twoind from "./subcomponents/Twoind";
import Threeind from "./subcomponents/Threeind";
import Fourind from "./subcomponents/Fourind";
import Fiveind from "./subcomponents/Fiveind"; 
export default {
  data: function() {
    return {
      efecto: 500,
      menu: {
        active: 0,
        size: 4,
        buttons: [
          {
            key: 0,
            title: "2016"
          },
          {
            key: 1,
            title: "2017"
          },
          {
            key: 2,
            title: "2018"
          },
          {
            key: 3,
            title: "2019"
          },
          {
            key: 4,
            title: "2020"
          }
        ]
      }
    };
  },
  methods: {
    getButtonGraphClass(key) {
      return {
        button: true,
        active: this.menu.active == key ? true : false,
        last: this.menu.size == key + 1 ? true : false
      };
    },
    changeGraphActive(key) {
      this.menu.active = key;
    },

    getSlideClass(key) {
      return {
        info_graphs: true,
        active: this.menu.active == key ? true : false,
        typea: true
      };
    }
  },
  components: {
    Oneind,
    Twoind,
    Threeind,
    Fourind,
    Fiveind
  }
};
</script>

<style>
.indicadores {
  position: relative;
  background: #f0f0f0;
  height: auto;
}

.indicadores .section {
  width: 100%;
  text-align: center;
  color: #1a4c94;
  padding-top: 8vw;
}

.indicadores .section span.a {
  display: block;
  font-size: 2.4vw;
  text-transform: uppercase;
}

.indicadores .intro-indicadores {
  display: block;
  font-size: 1.5vw;
  color: #606060;
  padding-top: 2vw;
  width: 75%;
  margin: 1vw auto 0 auto;
  text-align: justify;
}

.indicadores .cont-graphs {
  height: auto;
  width: 100%;
  padding: 4vw 0 8vw 0;
  position: relative;
}

.indicadores .cont-graphs .menu-graphs {
  width: 60%;
  height: auto;
  margin: 0 auto 2vw auto;
  display: block;
}

.indicadores .cont-graphs .menu-graphs .buttons {
  position: relative;
  height: 100%;
  width: 100%;
}

.indicadores .cont-graphs .menu-graphs .buttons .button {
  position: relative;
  width: calc(100% / 5);
  align-items: center;
  height: 100%;
  cursor: pointer;
  transition: all 0.3s 0.3s ease-in;
}

.indicadores .cont-graphs .menu-graphs .buttons .button.active {
  height: 100%;
  transition: all 0.3s 0.3s ease-in-out;
}

.indicadores .cont-graphs .menu-graphs .buttons .button .titulo-button {
  color: #606060;
  font-size: 2vw;
  height: 4vw;
  transition: all 0.3s 0.3s ease-in;
}

.indicadores .cont-graphs .menu-graphs .buttons .button .icon {
  opacity: 0;
  visibility: hidden;
  font-size: 1.8vw;
  color: #1a4c94;
  transition: all 0.3s 0.3s ease-in;
}

.indicadores .cont-graphs .menu-graphs .buttons .button.active .titulo-button {
  color: #1a4c94;
  font-size: 4vw;
  height: 4vw;
  transition: all 0.3s 0.3s ease-in-out;
}

.indicadores .cont-graphs .menu-graphs .buttons .button.active .icon {
  opacity: 1;
  visibility: visible;
  font-size: 1.8vw;
  color: #1a4c94;
  transition: all 0.3s 0.3s ease-in-out;
}

.indicadores .graphs {
  width: 90%;
  margin: 0 auto;
  height: 53vw;
  position: relative;
}
.indicadores .graphs .info_graphs {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  flex-flow: row;
  opacity: 0;
  z-index: -1;
  transition: all 0.3s 0.3s ease-in;
}
.indicadores .graphs .info_graphs.active {
  opacity: 1;
  z-index: 1;
  transition: all 0.3s 0.3s ease-in-out;
}
@media only screen and (max-width: 320px) {
  .indicadores .section span.a {
    font-size: 4vw;
  }
  .indicadores .intro-indicadores {
    font-size: 2.4vw;
  }
  .indicadores .cont-graphs .menu-graphs .buttons .button.active .titulo-button {
    font-size: 5vw;
    height: 6vw;
  }
  .indicador.one .months .month {
    font-size: 1.2vw;
  }
  .indicador.one .grafico {
    width: 100% !important;
  }
}
@media only screen and (min-width: 321px) and (max-width: 480px) {
  .indicadores .section span.a {
    font-size: 4vw;
  }

  .indicadores .intro-indicadores {
    font-size: 2.4vw;
  }
  .indicadores .cont-graphs .menu-graphs .buttons .button.active .titulo-button {
    font-size: 5vw;
    height: 6vw;
  }
  .indicador.one .months .month {
    font-size: 1.2vw;
  }
  .indicador.one .grafico {
    width: 100% !important;
  }
}
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .indicadores .section span.a {
    font-size: 4vw;
  }
  .indicadores .intro-indicadores {
    font-size: 2.4vw;
  }
  .indicadores .cont-graphs .menu-graphs .buttons .button.active .titulo-button {
    font-size: 5vw;
    height: 6vw;
  }
  .indicador.one .months .month {
    font-size: 1.2vw;
  }
  .indicador.one .grafico {
    width: 100% !important;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .indicadores .section span.a {
    font-size: 4vw;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1199px) {
  .indicadores .section span.a {
    font-size: 4vw;
    
  }
}
</style>

