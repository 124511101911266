<template>
<!-- 2018 -->
  <div class="indicador one">
    <div class="months flex-row-center align-row-center shadow">
      <span
        v-for="(m, i) in months"
        :key="i"
        @click="selectMonth(i)"
        class="month flex-row-center align-row-center"
        :class="getStyleMonth(i)"
      >{{ m }}</span>
    </div>

    <bar-chart v-if="datos !== null" suffix="%" :colors="['#1a4c94']" :data="datos" class="grafico"></bar-chart>
  </div>
</template>

<style>
.indicador.one {
  width: 100%;
  height: 100%;
}

.indicador.one .grafico {
  height: 45vw !important;
  width: 85% !important;
  margin: 0 auto !important;
  display: block !important;
}

.indicador.one .months {
  background: #fff;
  height: 4vw;
  margin: 1vw auto 3vw auto;
  width: 100%;
}

.indicador.one .months .month {
  background: #fff;
  color: #1a4c94;
  padding: 0 1vw;
  height: 100%;
  width: calc(100% / 12);
  transition: all 0.1s 0.1s ease-in;
}

.indicador.one .months .month:hover {
  cursor: pointer;
}

.indicador.one .months .month.active {
  background: #1a4c94;
  color: #fff;
  transition: all 0.1s 0.1s ease-in-out;
}
</style>

<script>
export default {
  data: function() {
    return {
      data: [
        {
          num: [
            ["Azuay", 7],
            ["Bolívar", 0.06],
            ["Cañar", 2.31],
            ["Carchi", 0.19],
            ["Chimborazo", 1.88],
            ["Cotopaxi", 3.55],
            ["El Oro", 12.64],
            ["Esmeraldas", 0.74],
            ["Guayas", 17],
            ["Imbabura", 1.51],
            ["Loja", 2.83],
            ["Los Ríos", 9.08],
            ["Pichincha", 7.60],
            ["Manabí", 14.05],
            ["Morona Santiago", 1.45],
            ["Napo", 0.13],
            ["Orellana", 1.77],
            ["Pastaza", 0.08],
            ["Santa Elena", 3.22],
            ["Sto. Dgo. Tsáchilas", 4.25],
            ["Sucumbíos", 2.08],
            ["Tungurahua", 4.87],
            ["Zamora Chinchipe", 1.53]
          ]
        },
        {
          num: [
            ["Azuay", 6.59],
            ["Bolívar", 0.10],
            ["Cañar", 2.30],
            ["Carchi", 0.19],
            ["Chimborazo", 1.91],
            ["Cotopaxi", 3.45],
            ["El Oro", 11.16],
            ["Esmeraldas", 0.74],
            ["Guayas", 19.01],
            ["Imbabura", 1.51],
            ["Loja", 2.86],
            ["Los Ríos", 8.79],
            ["Manabí", 13.97],
            ["Morona Santiago", 1.45],
            ["Napo", 0.13],
            ["Orellana", 1.83],
            ["Pastaza", 0.08],
            ["Pichincha", 7.62],
            ["Santa Elena", 2.43],
            ["Sto. Dgo. Tsáchilas", 4.32],
            ["Sucumbíos", 2.14],
            ["Tungurahua", 4.90],
            ["Zamora Chinchipe", 1.53]
          ]
        },
        {
          num: [
            ["Azuay", 6.56],
            ["Bolívar", 0.10],
            ["Cañar", 2.60],
            ["Carchi", 0.19],
            ["Chimborazo", 1.87],
            ["Cotopaxi", 3],
            ["El Oro", 11.05],
            ["Esmeraldas", 0.75],
            ["Guayas", 19.19],
            ["Imbabura", 1.45],
            ["Loja", 2.81],
            ["Los Ríos", 8.78],
            ["Manabí", 14.08],
            ["Morona Santiago", 1.42],
            ["Orellana", 1.83],
            ["Pastaza", 0.10],
            ["Pichincha", 7.58],
            ["Santa Elena", 3.37],
            ["Sto. Dgo. Tsáchilas", 4.25],
            ["Sucumbíos", 2.10],
            ["Tungurahua", 4.89],
            ["Zamora Chinchipe", 1.50]
          ]
        },
        {
          num: [
            ["Azuay", 6.51],
            ["Bolívar", 0.09],
            ["Cañar", 2.93],
            ["Carchi", 0.22],
            ["Chimborazo", 2.29],
            ["Cotopaxi", 3.87],
            ["El Oro", 12.52],
            ["Esmeraldas", 2.15],
            ["Guayas", 17.17],
            ["Imbabura", 1.56],
            ["Loja", 2.63],
            ["Los Ríos", 8.81],
            ["Manabí", 10.43],
            ["Morona Santiago", 2.48],
            ["Orellana", 1.69],
            ["Pastaza", 0.09],
            ["Pichincha", 8.95],
            ["Santa Elena", 2.87],
            ["Sto. Dgo. Tsáchilas", 2.53],
            ["Sucumbíos", 1.24],
            ["Tungurahua", 6.78],
            ["Zamora Chinchipe", 1.43]
          ]
        },
        {
          num: [
            ["Azuay", 8.32],
            ["Bolívar", 0.10],
            ["Cañar", 3.67],
            ["Chimborazo", 2.24],
            ["Cotopaxi", 3.61],
            ["El Oro", 11.51],
            ["Esmeraldas", 1.98],
            ["Guayas", 16.34],
            ["Imbabura", 1.44],
            ["Loja", 2],
            ["Los Ríos", 8.10],
            ["Manabí", 9.59],
            ["Morona Santiago", 2.28],
            ["Napo", 0.19],
            ["Orellana", 1.91],
            ["Pastaza", 0.08],
            ["Pichincha", 8.87],
            ["Santa Elena", 3.02],
            ["Sto. Dgo. Tsáchilas", 3.98],
            ["Sucumbíos", 2.19],
            ["Tungurahua", 6.74],
            ["Zamora Chinchipe", 1.03]
          ]
        },
        {
          num: [
            ["Azuay", 8.32],
            ["Bolívar", 0.10],
            ["Cañar", 3.67],
            ["Chimborazo", 2.24],
            ["Cotopaxi", 3.61],
            ["El Oro", 11.51],
            ["Esmeraldas", 1.98],
            ["Guayas", 16.34],
            ["Imbabura", 1.44],
            ["Loja", 2.42],
            ["Los Ríos", 8.10],
            ["Manabí", 9.59],
            ["Morona Santiago", 2.28],
            ["Napo", 0.19],
            ["Orellana", 1.91],
            ["Pastaza", 0.08],
            ["Pichincha", 8.87],
            ["Santa Elena", 3.02],
            ["Sto. Dgo. Tsáchilas", 3.98],
            ["Sucumbíos", 2.19],
            ["Tungurahua", 6.74],
            ["Zamora Chinchipe", 1.03]
          ]
        },
        {
          num: [
            ["Azuay", 6.56],
            ["Bolívar", 0.10],
            ["Cañar", 2.60],
            ["Carchi", 0.19],
            ["Chimborazo", 1.87],
            ["Cotopaxi", 3.39],
            ["El Oro", 11.05],
            ["Esmeraldas", 0.75],
            ["Guayas", 19.19],
            ["Imbabura", 1.45],
            ["Loja", 2.81],
            ["Los Ríos", 8.78],
            ["Manabí", 14.08],
            ["Morona Santiago", 1.42],
            ["Napo", 0.12],
            ["Orellana", 1.83],
            ["Pastaza", 0.10],
            ["Pichincha", 7.58],
            ["Santa Elena", 3.37],
            ["Sto. Dgo. Tsáchilas", 4.25],
            ["Sucumbíos", 2.10],
            ["Tungurahua", 4.89],
            ["Zamora Chinchipe", 1.50]
          ]
        },
        {
          num: [
            ["Azuay", 6.29],
            ["Bolívar", 0.26],
            ["Cañar", 2.92],
            ["Carchi", 1.45],
            ["Chimborazo", 1.95],
            ["Cotopaxi", 2.84],
            ["El Oro", 11.66],
            ["Esmeraldas", 3.20],
            ["Guayas", 16.82],
            ["Ibarra", 0.38],
            ["Imbabura", 1.82],
            ["Loja", 3.55],
            ["Los Ríos", 9.04],
            ["Manabí", 8.75],
            ["Manta", 0.85],
            ["Morona Santiago", 2.29],
            ["Napo", 0.46],
            ["Orellana", 1.38],
            ["Pastaza", 0.14],
            ["Pichincha", 8.37],
            ["Santa Elena", 2.61],
            ["Sto. Dgo. Tsáchilas", 4.59],
            ["Sucumbíos", 1.77],
            ["Tungurahua", 4.89],
            ["Zamora Chinchipe", 1.73]
          ]
        },
        {
          num: [
            ["Azuay", 7.55],
            ["Bolívar", 0.15],
            ["Cañar", 3.21],
            ["Carchi", 1.36],
            ["Chimborazo", 2.05],
            ["Cotopaxi", 2.71],
            ["El Oro", 11.01],
            ["Esmeraldas", 3.79],
            ["Guayas", 16.84],
            ["Ibarra", 0.35],
            ["Imbabura", 1.56],
            ["Loja", 3.82],
            ["Latacunga", 0],
            ["Los Ríos", 9.66],
            ["Manabí", 8.95],
            ["Manta", 0.77],
            ["Morona Santiago", 1.96],
            ["Napo", 0.47],
            ["Orellana", 1.38],
            ["Pastaza", 0.12],
            ["Pichincha", 7.33],
            ["Quito", 0.01],
            ["Santa Elena", 2.46],
            ["Sto. Dgo. Tsáchilas", 4.38],
            ["Sucumbíos", 1.71],
            ["Tungurahua", 4.82],
            ["Zamora Chinchipe", 1.58]
          ]
        },
        {
          num: [
            ["Azuay", 7.76],
            ["Bolívar", 0.09],
            ["Cañar", 3.20],
            ["Carchi", 1.28],
            ["Chimborazo", 1.85],
            ["Cotopaxi", 3.30],
            ["Cuenca", 0.08],
            ["El Oro", 10.64],
            ["Esmeraldas", 3.79],
            ["Guayas", 16.71],
            ["Imbabura", 2.03],
            ["Loja", 3.66],
            ["Los Ríos", 8.81],
            ["Manabí", 9.75],
            ["Morona Santiago", 1.68],
            ["Napo", 0.34],
            ["Orellana", 1.40],
            ["Pastaza", 0.12],
            ["Pichincha", 8.10],
            ["Quito", 0.26],
            ["Santa Elena", 2.47],
            ["Sto. Dgo. Tsáchilas", 4.93],
            ["Sucumbíos", 1.66],
            ["Tungurahua", 4.51],
            ["Zamora Chinchipe", 1.60]
          ]
        },
        {
          num: [
            ["Azuay", 7.59],
            ["Bolívar", 0.08],
            ["Cañar", 3.13],
            ["Carchi", 1.10],
            ["Chimborazo", 1.72],
            ["Cotopaxi", 3.40],
            ["Cuenca", 0.11],
            ["El Oro", 10.57],
            ["Esmeraldas", 4.03],
            ["Guayas", 16.75],
            ["Imbabura", 1.45],
            ["Loja", 3.63],
            ["Los Ríos", 8.56],
            ["Manabí", 9.30],
            ["Morona Santiago", 1.63],
            ["Napo", 0.32],
            ["Orellana", 1.35],
            ["Pastaza", 0.11],
            ["Pichincha", 9.26],
            ["Quito", 0.24],
            ["Samborondón", 0.03],
            ["Santa Elena", 2.49],
            ["Sto. Dgo. Tsáchilas", 5.77],
            ["Sucumbíos", 1.55],
            ["Tungurahua", 4.34],
            ["Zamora Chinchipe", 1.48]
          ]
        },
        {
          num: [
            ["Azuay", 8.18],
            ["Bolívar", 0.08],
            ["Cañar", 3.18],
            ["Carchi", 1.11],
            ["Chimborazo", 1.79],
            ["Cotopaxi", 3.35],
            ["Cuenca", 0.10],
            ["El Oro", 10.29],
            ["Esmeraldas", 3.86],
            ["Guayas", 17.23],
            ["Imbabura", 1.52],
            ["Loja", 3.09],
            ["Los Ríos", 8.60],
            ["Manabí", 9.29],
            ["Morona Santiago", 1.55],
            ["Napo", 0.33],
            ["Orellana", 1.29],
            ["Pastaza", 0.10],
            ["Pichincha", 9.02],
            ["Quito", 0.28],
            ["Samborondón", 0.05],
            ["Santa Elena", 2.97],
            ["Sto. Dgo. Tsáchilas", 5.54],
            ["Sucumbíos", 1.48],
            ["Tungurahua", 4.21],
            ["Zamora Chinchipe", 1.50]
          ]
        }
      ],
      months: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre"
      ],
      datos: null,
      monthActive: 0
    };
  },
  methods: {
    selectMonth: function(i) {
      this.monthActive = i;
      this.datos = this.data[i].num;
    },
    getStyleMonth: function(i) {
      return {
        active: this.monthActive == i ? true : false
      };
    }
  },
  beforeMount: function() {
    this.selectMonth(0);
  }
};
</script>