<template>
  <div class="indicador one">
    <div class="months flex-row-center align-row-center shadow">
      <span
        v-for="(m, i) in months"
        :key="i"
        @click="selectMonth(i)"
        class="month flex-row-center align-row-center"
        :class="getStyleMonth(i)"
      >{{ m }}</span>
    </div>

    <bar-chart v-if="datos !== null" suffix="%" :colors="['#1a4c94']" :data="datos" class="grafico"></bar-chart>
  </div>
</template>

<style>
.indicador.one {
  width: 100%;
  height: 100%;
}

.indicador.one .grafico {
  height: 45vw !important;
  width: 85% !important;
  margin: 0 auto !important;
  display: block !important;
}

.indicador.one .months {
  background: #fff;
  height: 4vw;
  margin: 1vw auto 3vw auto;
  width: 100%;
}

.indicador.one .months .month {
  background: #fff;
  color: #1a4c94;
  padding: 0 1vw;
  height: 100%;
  width: calc(100% / 12);
  transition: all 0.1s 0.1s ease-in;
}

.indicador.one .months .month:hover {
  cursor: pointer;
}

.indicador.one .months .month.active {
  background: #1a4c94;
  color: #fff;
  transition: all 0.1s 0.1s ease-in-out;
}
</style>

<script>
export default {
  data: function() {
    return {
      data: [
        {
          num: [
            ["Azuay", 5.33],
            ["Bolívar", 0.05],
            ["Cañar", 1.52],
            ["Carchi", 0.73],
            ["Chimborazo", 2.05],
            ["Cotopaxi", 3.11],
            ["El Oro", 13.88],
            ["Esmeraldas", 2.38],
            ["Guayas", 16.79],
            ["Imbabura", 3.15],
            ["Loja", 3.60],
            ["Los Ríos", 8.59],
            ["Manabí", 8.90],
            ["Morona Santiago", 2.45],
            ["Napo", 0],
            ["Orellana", 1.84],
            ["Pastaza", 0.06],
            ["Pichincha", 9.07],
            ["Santa Elena", 2.29],
            ["Sto. Dgo. Tsáchilas", 6.66],
            ["Sucumbíos", 0.71],
            ["Tungurahua", 4.85],
            ["Zamora Chinchipe", 1.84]
          ]
        },
        {
          num: [
            ["Azuay", 6.55],
            ["Bolívar", 0.05],
            ["Cañar", 1.47],
            ["Carchi", 0.70],
            ["Chimborazo", 2.04],
            ["Cotopaxi", 3.20],
            ["El Oro", 13.56],
            ["Esmeraldas", 2.37],
            ["Guayas", 16.77],
            ["Imbabura", 3.72],
            ["Loja", 3.29],
            ["Los Ríos", 8.55],
            ["Manabí", 8.56],
            ["Morona Santiago", 2.39],
            ["Napo", 0.17],
            ["Orellana", 1.95],
            ["Pastaza", 0.07],
            ["Pichincha", 8.77],
            ["Santa Elena", 2.24],
            ["Sto. Dgo. Tsáchilas", 5.40],
            ["Sucumbíos", 1.62],
            ["Tungurahua", 4.69],
            ["Zamora Chinchipe", 1.85]
          ]
        },
        {
          num: [
            ["Azuay", 6.65],
            ["Bolívar", 0.05],
            ["Cañar", 1.41],
            ["Carchi", 0.53],
            ["Chimborazo", 1.31],
            ["Cotopaxi", 3.05],
            ["El Oro", 14.14],
            ["Esmeraldas", 2.33],
            ["Guayas", 16.51],
            ["Imbabura", 3.57],
            ["Loja", 3.10],
            ["Los Ríos", 8.59],
            ["Manabí", 8.52],
            ["Morona Santiago", 2.34],
            ["Napo", 0.12],
            ["Orellana", 1.97],
            ["Pastaza", 0.07],
            ["Pichincha", 9.55],
            ["Santa Elena", 2.22],
            ["Sto. Dgo. Tsáchilas", 4.97],
            ["Sucumbíos", 1.55],
            ["Tungurahua", 4.65],
            ["Zamora Chinchipe", 1.81]
          ]
        },
        {
          num: [
            ["Azuay", 6.71],
            ["Bolívar", 0.05],
            ["Cañar", 1.39],
            ["Carchi", 0.51],
            ["Chimborazo", 2.35],
            ["Cotopaxi", 3],
            ["El Oro", 13.75],
            ["Esmeraldas", 2.20],
            ["Guayas", 15.73],
            ["Imbabura", 3.43],
            ["Loja", 3.52],
            ["Los Ríos", 8.68],
            ["Manabí", 8.54],
            ["Morona Santiago", 2.50],
            ["Napo", 0.13],
            ["Orellana", 1.99],
            ["Pastaza", 0.07],
            ["Pichincha", 9.84],
            ["Santa Elena", 2.18],
            ["Sto. Dgo. Tsáchilas", 4.83],
            ["Sucumbíos", 1.97],
            ["Tungurahua", 4.89],
            ["Zamora Chinchipe", 1.77]
          ]
        },
        {
          num: [
            ["Azuay", 6.81],
            ["Bolívar", 0.06],
            ["Cañar", 1.50],
            ["Carchi", 0.30],
            ["Chimborazo", 2.37],
            ["Cotopaxi", 3.09],
            ["El Oro", 12.33],
            ["Esmeraldas", 2.23],
            ["Guayas", 16.20],
            ["Imbabura", 2.77],
            ["Loja", 3.71],
            ["Los Ríos", 8.90],
            ["Manabí", 8.72],
            ["Morona Santiago", 2.19],
            ["Napo", 0.13],
            ["Orellana", 2.02],
            ["Pastaza", 0.07],
            ["Pichincha", 10.57],
            ["Santa Elena", 2.26],
            ["Sto. Dgo. Tsáchilas", 4.98],
            ["Sucumbíos", 2.03],
            ["Tungurahua", 4.98],
            ["Zamora Chinchipe", 1.80]
          ]
        },
        {
          num: [
            ["Azuay", 5.99],
            ["Bolívar", 0.06],
            ["Cañar", 1.99],
            ["Carchi", 1.30],
            ["Chimborazo", 2.47],
            ["Cotopaxi", 3.07],
            ["El Oro", 12.28],
            ["Esmeraldas", 2.69],
            ["Guayas", 15.35],
            ["Imbabura", 2.77],
            ["Loja", 3.62],
            ["Los Ríos", 9.12],
            ["Manabí", 8.66],
            ["Morona Santiago", 1.87],
            ["Napo", 0.13],
            ["Orellana", 2.09],
            ["Pastaza", 0.07],
            ["Pichincha", 11.01],
            ["Santa Elena", 2.29],
            ["Sto. Dgo. Tsáchilas", 4.95],
            ["Sucumbíos", 2.32],
            ["Tungurahua", 4.88],
            ["Zamora Chinchipe", 2.01]
          ]
        },
        {
          num: [
            ["Azuay", 4.11],
            ["Bolívar", 0.05],
            ["Cañar", 1.71],
            ["Carchi", 0.29],
            ["Chimborazo", 2.21],
            ["Cotopaxi", 3.22],
            ["El Oro", 13.21],
            ["Esmeraldas", 2.76],
            ["Guayas", 17.91],
            ["Imbabura", 1.71],
            ["Loja", 3.17],
            ["Los Ríos", 7.96],
            ["Manabí", 9.38],
            ["Morona Santiago", 1.69],
            ["Napo", 0.16],
            ["Orellana", 2.01],
            ["Pastaza", 0.06],
            ["Pichincha", 10.56],
            ["Santa Elena", 2.17],
            ["Sto. Dgo. Tsáchilas", 6.74],
            ["Sucumbíos", 2.36],
            ["Tungurahua", 4.80],
            ["Zamora Chinchipe", 1.74]
          ]
        },
        {
          num: [
            ["Azuay", 5.57],
            ["Bolívar", 0.05],
            ["Cañar", 1.69],
            ["Carchi", 0.22],
            ["Chimborazo", 2.21],
            ["Cotopaxi", 3.19],
            ["El Oro", 13.38],
            ["Esmeraldas", 2.09],
            ["Guayas", 18.11],
            ["Imbabura", 1.32],
            ["Loja", 3.31],
            ["Los Ríos", 7.87],
            ["Manabí", 9.69],
            ["Morona Santiago", 1.61],
            ["Napo", 0.17],
            ["Orellana", 2.09],
            ["Pastaza", 0.06],
            ["Pichincha", 9.91],
            ["Santa Elena", 2.07],
            ["Sto. Dgo. Tsáchilas", 7.14],
            ["Sucumbíos", 1.61],
            ["Tungurahua", 4.88],
            ["Zamora Chinchipe", 1.76]
          ]
        },
        {
          num: [
            ["Azuay", 5.20],
            ["Bolívar", 0.08],
            ["Cañar", 1.64],
            ["Carchi", 0.25],
            ["Chimborazo", 2.28],
            ["Cotopaxi", 4.19],
            ["El Oro", 13.37],
            ["Esmeraldas", 2.20],
            ["Guayas", 18.78],
            ["Imbabura", 1.17],
            ["Loja", 3.41],
            ["Los Ríos", 8.22],
            ["Manabí", 9.58],
            ["Morona Santiago", 1.48],
            ["Napo", 0.17],
            ["Orellana", 2.15],
            ["Pastaza", 0.10],
            ["Pichincha", 10.02],
            ["Santa Elena", 2.31],
            ["Sto. Dgo. Tsáchilas", 5.95],
            ["Sucumbíos", 1.18],
            ["Tungurahua", 4.66],
            ["Zamora Chinchipe", 1.59]
          ]
        },
        {
          num: [
            ["Azuay", 6.14],
            ["Bolívar", 0.07],
            ["Cañar", 2.42],
            ["Carchi", 0.21],
            ["Chimborazo", 2.34],
            ["Cotopaxi", 3.43],
            ["El Oro", 13.05],
            ["Esmeraldas", 1],
            ["Guayas", 16.98],
            ["Imbabura", 1.50],
            ["Loja", 2.48],
            ["Los Ríos", 8.94],
            ["Manabí", 10.13],
            ["Morona Santiago", 2.01],
            ["Napo", 0.14],
            ["Orellana", 1.93],
            ["Pastaza", 0.08],
            ["Pichincha", 11.68],
            ["Santa Elena", 2.70],
            ["Sto. Dgo. Tsáchilas", 4.62],
            ["Sucumbíos", 1.36],
            ["Tungurahua", 5.12],
            ["Zamora Chinchipe", 1.67]
          ]
        },
        {
          num: [
            ["Azuay", 5.3],
            ["Bolívar", 0.06],
            ["Cañar", 2.40],
            ["Carchi", 0.19],
            ["Chimborazo", 1.88],
            ["Cotopaxi", 3.44],
            ["El Oro", 13.33],
            ["Esmeraldas", 0.77],
            ["Guayas", 19.33],
            ["Imbabura", 1.51],
            ["Loja", 2.97],
            ["Los Ríos", 9.10],
            ["Manabí", 9],
            ["Morona Santiago", 1],
            ["Napo", 0.13],
            ["Orellana", 1.84],
            ["Pastaza", 0.08],
            ["Pichincha", 10.77],
            ["Santa Elena", 2.84],
            ["Sto. Dgo. Tsáchilas", 4.58],
            ["Sucumbíos", 2],
            ["Zamora Chinchipe", 1.55]
          ]
        },
        {
          num: [
            ["Azuay", 5.41],
            ["Bolívar", 0.07],
            ["Cañar", 2.44],
            ["Carchi", 0.14],
            ["Chimborazo", 2],
            ["Cotopaxi", 3.63],
            ["El Oro", 13.89],
            ["Esmeraldas", 0.78],
            ["Guayas", 1],
            ["Imbabura", 1.53],
            ["Loja", 3.01],
            ["Los Ríos", 9.06],
            ["Manabí", 9.68],
            ["Morona Santiago", 1.78],
            ["Napo", 0.13],
            ["Orellana", 1.86],
            ["Pastaza", 0.08],
            ["Pichincha", 10.93],
            ["Santa Elena", 2.88],
            ["Sto. Dgo. Tsáchilas", 4.65],
            ["Sucumbíos", 2.03],
            ["Tungurahua", 4.67],
            ["Zamora Chinchipe", 1.57]
          ]
        }
      ],
      months: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre"
      ],
      datos: null,
      monthActive: 0
    };
  },
  methods: {
    selectMonth: function(i) {
      this.monthActive = i;
      this.datos = this.data[i].num;
    },
    getStyleMonth: function(i) {
      return {
        active: this.monthActive == i ? true : false
      };
    }
  },
  beforeMount: function() {
    this.selectMonth(0);
  }
};
</script>