<template>
  <div class="info-contacto row" v-scrollanimation>
    <div class="cont-info flex-column-center shadow">
      <div class="buttons flex-row-center align-row-center">
        <div
          :key="btn.key"
          v-for="btn in slide.slides.buttons"
          :class="getClassButton(btn.index)"
          @click="changeActiveSection(btn.index)"
        >
          <span class="">{{ btn.n }}</span>
        </div>
      </div>
      
      <div class="slider">
        <div :key="s.key" v-for="s in slide.slides.items" :class="getClassSlideItem(s.index)">
          <div
            :key="'item_' + pos"
            v-for="(i, pos) in s.item"
            :class="getClassItem()"
            class="flex-row-center align-row-center"
            v-show="i !== null"
          >
            <div class="item-info flex-row-center align-row-center full-height">
              <div class="a flex-row-center align-row-center">
                <div class="cont-img">
                  <img src="../../assets/img/home/phone.png" alt="" />
                </div>
              </div>
              <div class="b flex-column-center">
                <span>{{ i.phone1 }}</span>
                <span>{{ i.phone2 }}</span>
              </div>
            </div>

            <div class="item-info flex-row-center align-row-center">
              <div class="a flex-row-center align-row-center">
                <div class="cont-img">
                  <img src="../../assets/img/home/mail.png" alt="" />
                </div>
              </div>
              <div class="b flex-column-center">
                <span>{{ i.email1 }}</span>
                <span v-show="i.email2 !== ''">{{ i.email2 }}</span>
              </div>
            </div>

            <div class="item-info flex-row-center align-row-center">
              <div class="a flex-row-center align-row-center">
                <div class="cont-img">
                  <img src="../../assets/img/home/locale.png" alt="" />
                </div>
              </div>
              <div class="b flex-column-center">
                <span>{{ i.direccion }}</span>
              </div>
            </div>

          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>


<script>
export default {
  data: function() {
    return {
      it: [
        {
          name: "Guayaquil",
          phone1: "+593 (4) 6008192",
          
          email1: "info@nedetel.net",
          email2: "",
          direccion: "Av. Perimetral Km 4 y Av. Francisco de Orellana (Intersección)"
        },
        {
          name: "Sto. Domingo",
          phone1: "+593 (4) 6008192",
          
          email1: "info@nedetel.net",
          email2: "",
          direccion: "Intersección entre Río Mulaute y Guayaquil (Junto a Edificio Majestad Tv)"
        },
        {
          name: "Quito",
          phone1: "+593 (9) 98025000",
          phone2: "+593 (2) 3731740",
          email1: "info@nedetel.net",
          email2: "",
          direccion: "Av. de los Shyris N32-88 y Diego de Almagro, Edif. Shyris 186, oficina 703."
        },
        {
          name: "Manta",
          phone1: "+593 (4) 6008192",
          
          email1: "info@nedetel.net",
          email2: "",
          direccion: "Calle m2 y calle m1 sector el Murciélago-EDIFICIO BUSINESS CENTER, Piso 13"
        },
        {
          name: "Cuenca",
          phone1: "+593 (4) 6008192",
          
          email1: "info@nedetel.net",
          email2: "",
          direccion: "Antinsuyo S/N Collasuyo - MONAY"
        }
      ],

      slide: {
        size: 0,
        slides: {
          active: 0,
          buttons: [],
          items: []
        }
      }
    };
  },
  beforeMount: function() {
    this.loadItems();
  },
  methods: {
    getClassSlideItem: function(index) {
      return {
        slide: true,
        active: this.slide.slides.active === index
      };
    },
    getClassItem: function() {
      return {
        item: true
      };
    },
    getClassButton: function(index) {
      return {
        button: true,
        active: this.slide.slides.active === index
      };
    },
    changeActiveSection: function(index) {
      this.slide.slides.active = index;
    },
    loadItems:  function() {
      this.slide.size = this.it.length;
      let indices = 0;
      for (let i = 0; i < this.slide.size; i += 1) {
        let aux = {
          key: "slide_" + i,
          index: indices,
          item: []
        };
        for (let j = 0; j < 1; j++) {
          aux.item.push(
            i + j < this.slide.size ? this.it[i + j] : null
          );
        }
        this.slide.slides.items.push(aux);
        indices += 1;
      }
      for (let j = 0; j < this.slide.slides.items.length; j++) {
        this.slide.slides.buttons.push({
          key: "button_" + j,
          index: j,
          n: this.it[j].name
        });
      }
    }
  }
};
</script>


<style>
.info-contacto {
  height: 16vw;
  position: absolute;
  top: -8vw;
  left: 0;
  z-index: 1;
}

.info-contacto .cont-info {
  background: #fff;
  width: 100%;
  height: 100%;
}

.info-contacto .cont-info .slider {
  position: relative;
  width: 90%;
  height: calc(8vw - 3vw);
  margin: 0 auto;
  padding: 1vw 0 2vw 0;
}

.info-contacto .cont-info .slider .slide {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row wrap;

  position: absolute;
  z-index: -1;
  opacity: 0;
  top: 0;
  transition: all 0.3s 0.3s ease;
}

.info-contacto .cont-info .slider .slide .item {
  width: 80%;
  height: 100%;
  margin: 0 auto;
  transition: all 0.1s 0.1s;
}

.info-contacto .cont-info .slider .slide .item .item-info {
  width: calc(33.333333334% - 1vw);
  margin: 0 0.5vw;
}

.info-contacto .cont-info .slider .slide .item .item-info .a {
  width: 30%;
  height: 100%;
}

.info-contacto .cont-info .slider .slide .item .item-info .a .cont-img {
  width: 4vw;
  height: 4vw;
}

.info-contacto .cont-info .slider .slide .item .item-info .a .cont-img img {
  width: 100%;
  height: 100%;
}

.info-contacto .cont-info .slider .slide .item .item-info .b {
  width: 70%;
  height: 100%;
}

.info-contacto .cont-info .slider .slide.active {
  z-index: 1;
  opacity: 1;
  transition: all 0.3s 0.3s ease;
}

.info-contacto .cont-info .buttons {
  width: 90%;
  height: calc(4vw - 1px);
  margin: 0 auto;
  position: relative;
  z-index: 2;
  border-bottom: solid 1px #878787;
}

.info-contacto .cont-info .buttons .button {
  width: calc(20% - 0.8vw);
  margin: 0 0.4vw;
  cursor: pointer;
  transition: all 0.2s 0.2s ease;
}

.info-contacto .cont-info .buttons .button span {
  display: block;
  text-align: center;
  color: #606060;
  font-family: montserrat_medium;
}

.info-contacto .cont-info .buttons .button:active {
  transform: scale(0.98);
}
.info-contacto .cont-info .buttons .button.active span {
  color: rgb(240, 144, 85);
  transition: all 0.2s 0.2s ease;
}


@media only screen and (max-width: 320px) {
  .info-contacto .cont-info .buttons .button span {
    font-size: 1.4vw;
  }
  .info-contacto .cont-info .slider .slide .item .item-info .b {
    font-size: 1.4vw;
  }
}
@media only screen and (min-width: 321px) and (max-width: 480px) {
  .info-contacto .cont-info .buttons .button span {
    font-size: 1.35vw;
  }
  .info-contacto .cont-info .slider .slide .item .item-info .b {
    font-size: 1.35vw;
  }
}
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .info-contacto .cont-info .buttons .button span {
    font-size: 1.3vw;
  }
  .info-contacto .cont-info .slider .slide .item .item-info .b {
    font-size: 1.3vw;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .info-contacto .cont-info .buttons .button span {
    font-size: 1.2vw;
  }
  .info-contacto .cont-info .slider .slide .item .item-info .b {
    font-size: 1vw;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1199px) {
  .info-contacto .cont-info .buttons .button span {
    font-size: 1.2vw;  
  }
  .info-contacto .cont-info .slider .slide .item .item-info .b {
    font-size: 0.9vw;
  }
}



</style>