<template>
  <div class="infra full-width full-height">
    <Intro></Intro>
    <Caracteristicas></Caracteristicas>    
  </div>
</template>

<script>
import Intro from "../infraestructura/Intro.vue";
import Caracteristicas from "../infraestructura/Caracteristicas.vue";
export default {
  components: {
    Intro,
    Caracteristicas
  }
}

</script>

